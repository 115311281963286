import { BaseObject } from "./base-object";

export class UserImage extends BaseObject {
  public static readonly defaultIconImage = "/media/category_images/default-category.png";
  static fromData(data: any) {
    const { UserId, ProfileImage, BackgroundImage} = data;
    return new this(UserId, ProfileImage, BackgroundImage);
  }

  constructor(
    public UserId: string,
    public ProfileImage?: File,
    public BackgroundImage?: File,

  ) { super(); }
}
