import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "dialog-input.html"
})
export class DialogInputComponent {
  model = null;
  inputValue: string = '';
  active:boolean = false;
  private defaults = {
    title: 'Confirmation',
    message: 'Do you want to ',
    yesText: 'Yes',
    noText: 'No'
  };

  constructor(
    public dialogRef: MatDialogRef<DialogInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.model = { ...this.defaults, ...data }
  }

  ngOnInit() {
    this.inputValue = this.data.screen.disable_reason;
    this.active = this.data.screen.admin_active_flg;
  }

  onNoClick(): void {
    this.dialogRef.close({'agree': false});
  }

  onYesClick(): void {
    this.dialogRef.close({'agree': true, 'reason': this.inputValue});
  }
}
