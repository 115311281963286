import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { BookingBrowseV2Action } from "./v2/booking-browse-v2.action";

@Injectable()
export class BookingAdapter {
  constructor(
    private browse: BookingBrowseV2Action,
  ) {}

  getAllBookings(params): Observable<any> {
    return this.browse.exec(params);
  }

}
