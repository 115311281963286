import { Permission } from './../../../models/permission';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';

@Injectable()
export class PermissionAddV2Action extends ActionEntityV2Abstract {
  exec(permisison: Permission): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/permissions`,
      permisison.toFormData()
    );
  }
}
