
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Action } from "../../models/action";
import { ActionAddOrUpdateV1Action } from "./action-add-or-update-v1.action";
import { ActionReadControllerV1Action } from "./action-read-controller-v1.action";
import { ActionReadV1Action } from "./action-read-v1.action";
import { ActionRemoveControllerV1Action } from "./action-remove-controller-v1.action";
import { ActionRemoveV1Action } from "./action-remove-v1.action";

@Injectable()
export class ActionAdapter {

  constructor(
    private add_or_update: ActionAddOrUpdateV1Action,
    private read_controller: ActionReadControllerV1Action,
    private read: ActionReadV1Action,
    private remove_controller: ActionRemoveControllerV1Action,
    private remove: ActionRemoveV1Action,
  ) {
  }

  // Simulate POST /actions
  addOrUpdate(action: Action): Observable<any> {
    return this.add_or_update.exec({action});
  }

  // Simulate DELETE /actions/:id
  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }

  deleteByControllerId(id: number): Observable<any> {
    return this.remove_controller.exec({id});
  }

  // Simulate GET /actions
  getByControllerId(id: number): Observable<any> {
    return this.read_controller.exec({id});
  }

  // Simulate GET /actions/:id
  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }
}
