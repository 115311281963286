import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { Controller } from "../../models/controller";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDialogService } from "../../core/modalDialog/modalDialog.service";
import { BusyIndicatorService } from "../../core/busyIndicator/busyIndicator.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { ActionAdapter, ControllerAdapter, BaseService } from "../../services";

@Component({
  selector: "app-ms-controller-list",
  templateUrl: "./controller-list.component.html",
  styleUrls: ["./controller-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ControllerListComponent implements OnInit {
  rows = [];
  actionsInRow = [];
  expanded: any = {};
  isLoading: boolean;
  loadingIndicator: boolean;
  errorMessage: string;
  deleteId: number;
  @ViewChild("myTable") table: any;
  @ViewChild("actionsTable") actionsTable: any;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private controllerAdapter: ControllerAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalDialogService: ModalDialogService,
    protected busyIndicatorService: BusyIndicatorService,
    private dialog: MatDialog,
    private actionAdapter: ActionAdapter
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle("Controllers Management");
    this.getRows();
  }

  public getRows() {
    this.busyIndicatorService.show();
    this.isLoading = true;
    this.loadingIndicator = true;

    this.controllerAdapter.getAll().subscribe(
      data => {
        this.rows = data.data;
        this.loadingIndicator = false;
      },
      error => {
        this.errorMessage = <any>error;
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.isLoading = false;
      }
    );
  }

  openDialog(DeleteId): void {
    this.deleteId = DeleteId;
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "350px"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === "Yes") {
        this.router.navigate(["controllers"]);
        this.controllerAdapter.deleteById(this.deleteId).subscribe(res => {
          this.getRows();
        });
      }
    });
  }

  public edit(item) {
    this.router.navigate([item.Id, "edit"], {
      relativeTo: this.activatedRoute
    });
  }

  private handleServiceError(op: string, err: any) {
    console.error(`${op} error: ${err.message || err}`);
  }

  loadActions(row) {
    this.actionsInRow = [];
    this.actionsTable.loadingIndicator = true;

    this.actionAdapter.getByControllerId(row.Id).subscribe(
      data => {
        this.actionsInRow = data.data;
      },
      error => {
        this.errorMessage = <any>error;
        this.actionsTable.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.isLoading = false;
        this.actionsTable.loadingIndicator = false;
      }
    );
  }

  public editAction(item) {
    // TODO: fix
    // this.router.navigate([item.Id, "actions", "edit"], {
    //   relativeTo: this.activatedRoute.parent.parent
    // });
  }

  public deleteAction(item) {
    // TODO: fix
  }
}
