import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Service } from "../../../models/service";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class ServiceAddV2Action extends ActionEntityV2Abstract {

  // Simulate PATCH /services/:id
  exec(service: Service): Observable<any> {
    return this.http.post(`${this.apiUrl}/services/`, service.toFormData());
  }

}
