import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class CategoryAddV1Action extends ActionEntityV1Abstract {

  // Simulate POST /clients
  exec(options: any): Observable<any> {
    return this.http.post(
      `${this.apiBaseUrl}/Category/InsertCategory`,
      options.toFormData(),
    );
  }
}
