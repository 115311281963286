import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";
import { ActionEntityV2Abstract } from '../action-entity-v2.abstract';

@Injectable()
export class ConstantReadV1Action extends ActionEntityV2Abstract {

  // Simulate POST /clients
  exec(options: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/const/${options.type}`);
  }
}
