import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class CategoryEditV2Action extends ActionEntityV2Abstract {

  exec(options: any): Observable<any> {
    const data = new FormData()

    data.append('id', options.id)
    data.append('name', options.name)
    data.append('group', options.group)
    data.append('description', options.description)
    data.append('type', options.type)
    data.append('parent_id', options.parent_id || '')
    data.append('primary_image', options.primary_image || '')
    data.append('_method', 'PATCH')

    return this.http.post(
      `${this.apiUrl}/categories/${options.id}`,
      data,
    );
  }

}
