import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class RoleBrowseOtherV1Action extends ActionEntityV2Abstract {
  exec(options: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/roles`);
  }
}
