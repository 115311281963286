import { RoleInfoComponent } from "./roles/role-info/role-info.component";
import { PermissionAdapter } from "./services/permission/permission.adapter";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from "@angular/material";
import { AngularDateTimePickerModule } from "angular2-datetimepicker";

// import primeng UI components : https://www.npmjs.com/package/primeng
import {
  InputTextModule,
  ChartModule,
  CalendarModule,
  DataTableModule,
  ButtonModule,
  MessagesModule,
  DialogModule,
  SelectButtonModule,
  AutoCompleteModule,
  SharedModule,
  AccordionModule,
  ToolbarModule,
  PanelModule,
  FieldsetModule,
  TooltipModule,
  TreeTableModule,
  TreeNode,
} from "primeng/primeng";

import { Ng5BreadcrumbModule, BreadcrumbService } from "ng5-breadcrumb";
import { DeviceDetectorModule } from "ngx-device-detector";
import "hammerjs";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { GeneAppComponent } from "./app.component";
import { RoutingModule } from "./app-routing.module";
import { MomentModule } from "ngx-moment";
import { MainComponent } from "./main/main.component";
import { MenuToggleModule } from "./core/menu/menu-toggle.module";
import { MenuItems } from "./core/menu/menu-items/menu-items";
import { PageTitleService } from "./core/page-title/page-title.service";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { BlankComponent } from "./blank/blank.component";
import { LoginComponent } from "./session/login/login.component";
import { RegisterComponent } from "./session/register/register.component";
import { ForgotPasswordComponent } from "./session/forgot-password/forgot-password.component";
import { LockScreenComponent } from "./session/lockscreen/lockscreen.component";

// custom modules
import { ModalDialogModule } from "./core/modalDialog/modalDialog.module";
import { BusyIndicatorModule } from "./core/busyIndicator/busyIndicator.module";

// components
import { ClientListComponent } from "./clients/client-list/client-list.component";
import { ClientItemComponent } from "./clients/client-item/client-item.component";
import { DeleteConfirmDialogComponent } from "./dialog/delete-confirm-dialog";
import { DialogInputComponent } from "./dialog-input/dialog-input";
import { DialogInfoComponent } from "./dialog-info/dialog-info";
import { ControllerListComponent } from "./controller/controller-list/controller-list.component";
import { ControllerItemComponent } from "./controller/controller-item/controller-item.component";

import { ActionListComponent } from "./actions/action-list/action-list.component";
import { ActionItemComponent } from "./actions/action-item/action-item.component";
import { UserProfileComponent } from "./profile/user-profile/user-profile.component";

// services
import { AuthService } from "./auth/auth.service";
import { AuthGuard } from "./auth/auth-guard.service";

// Import Adapter
import {

  PromotionBrowseV1Action,
  UserAddExternalV1Action,
  UserAddInternalV1Action,
  UserBrowseExternalV1Action,
  UserBrowseInternalV1Action,
  UserEditImageV1Action,
  UserEditV1Action,
  UserReadV1Action,
  UserRemoveV1Action,
  UserSearchV1Action,
  OrderReadV1Action,
  OrderBrowseV1Action,
  OrderRefundV1Action,
  CategoryAddV1Action,
  CategoryBrowseProductV1Action,
  CategoryBrowseServiceV1Action,
  CategoryEditV1Action,
  CategoryReadV1Action,
  CategoryRemoveV1Action,
  ProductAddV1Action,
  ProductRemoveV1Action,
  ProductReadV1Action,
  ProductBrowseV1Action,
  ProductEditV1Action,
  ServiceAddV1Action,
  ServiceRemoveV1Action,
  ServiceReadV1Action,
  ServiceBrowserV1Action,
  ServiceEditV1Action,
  RoleAddOrUpdateV1Action,
  RoleBrowseOtherV1Action,
  RoleBrowseV1Action,
  RoleReadV1Action,
  RoleRemoveV1Action,
  UserRoleReadV1Action,
  UserRoleAssignV1Action,
  ClientAddOrUpdateV1Action,
  ClientReadV1Action,
  ClientBrowseV1Action,
  ClientRemoveV1Action,
  RolePermissionActionV1Action,
  RolePermissionControllerV1Action,
  RolePermissionAssignV1Action,
  ActionRemoveV1Action,
  ActionRemoveControllerV1Action,
  ActionReadV1Action,
  ActionReadControllerV1Action,
  ActionAddOrUpdateV1Action,
  ControllerRemoveV1Action,
  ControllerReadV1Action,
  ControllerBrowseV1Action,
  ControllerAddOrUpdateV1Action,
  ConstantReadV1Action,
  NotisBrowseV1Action,
  /* V2 */
  UserBrowseExternalV2Action,
  UserBrowseInternalV2Action,
  UserReadV2Action,
  UserEditV2Action,
  UserBrowseV2Action,
  UserSearchV2Action,
  ProductBrowseV2Action,
  ProductReadV2Action,
  ProductRemoveV2Action,
  ProductEditV2Action,
  ProductAddV2Action,
  ProductImportV2Action,
  ServiceBrowserV2Action,
  ServiceReadV2Action,
  ServiceEditV2Action,
  ServiceRemoveV2Action,
  ServiceAddV2Action,
  PromotionBrowseV2Action,
  CategoryBrowseProductV2Action,
  CategoryBrowseMenuV2Action,
  CategoryBrowseServiceV2Action,
  CategoryReadV2Action,
  CategoryEditV2Action,
  CategoryAddV2Action,
  CategoryRemoveV2Action,
  OrderBrowseV2Action,
  OrderReadV2Action,
  OrderRefundV2Action,
  ReviewBrowserV2Action,
  ReviewRemoveV2Action,
  ReviewReadV2Action,
  ReviewEditV2Action,
  ReviewProductBrowserV2Action,
  ReviewServiceBrowserV2Action,
  BookingBrowseV2Action,
  BannerAddV2Action,
  BannerRemoveV2Action,
  BannerReadV2Action,
  BannerBrowseV2Action,
  BannerEditV2Action,
  BannerActiveV2Action,
  BannerDigitalBrowseV2Action,
  BannerDigitalRemoveV2Action,
  BannerDigitalAddV2Action,
  BannerDigitalEditV2Action,
  BannerDigitalReadV2Action,
  DigitalShareBrowseV2Action,
  DigitalShareRemoveV2Action,
  DigitalShareEditV2Action,
  DigitalShareReadV2Action,
  PermissionAddV2Action,
  PermissionRemoveV2Action,
  PermissionReadV2Action,
  PermissionBrowseV2Action,
  PermissionEditV2Action,
  RoleAddV2Action,
  RoleRemoveV2Action,
  RoleReadV2Action,
  RoleBrowseV2Action,
  RoleEditV2Action,
  DeliveryAdapter,
  DeliveryBrowseV2Action,
  DeliveryAddV2Action,
  DeliveryEditV2Action,
  DeliveryRemoveV2Action,
  DeliveryReadV2Action,
  SubcribersBrowseV2Action,
  SubcribersAdapter,
  DigitalScreenActivityAdapter,
  DigitalScreenActivityBrowseV1Action,
  DigitalScreenAdapter,
  DigitalScreenAddV1Action,
  DigitalScreenReadV1Action,
  DigitalScreenBrowseV1Action,
  DigitalScreenEditV1Action,
  DigitalScreenRemoveV1Action,

} from "./services";

import {
  AlertService,
  ActionAdapter,
  BannerAdapter,
  NotisAdapter,
  ClientAdapter,
  ControllerAdapter,
  ConstantAdapter,
  UserAdapter,
  RoleAdapter,
  RolePermissionAdapter,
  UserRoleAdapter,
  CategoryAdapter,
  OrderAdapter,
  BookingAdapter,
  AppContext,
  ProductAdapter,
  ServiceAdapter,
  PromotionAdapter,
  ReviewAdapter,
  BaseService,
  BannerDigitalAdapter,
  DigitalShareAdapter
} from "./services";
import { RestoreService } from "./services/restore.service";
import { ParseService } from "./services/parse.service";

// pipes
import { YesNoPipe, ImageAPIPipe } from "./pipes";

// components
import { UserListComponent } from "./users/user-list/user-list.component";
import { UserEditComponent } from "./users/user-edit/user-edit.component";
import { UserNewComponent } from "./users/user-new/user-new.component";
import { RoleListComponent } from "./roles/role-list/role-list.component";
import { RoleItemComponent } from "./roles/role-item/role-item.component";
import { CategoryListComponent } from "./categories/category-list/category-list.component";
import { CategoryItemComponent } from "./categories/category-item/category-item.component";
import { CategoryNewComponent } from "./categories/category-new/category-new.component";
import { OrdersComponent } from "./orders/orders.component";
import { OrderItemComponent } from "./orders/order-item/order-item.component";
import { BannerListComponent } from "./banners/banner-list/banner-list.component";
import { BannerItemComponent } from "./banners/banner-item/banner-item.component";
import { BannerInfoComponent } from "./banners/banner-info/banner-info.component";
import { BannerDigitalListComponent } from "./banners-digital/banner-list/banner-list.component";
import { BannerDigitalItemComponent } from "./banners-digital/banner-item/banner-item.component";
import { BannerDigitalInfoComponent } from "./banners-digital/banner-info/banner-info.component";
import { DigitalShareListComponent } from "./digitals-share/digital-list/digital-list.component";
import { DigitalShareInfoComponent } from "./digitals-share/digital-info/digital-info.component";

import { DigitalScreenListComponent } from "./digitals-screen/screen-list/screen-list.component";

import { MenuListComponent } from "./menus/menu-list/menu-list.component";
import { MenuItemComponent } from "./menus/menu-item/menu-item.component";
import { MenuInfoComponent } from "./menus/menu-info/menu-info.component";
import { ProductListComponent } from "./products/product-list/product-list.component";
import { ProductItemComponent } from "./products/product-item/product-item.component";
import { ProductInfoComponent } from "./products/product-info/product-info.component";
import { ServiceItemComponent } from "./service/service-item/service-item.component";
import { ServiceListComponent } from "./service/service-list/service-list.component";
import { ServiceInfoComponent } from "./service/service-info/service-info.component";
import { OrderDetailComponent } from "./orders/order-detail/order-detail.component";
import { UserInfoComponent } from "./users/user-info/user-info.component";
import { PromotionListComponent } from "./promotions/promotion-list/promotion-list.component";
import { ReviewsComponent } from "./reviews/reviews.component";
import { ReviewEditComponent } from "./reviews/review-edit/review-edit.component";
import { httpInterceptorProviders } from "./interceptors";
import { ReviewListComponent } from "./reviews/review-list/review-list.component";
import { PasswordEditComponent } from "./users/user-password/password-edit.component";
import { BookingsComponent } from "./bookings/bookings.component";
import { SubscribersComponent } from "./subscribers/subscribers.component";
import { AppLoaderModule } from "./core/app-loader/app-loader.module";
import { PermissionInfoComponent } from "./permissions/permission-info/permission-info.component";
import { PermissionListComponent } from "./permissions/permission-list/permission-list.component";
import { PermissionItemComponent } from "./permissions/permission-item/permission-item.component";
import { DeliveryListComponent } from "./deliveries/delivery-list/delivery-list.component";
import { DeleveryItemComponent } from "./deliveries/delivery-item/delivery-item.component";
import { DeliveryInfoComponent } from "./deliveries/delivery-info/delivery-info.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

const perfectScrollbarConfig: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    RoutingModule,
    MomentModule,
    FlexLayoutModule,
    NgxDatatableModule,
    Ng5BreadcrumbModule.forRoot(),
    PerfectScrollbarModule,
    MenuToggleModule,
    HttpClientModule,
    ModalDialogModule,
    BusyIndicatorModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

    InputTextModule,
    ChartModule,
    CalendarModule,
    DataTableModule,
    ButtonModule,
    MessagesModule,
    DialogModule,
    SelectButtonModule,
    AutoCompleteModule,
    SharedModule,
    AccordionModule,
    ToolbarModule,
    PanelModule,
    FieldsetModule,
    TooltipModule,
    TreeTableModule,
    DragDropModule,
    CKEditorModule,
    AngularDateTimePickerModule,
    // TreeNode,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppLoaderModule,
  ],
  entryComponents: [
    ActionListComponent,
    ClientItemComponent,
    DeleteConfirmDialogComponent,
    DialogInputComponent,
    DialogInfoComponent
  ],
  declarations: [
    YesNoPipe,
    ImageAPIPipe,
    GeneAppComponent,
    MainComponent,
    DashboardComponent,
    BlankComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    LockScreenComponent,
    ClientItemComponent,
    ClientListComponent,
    ControllerListComponent,
    ControllerItemComponent,
    DeleteConfirmDialogComponent,
    DialogInputComponent,
    DialogInfoComponent,
    ActionListComponent,
    ActionItemComponent,
    UserListComponent,
    UserEditComponent,
    UserNewComponent,
    RoleListComponent,
    RoleItemComponent,
    RoleInfoComponent,
    UserProfileComponent,
    CategoryListComponent,
    CategoryItemComponent,
    CategoryNewComponent,
    OrdersComponent,
    OrderItemComponent,
    BannerListComponent,
    BannerItemComponent,
    BannerInfoComponent,
    DigitalScreenListComponent,
    BannerDigitalListComponent,
    BannerDigitalItemComponent,
    BannerDigitalInfoComponent,
    DigitalShareListComponent,
    DigitalShareInfoComponent,
    MenuListComponent,
    MenuItemComponent,
    MenuInfoComponent,
    ProductListComponent,
    ProductItemComponent,
    ProductInfoComponent,
    ServiceItemComponent,
    ServiceListComponent,
    ServiceInfoComponent,
    UserInfoComponent,
    PromotionListComponent,
    ReviewsComponent,
    ReviewEditComponent,
    OrderDetailComponent,
    ReviewListComponent,
    PasswordEditComponent,
    BookingsComponent,
    SubscribersComponent,
    PermissionItemComponent,
    PermissionListComponent,
    PermissionInfoComponent,
    DeliveryListComponent,
    DeleveryItemComponent,
    DeliveryInfoComponent,
  ],
  bootstrap: [GeneAppComponent],
  providers: [
    httpInterceptorProviders,
    MenuItems,
    BreadcrumbService,
    PageTitleService,
    AuthGuard,
    AuthService,
    PromotionBrowseV1Action,
    UserAddExternalV1Action,
    UserAddInternalV1Action,
    UserBrowseExternalV1Action,
    UserBrowseInternalV1Action,
    UserEditImageV1Action,
    UserEditV1Action,
    UserReadV1Action,
    UserRemoveV1Action,
    UserSearchV1Action,
    OrderReadV1Action,
    OrderBrowseV1Action,
    OrderRefundV1Action,
    CategoryAddV1Action,
    CategoryBrowseProductV1Action,
    CategoryBrowseServiceV1Action,
    CategoryEditV1Action,
    CategoryReadV1Action,
    CategoryRemoveV1Action,
    ActionAdapter,
    BannerAdapter,
    NotisAdapter,
    BannerDigitalAdapter,
    DigitalShareAdapter,
    DigitalScreenActivityAdapter,
    DigitalScreenActivityBrowseV1Action,

    DigitalScreenAdapter,
    DigitalScreenAddV1Action,
    DigitalScreenReadV1Action,
    DigitalScreenBrowseV1Action,
    DigitalScreenEditV1Action,
    DigitalScreenRemoveV1Action,



    BannerAddV2Action,
    BannerRemoveV2Action,
    BannerReadV2Action,
    BannerBrowseV2Action,
    BannerEditV2Action,
    BannerActiveV2Action,
    BannerDigitalBrowseV2Action,
    BannerDigitalRemoveV2Action,
    BannerDigitalAddV2Action,
    BannerDigitalEditV2Action,
    BannerDigitalReadV2Action,
    DigitalShareBrowseV2Action,
    DigitalShareRemoveV2Action,
    DigitalShareEditV2Action,
    DigitalShareReadV2Action,
    ClientAdapter,
    ControllerAdapter,
    ConstantAdapter,
    RestoreService,
    ParseService,
    UserAdapter,
    RoleAdapter,
    RoleAddV2Action,
    RoleRemoveV2Action,
    RoleReadV2Action,
    RoleBrowseV2Action,
    RoleEditV2Action,
    RoleAddOrUpdateV1Action,
    RoleBrowseOtherV1Action,
    RoleBrowseV1Action,
    RoleReadV1Action,
    RoleRemoveV1Action,
    AlertService,
    UserRoleAdapter,
    UserRoleReadV1Action,
    UserRoleAssignV1Action,
    ClientAddOrUpdateV1Action,
    ClientReadV1Action,
    ClientBrowseV1Action,
    ClientRemoveV1Action,
    RolePermissionAdapter,
    CategoryAdapter,
    OrderAdapter,
    BookingAdapter,
    AppContext,
    ProductAdapter,
    ServiceAdapter,
    PromotionAdapter,
    ReviewAdapter,
    BaseService,
    ProductAddV1Action,
    ProductRemoveV1Action,
    ProductReadV1Action,
    ProductBrowseV1Action,
    ProductEditV1Action,
    ServiceAddV1Action,
    ServiceRemoveV1Action,
    ServiceReadV1Action,
    ServiceBrowserV1Action,
    ServiceEditV1Action,
    RolePermissionActionV1Action,
    RolePermissionControllerV1Action,
    RolePermissionAssignV1Action,
    ActionRemoveV1Action,
    ActionRemoveControllerV1Action,
    ActionReadV1Action,
    ActionReadControllerV1Action,
    ActionAddOrUpdateV1Action,
    ControllerRemoveV1Action,
    ControllerReadV1Action,
    ControllerBrowseV1Action,
    ControllerAddOrUpdateV1Action,
    ConstantReadV1Action,
    NotisBrowseV1Action,
    /* V2 */
    UserBrowseExternalV2Action,
    UserBrowseInternalV2Action,
    UserReadV2Action,
    UserEditV2Action,
    UserBrowseV2Action,
    UserSearchV2Action,
    ProductBrowseV2Action,
    ProductReadV2Action,
    ProductRemoveV2Action,
    ProductEditV2Action,
    ProductAddV2Action,
    ProductImportV2Action,
    ServiceBrowserV2Action,
    ServiceReadV2Action,
    ServiceEditV2Action,
    ServiceRemoveV2Action,
    ServiceAddV2Action,
    PromotionBrowseV2Action,
    CategoryBrowseProductV2Action,
    CategoryBrowseMenuV2Action,
    CategoryBrowseServiceV2Action,
    CategoryReadV2Action,
    CategoryEditV2Action,
    CategoryAddV2Action,
    CategoryRemoveV2Action,
    OrderBrowseV2Action,
    OrderReadV2Action,
    OrderRefundV2Action,
    ReviewBrowserV2Action,
    ReviewRemoveV2Action,
    ReviewReadV2Action,
    ReviewEditV2Action,
    ReviewProductBrowserV2Action,
    ReviewServiceBrowserV2Action,
    BookingBrowseV2Action,
    BannerBrowseV2Action,
    PermissionAddV2Action,
    PermissionRemoveV2Action,
    PermissionReadV2Action,
    PermissionBrowseV2Action,
    PermissionEditV2Action,
    PermissionAdapter,
    DeliveryAdapter,
    DeliveryAddV2Action,
    DeliveryBrowseV2Action,
    DeliveryEditV2Action,
    DeliveryRemoveV2Action,
    DeliveryReadV2Action,
    SubcribersBrowseV2Action,
    SubcribersAdapter,
  ],
})
export class GeneAppModule {}
