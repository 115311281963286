import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class RolePermissionAssignV1Action extends ActionEntityV1Abstract {

  //  POST /users
  exec(options: any): Observable<any> {
    let body = `RoleId=${options.roleId}`;

    options.controllerIds.forEach((val, idx) => {
      body += `&ControllerIds[${idx}]=${val}`;
    });

    options.actionIds.forEach((val, idx) => {
      body += `&ActionIds[${idx}]=${val}`;
    });
    return this.http.post(`${this.apiOauthUrl}/api/manage/Permission/Assign`, body);
  }
}
