import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { AppLoaderService } from './app-loader.service';

@Component({
  selector: 'ms-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent implements OnInit {

  constructor(public appLoaderService: AppLoaderService,
    private el: ElementRef,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.appLoaderService.showLoading.subscribe(show => {
      const loader = this.el.nativeElement.querySelector('.app-loader-spinner-container');
      if (show) {
        return this.renderer.addClass(loader, 'show');
      }
      this.renderer.removeClass(loader, 'show');
    });
  }

}
