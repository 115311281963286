import { Permission } from './../../../models/permission';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';

@Injectable()
export class PermissionEditV2Action extends ActionEntityV2Abstract {
  exec(permission: Permission): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}/permission/${permission.id}`,
      permission.toFormData()
    );
  }
}
