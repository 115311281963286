import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class UserBrowseInternalV2Action extends ActionEntityV2Abstract {

    //  GET /users/internal
    exec(options: any): Observable<any> {
        return this.http.get(`${this.apiUrl}/users`);
    }
}
