import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { OrderReadV1Action } from "./order-read-v1.action";
import { OrderBrowseV1Action } from "./order-browse-v1.action";
import { OrderRefundV1Action } from "./order-refund-v1.action";

import { OrderBrowseV2Action } from "./v2/order-browse-v2.action";
import { OrderReadV2Action } from "./v2/order-read-v2.action";
import { OrderRefundV2Action } from "./v2/order-refund-v2.action";

@Injectable()
export class OrderAdapter {
  constructor(
    private read: OrderReadV2Action,
    private browse: OrderBrowseV2Action,
    private refund: OrderRefundV2Action,
  ) {}

  getAllOrders(params): Observable<any> {
    return this.browse.exec(params);
  }

  getOrderDetail(id): Observable<any> {
    return this.read.exec({id});
  }

  refundOrder({ id, orderItem }): Observable<any> {
    return this.refund.exec({ id, orderItem });
  }
}
