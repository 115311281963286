import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../action-entity-v2.abstract";

@Injectable()
export class ReviewRemoveV2Action extends ActionEntityV2Abstract {

  // Simulate DELETE /products/:id
  exec(options: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/reviews?id[]=${options.id}`);
  }
}
