import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { PageTitleService } from "../core/page-title/page-title.service";
import { BookingAdapter, BaseService, SubcribersAdapter } from "../services";
import { MatDialog, PageEvent } from "@angular/material";

import { TreeNode } from "primeng/api";

import { FormControl } from "@angular/forms";

import {
  fadeInAnimation,
  ModalDialogService,
  BusyIndicatorService,
} from "../core";
import { ParseService } from "../services/parse.service";

@Component({
  selector: "app-ms-bookings",
  templateUrl: "./subscribers.component.html",
  styleUrls: ["./subscribers.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": "true" },
  animations: [fadeInAnimation],
})
export class SubscribersComponent implements OnInit {
  selectedNode: TreeNode;
  selectedTabIndex = 0;

  public pageEvent: PageEvent;
  public pageIndex: number;

  isLoading: boolean;
  errorMessage: string;
  deleteId: number;
  loadingIndicator = false;
  basePath = "orders";
  subcribers: any;
  pageSize = 0;
  length = 0;

  params = {
    page: 1,
    search: "",
    status: "",
    fromDate: "",
    toDate: "",
    email: "",
  };

  fromDate = new FormControl();
  toDate = new FormControl();
  search = new FormControl();
  email = new FormControl();

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private activatedRoute: ActivatedRoute,
    private busyIndicatorService: BusyIndicatorService,
    private router: Router,
    private bookingAdapter: BookingAdapter,
    private subcribersAdapter: SubcribersAdapter,
    private parseService: ParseService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle("Subcribers Management");

    this.getSubcribers(this.params);
  }

  public getSubcribers(params) {
    this.busyIndicatorService.show();
    this.isLoading = true;
    this.loadingIndicator = true;

    this.subcribersAdapter.getAllSubcribers(params).subscribe(
      (response) => {
        if (response) {
          if (response.data.length >= 0) {
            const items = this.parseService.multi(response);
            this.subcribers = items;
            this.length = response.meta.totalItems;
            this.pageSize = 20;
          }
        }
        this.loadingIndicator = false;
      },
      (error) => {
        this.errorMessage = <any>error;
        this.loadingIndicator = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  public filterBookings() {
    const datePipe = new DatePipe("en-US");
    const fromDate =
      datePipe.transform(this.fromDate.value, "yyyy-MM-dd") || "";
    const toDate = datePipe.transform(this.toDate.value, "yyyy-MM-dd") || "";
    this.params.page = 1;
    this.params.email = this.email.value;
    this.params.fromDate = fromDate;
    this.params.toDate = toDate;

    this.getSubcribers(this.params);
  }

  // // Get all products
  getPaginationSubcribers(event?: PageEvent) {
    this.params = {
      ...this.params,
      page: event.pageIndex + 1,
    };
    this.getSubcribers(this.params);
  }
}
