import { BaseObject } from "./base-object";

export class Client extends BaseObject {
  static fromData(data: any) {
    const {
      Id,
      ClientKey,
      Name,
      ApplicationType,
      AccessTokenLifeTime,
      RefreshTokenLifeTime,
      Active,
      AllowedOrigin
    } = data;

    return new this(
      Id,
      ClientKey,
      Name,
      ApplicationType,
      AccessTokenLifeTime,
      RefreshTokenLifeTime,
      Active,
      AllowedOrigin
    );
  }

  constructor(
    public Id: number = null,
    public ClientKey: string, // client key
    public Name: string,
    public ApplicationType: number,
    public AccessTokenLifeTime: number, // in miliseconds
    public RefreshTokenLifeTime: number, // in miliseconds
    public Active?: boolean,
    public AllowedOrigin?: string // public secret: string,
  ) {
    super();
  }
}
