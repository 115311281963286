import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class ProductImportV2Action extends ActionEntityV2Abstract {

  // Simulate POST /products/import
  exec(file: File, type: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', type);
    return this.http.post(`${this.apiUrl}/products/import`, formData);
  }

}
