// import { Injectable } from '@angular/core';
// import { Observable } from "rxjs";
// import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

// @Injectable()
// export class OrderBrowseV1Action extends ActionEntityV1Abstract {

//   exec(options: any): Observable<any> {
//     const filter = `&Status=${options.selectedStatus}&From=${options.fromDate}&To=${options.toDate}&Search=${options.search}`;
//     return this.http.get(`${this.apiBaseUrl}/api/admin/orders?PageNo=${options.page}${filter}`
//     );
//   }

// }

import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class OrderBrowseV2Action extends ActionEntityV2Abstract {

  // Simulate GET /clients
  exec(options: any): Observable<any> {
    const number = options && options.page ? options.page : 1;
    const size = options && options.number ? options.number : 20;
    const status = options && options.status ? `status[]=${options.status}` : '';

    return this.http.get(
      `${this.apiUrl}/orders?page[number]=${number}&page[size]=${size}&${status}`,
    );
  }
}
