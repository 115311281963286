import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class ProductRemoveV1Action extends ActionEntityV1Abstract {

  // Simulate DELETE /products/:id
  exec(options: any): Observable<any> {
    return this.http.delete(`${this.apiBaseUrl}/Product/DeleteProduct?ProductId=${options.id}`);
  }

}
