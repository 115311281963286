import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class ControllerReadV1Action extends ActionEntityV1Abstract {

  // Simulate POST /clients
  exec(options: any): Observable<any> {
    return this.http.get(`${this.apiOauthUrl}/api/manage/ControllerInfo/${options.controllerId}`);
  }
}
