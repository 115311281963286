import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDialogService } from "../../core/modalDialog/modalDialog.service";
import { BusyIndicatorService } from "../../core/busyIndicator/busyIndicator.service";
import { MatDialog } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { ActionAdapter, ControllerAdapter, BaseService } from "../../services/index";

@Component({
  selector: "app-ms-action-list",
  templateUrl: "./action-list.component.html",
  styleUrls: ["./action-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ActionListComponent implements OnInit {
  rows = [];
  isLoading: boolean;
  errorMessage: string;
  controllers = [];
  public deleteId: number;
  loadingIndicator = false;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private actionAdapter: ActionAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalDialogService: ModalDialogService,
    protected busyIndicatorService: BusyIndicatorService,
    private dialog: MatDialog,
    private controllerAdapter: ControllerAdapter
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle("Actions Management");
    this.getRows();
    this.getControllers();
  }

  public getRows() {
    this.busyIndicatorService.show();
    this.isLoading = true;
    this.loadingIndicator = true;

    // TODO: get by default first time
    this.actionAdapter.getByControllerId(1).subscribe(
      data => {
        this.rows = data.data;
        this.loadingIndicator = false;
      },
      error => {
        this.errorMessage = <any>error;
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.isLoading = false;
      }
    );
  }

  openDialog(id: number): void {
    this.deleteId = id;
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.router.navigate(["actions"]);
        this.actionAdapter.deleteById(this.deleteId).subscribe(res => {
          this.getRows();
        });
      }
    });
  }

  public edit(item) {
    this.router.navigate([item.Id, "edit"], {
      relativeTo: this.activatedRoute
    });
  }

  private handleServiceError(op: string, err: any) {
    console.error(`${op} error: ${err.message || err}`);
  }

  private getControllers() {
    this.controllerAdapter
      .getAll()
      .subscribe(data => (this.controllers = data.data));
  }

  private getControllerName(id: number) {
    const controller = this.controllers.find(c => c.Id === id);

    if (controller) {
      return controller.ControllerName;
    }
    return "";
  }
}
