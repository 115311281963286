import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class PromotionBrowseV1Action extends ActionEntityV1Abstract {

  exec(options: any): Observable<any> {
    return this.http.get(
      `${this.apiBaseUrl}/Promotion/GetAllPromotion${options.type}ByUserId/${options.id}`,
    );
  }

}
