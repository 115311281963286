import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../action-entity-v2.abstract";

@Injectable()
export class RoleAddOrUpdateV1Action extends ActionEntityV2Abstract {
  exec(options: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/roles`,
      options.role.toFormUrlEncoded()
    );
  }
}
