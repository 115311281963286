import { SnackbarService } from 'app/core/snackbar/snackbar.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTabChangeEvent,
  PageEvent
} from "@angular/material";

import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { ModalDialogService } from "../../core/modalDialog/modalDialog.service";
import { BusyIndicatorService } from "../../core/busyIndicator/busyIndicator.service";
import { UserAdapter, BaseService } from "../../services";
import { ParseService } from "../../services/parse.service";
import { Subscription, Observable } from "rxjs";
import { FormControl } from "@angular/forms";


@Component({
  selector: "app-ms-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class UserListComponent implements OnInit, OnDestroy {
  users: any;
  errorMessage: string;
  public deleteId: number;
  selectedTabIndex: number;
  basePath = "users";
  subscriptions: Subscription[] = [];
  loadingIndicator = false;
  externalUsers: any
  emailControl = new FormControl();
  userControl = new FormControl();
  phoneControl = new FormControl();
  filteredUsers: Observable<string[]>;
  selectedUser: any;
  selectedEmail: any;


  // pagination
  public pageEvent: PageEvent;
  public pageIndex: number;
  public pageSize: number;
  public length: number;
  public pageNo: number;
  public filter: any;
  public totalPage: any;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private userAdapter: UserAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalDialogService: ModalDialogService,
    protected busyIndicatorService: BusyIndicatorService,
    private dialog: MatDialog,
    private parseService: ParseService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle("Users Management");
    this.pageSize = this.baseService.PAGE_SIZE;
    this.getRows();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  public getRows(event?: PageEvent) {
    this.busyIndicatorService.show();
    this.loadingIndicator = true;
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageIndex = event.pageIndex;
    }
    let filter = '';
    if (this.emailControl.value !== "" && !this.baseService.isNil(this.emailControl.value)) {
      filter += `email=*${this.emailControl.value}*`;
    }
    if (this.userControl.value !== "" && !this.baseService.isNil(this.userControl.value)) {
      filter += `&fullname=*${this.userControl.value}*`;
    }
    if (this.phoneControl.value !== "" && !this.baseService.isNil(this.phoneControl.value)) {
      filter += `&phone=*${this.phoneControl.value}*`;
    }
    const subscription = this.userAdapter.getAll(this.pageIndex + 1 , this.pageSize, filter).subscribe(
      values => {
        this.loadingIndicator = false;
        this.busyIndicatorService.hide();
        this.users = this.parseService.multi(values);
        this.length = values.meta.totalItems;
      },
      err => {
        if (err.error.errors.length) {
          this.snackbarService.showError(err.error.errors[0].detail);
        }
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      }
    );
    this.subscriptions.push(subscription);
  }

  openDialog(id: number): void {
    this.deleteId = id;
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        const subscription = this.userAdapter.deleteById(this.deleteId).subscribe(_ => {
          this.getRows();
          this.snackbarService.showSuccess('Deleted successfully');
          this.router.navigate(["users"]);
        }, err => {
          if (err.error.errors.length) {
            this.snackbarService.showError(err.error.errors[0].detail);
          }
        });
        this.subscriptions.push(subscription);
      }
    });
  }

  public edit(item) {
    this.router.navigate([item.id, "edit"], {
      relativeTo: this.activatedRoute
    });
  }

  public onNew() {
    const type = this.selectedTabIndex === 0 ? "internal" : "external";
    this.router.navigate([type, "new"], {
      relativeTo: this.activatedRoute
    });
  }


}
