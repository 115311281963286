import { BusyIndicatorService } from '../../core/busyIndicator/busyIndicator.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DigitalScreenAdapter, BaseService, DigitalScreenActivityAdapter } from "../../services";
import { MatDialog, PageEvent } from "@angular/material";
import {  DialogInputComponent} from "app/dialog-input/dialog-input";
import { DialogInfoComponent } from "app/dialog-info/dialog-info";
import { fadeInAnimation } from "../../core";
import { FormControl } from '@angular/forms';
import { ParseService } from "../../services/parse.service";
import { SnackbarService } from 'app/core/snackbar/snackbar.service';
import { DigitalScreen } from "../../models";

@Component({
  selector: 'app-ms-banner-list',
  templateUrl: './screen-list.component.html',
  styleUrls: ['./screen-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [fadeInAnimation]
})
export class DigitalScreenListComponent implements OnInit {
  rows = [];
  nameControl = new FormControl();
  statusControl = new FormControl();
  pageIndex: number;
  pageSize: number;
  length: number;
  filter: any;
  loadingIndicator = false;
  screenActivities=[];
  basePath = "digitals_share";
  public form: FormGroup;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private screenAdapter: DigitalScreenAdapter,
    private screenActivityAdapter: DigitalScreenActivityAdapter,
    private dialog: MatDialog,
    private router: Router,
    private parseService: ParseService,
    private snackbarService: SnackbarService,
    protected busyIndicatorService: BusyIndicatorService,
  ) {
    this.pageSize = baseService.PAGE_SIZE;
  }

  ngOnInit() {
    this.pageTitleService.setTitle("Digitals Screen Management");
    this.getScreens();
  }

  // Get all banners
  getScreens(event?: PageEvent) {
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageIndex = event.pageIndex;
    }
    let filter = '';
    const name = this.nameControl.value;
    const is_online = this.statusControl.value;
    if (name !== "" && !this.baseService.isNil(name)) {
      filter = `name=*${name}*`;
    }
    if (is_online !== "" && !this.baseService.isNil(is_online)) {
      filter = `&is_online=${is_online}`;
    }
    this.requestApi(this.pageIndex + 1, filter, this.pageSize);
  }
  public requestApi(pageNo, filter, pageSize?) {
    this.busyIndicatorService.show();
    this.loadingIndicator = true;
    this.screenAdapter.getAll(pageNo, pageSize, filter).subscribe(
      data => {
        if (!data) {
          return;
        }
        this.rows = this.parseService.multi(data);
        this.loadingIndicator = false;
        this.length = data.meta.totalItems;
      },
      error => {
        this.snackbarService.showError(`Error : ${error}`);
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      }
    );
  }
  public requestApiGetScreenActivity(id, pageNo, pageSize?) {
    this.busyIndicatorService.show();
    this.loadingIndicator = true;
    const filter = `digital_screen_id=${id}`;
    this.screenActivityAdapter.getAll(pageNo, pageSize, filter).subscribe(
      data => {
        if (!data) {
          return false;
        }
        this.screenActivities = this.parseService.multi(data);
        this.loadingIndicator = false;
      },
      error => {
        this.snackbarService.showError(`Error : ${error}`);
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      }
    );
  }
  getRowClass = (row) => {
    return row.is_online == true?'online':'offline' ;
  };
  onOpenDialogInfo(item: DigitalScreen){
    const screen = DigitalScreen.fromData(item);
    const filter = `digital_screen_id=${screen.id}`;
    this.screenActivityAdapter.getAll(1, 30, filter).subscribe(
      data => {
        if (!data) {
          return false;
        }
        const logs = this.parseService.multi(data);
        const dialogRef = this.dialog.open(DialogInfoComponent, {'data':{
          width: "600px" , 'logs': logs, 'screen':item
        }});
      },
      error => {
        this.snackbarService.showError(`Error : ${error}`);
      },
    );



  }
  onChangeStatus(item: DigitalScreen, slideToggle): void {
    let data = {
      width: "300px" , 'screen': item,
    };
    const dialogRef = this.dialog.open(DialogInputComponent, {data});
    const screen = DigitalScreen.fromData(item);
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK.agree) {

        screen.admin_active_flg = !item.admin_active_flg;
        screen.disable_reason = responseOK.reason;
        this.screenAdapter.updateById(screen).subscribe(res => {
          this.getScreens();
          this.snackbarService.showSuccess('Updated successfully');
        }, (errors) => {
          errors.errors[0].map(item => {
            this.snackbarService.showError(`Error: ${item.detail}`);
            this.loadingIndicator = false;
          })
        });
      } else{
        slideToggle.checked = !slideToggle.checked;
      }
    });
  }

}
