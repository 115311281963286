import { BusyIndicatorService } from './../../core/busyIndicator/busyIndicator.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { Router } from "@angular/router";
import { BannerDigitalAdapter, BaseService } from "../../services";
import { MatDialog, PageEvent } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { fadeInAnimation } from "../../core";
import { FormControl } from '@angular/forms';
import { ParseService } from "../../services/parse.service";
import { SnackbarService } from 'app/core/snackbar/snackbar.service';

@Component({
  selector: 'app-ms-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [fadeInAnimation]
})
export class BannerDigitalListComponent implements OnInit {
  rows = [];
  nameControl = new FormControl();

  pageIndex: number;
  pageSize: number;
  length: number;
  filter: any;
  loadingIndicator = false;

  basePath = "banners_digital";

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private bannerAdapter: BannerDigitalAdapter,
    private dialog: MatDialog,
    private router: Router,
    private parseService: ParseService,
    private snackbarService: SnackbarService,
    protected busyIndicatorService: BusyIndicatorService
  ) {
    this.pageSize = baseService.PAGE_SIZE;
  }

  ngOnInit() {
    this.pageTitleService.setTitle("Banners Digital Management");
    this.getBanners();
  }

  // Get all banners
  getBanners(event?: PageEvent) {
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageIndex = event.pageIndex;
    }
    let filter = '';
    const name = this.nameControl.value;
    if (name !== "" && !this.baseService.isNil(name)) {
      filter = `name=*${name}*`;
    }
    this.requestApi(this.pageIndex + 1, filter, this.pageSize);
  }

  public requestApi(pageNo, filter, pageSize?) {
    this.busyIndicatorService.show();
    this.loadingIndicator = true;
    this.bannerAdapter.getAll(pageNo, pageSize, filter).subscribe(
      data => {
        if (!data) {
          return;
        }
        this.rows = this.parseService.multi(data);
        this.loadingIndicator = false;
        this.length = data.meta.totalItems;
      },
      error => {
        this.snackbarService.showError(`Error : ${error}`);
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      }
    );
  }

  delete(id) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, { width: "300px" });
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.bannerAdapter.deleteById(id).subscribe(
          data => {
            this.getBanners();
            this.snackbarService.showSuccess('Deleted successfully');
          },
          error => {
            this.snackbarService.showError('Error');
            this.loadingIndicator = false;
          }
        );
      }
    });
  }
}
