import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Delivery } from "../../../models/delivery";
import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';

@Injectable()
export class DeliveryEditV2Action extends ActionEntityV2Abstract {
  exec(delivery: Delivery): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}/deliveries/${delivery.id}`,
      delivery.toFormData()
    );
  }
}
