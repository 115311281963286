import { BusyIndicatorService } from './../../core/busyIndicator/busyIndicator.service';
import { PermissionAdapter } from './../../services/permission/permission.adapter';
import { UserAdapter } from "../../services/index";
import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";

import { User } from "../../models";
import { RestoreService, BaseService } from "../../services";
import { MatDialog } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { RoleAdapter } from "./../../services/";
import { ParseService } from "../../services/parse.service";
import { Subscription } from "rxjs";
import { SnackbarService } from 'app/core/snackbar/snackbar.service';

@Component({
  selector: "app-ms-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class UserEditComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public isLoading: boolean;
  public errorMessage: string;
  public item: User;

  public profileImage: File;
  public backgroundImage: File;

  roles = [];
  permissions = [];
  public isBrowsewrIE = false;
  public isShowAssignRolesForm = false;
  public isShowAssignPermissionForm = false;
  permissionsTable = [];
  rolesTable = [];
  subscriptions: Subscription[] = [];
  defaultImage = '/assets/img/default.png';
  pageSize: any;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userAdapter: UserAdapter,
    private restoreService: RestoreService<User>,
    private dialog: MatDialog,
    private roleAdapter: RoleAdapter,
    private parseService: ParseService,
    private permissionAdapter: PermissionAdapter,
    private snackbarService: SnackbarService,
    protected busyIndicatorService: BusyIndicatorService
  ) {
    this.pageSize = baseService.PAGE_SIZE;
  }

  ngOnInit() {
    const pageTitle = "Edit User";
    this.setItemId();
    this.isItemEdited = true;
    this.getItem();
    this.pageTitleService.setTitle(pageTitle);
    this.profileImage = null;
    this.backgroundImage = null;
    this.isBrowsewrIE = /msie\s|trident/i.test(window.navigator.userAgent);
  }

  ngOnDestroy(): void {
    this.subscriptions.map(item => item.unsubscribe());
  }

  public readIconImage(fileId, imageId, type) {

    const image = this.baseService.readIconImage(fileId, imageId);
    if (type === "profileImage") {
      this.profileImage = image;
    }
    if (type === "backgroundImage") {
      this.backgroundImage = image;
    }
  }

  public getItem() {
    this.isLoading = true;
    const subscription = this.userAdapter.getById(this.id).subscribe(
      item => {
        this.isLoading = false;
        const data = this.parseService.single(item);
        this.restoreService.set(data);
        this.item = this.restoreService.get();
        this.getAssignedPermissionsRoles(this.item);
        this.buildForm(this.item);
      },
      err => {
        if (err.error.errors.length) {
          this.snackbarService.showError(err.error.errors[0].detail);
        }
        this.isLoading = false;
      }
    );

    this.subscriptions.push(subscription);
    this.buildForm();
  }

  private buildForm(item: any = {}) {
    this.form = this.fb.group({
      email: [
        item.email,
        Validators.compose([Validators.required, Validators.email])
      ],
      fullname: [item.fullname],
      phone: [item.phone],
      address: [item.address],
      postcode: [item.postcode],
      verified: [item.verified],
      claimed: [item.claimed],
      created_at: [item.created_at],
      password: [null],
      image: [''],
      background_image: [''],
    });
  }

  public populateDataForNewItem() {
    this.item = <User>{};
    this.item["Id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (!this.id) {
      this.id = currentUser.id;
    }
  }

  onReset() {
    this.form.patchValue(this.restoreService.restoreItem());
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.userAdapter.deleteById(this.id).subscribe(() => {
          this.snackbarService.showSuccess("Deleted Successfully");
          this.router.navigate(["users"]);
        });
      }
    });
  }

  onSubmit() {
    const user = User.fromData(this.form.value);
    user.id = this.id;
    user.image = this.profileImage;
    user.background_image = this.backgroundImage;
    user.permissions = `[${this.permissions.filter(perm => !perm.roleId).map(perm => +perm.id)}]`;
    user.roles = `[${this.roles.map(x => +x.id)}]`;
    user.verified = user.verified ? '1' : '0';
    user.claimed = user.claimed ? '1' : '0';
    this.userAdapter.update(user).subscribe(
      () => {
        this.snackbarService.showSuccess("Updated Successfully");
        this.router.navigate(["users"]);
      },
      err => {
        if (err.error.errors.length) {
          this.snackbarService.showError(err.error.errors[0].detail);
        }
      });
  }

  private getAssignedPermissionsRoles(item) {
    const { permissions: directPermissions, roles } = item;
    const permissions = [...directPermissions];
    roles.forEach(role => {
      const { id: roleId, name: roleName } = role;
      permissions.push(...role.permissions.map(permission => ({ ...permission, roleId, roleName })));
    });
    this.permissions = permissions;
    this.roles = roles;
  }

  showAssignRolesForm() {
    const subscription = this.roleAdapter.getAll(null, -1, '').subscribe(res => {
      const availableRoles = this.parseService.multi(res);
      const roledIds = this.roles.reduce((set, data) => {
        set.add(data.id);
        return set;
      }, new Set()) || new Set();
      this.rolesTable = availableRoles.filter(data => !roledIds.has(data.id));
      this.isShowAssignRolesForm = true;
    }, error => {
      this.snackbarService.showError(`Error : ${error}`);
      this.busyIndicatorService.hide();
    },
      () => {
        this.busyIndicatorService.hide();
      });

    this.subscriptions.push(subscription);
  }

  showAssignPermissionForm() {
    const subscription = this.permissionAdapter.getAll(null, -1, '').subscribe(res => {
      const availablePermissions = this.parseService.multi(res);
      const permissionIds = this.permissions.filter(x => !x.roleId).reduce((set, data) => {
        set.add(data.id);
        return set;
      }, new Set()) || new Set();
      this.permissionsTable = availablePermissions.filter(data => !permissionIds.has(data.id));
      this.isShowAssignPermissionForm = true;
    }, error => {
      this.snackbarService.showError(`Error : ${error}`);
      this.busyIndicatorService.hide();
    }, () => {
      this.busyIndicatorService.hide();
    });

    this.subscriptions.push(subscription);
  }

  updateAssignRoles() {
    const permissions = this.permissions.filter(x => !x.roleId);

    this.roles = [...this.roles, ...this.rolesTable.filter(x => x.isChecked)];
    this.roles.forEach(role => {
      const { id: roleId, name: roleName } = role;
      permissions.push(...role.permissions.map(permission => ({ ...permission, roleId, roleName })));
    });

    this.permissions = permissions;
    this.isShowAssignRolesForm = false;
  }

  cancelAssignRoles() {
    setTimeout(() => {
      this.isShowAssignRolesForm = false;
    }, 50);
  }

  openDialogDeletePermission(data): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        if (data.roleId) {
          this.permissions = this.permissions.filter(x => !x.roleId || x.roleId !== data.roleId);
          this.roles = this.roles.filter(x => x.id !== data.roleId);
        } else {
          this.permissions = this.permissions.filter(x => x.id !== data.id);
        }
      }
    });
  }

  openDialogDeleteRole(data): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.roles = this.roles.filter(x => x.id !== data.id);
        this.permissions = this.permissions.filter(x => !x.roleId || x.roleId !== data.id);
      }
    });
  }

  cancelAssignPermission() {
    setTimeout(() => {
      this.isShowAssignPermissionForm = false;
    }, 50);
  }

  updateAssignPermission() {
    this.permissions = [...this.permissions, ...this.permissionsTable.filter(x => x.isChecked)];
    this.isShowAssignPermissionForm = false;
  }

  filterPermissions(permissions, permissionName: string) {
    const val = permissionName.toLowerCase().trim();
    if (!val) {
      return permissions;
    }
    return permissions.filter(d => d.name.toLowerCase().indexOf(val) !== -1);
  }

  filterRoles(roles, roleName: string) {
    const val = roleName.toLowerCase().trim();
    if (!val) {
      return roles;
    }
    return roles.filter(d => d.name.toLowerCase().indexOf(val) !== -1);
  }
}

