import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SubcribersBrowseV2Action } from "./v2/subcribers-browse-v2.action";

@Injectable()
export class SubcribersAdapter {
  constructor(private browse: SubcribersBrowseV2Action) {}

  getAllSubcribers(params): Observable<any> {
    return this.browse.exec(params);
  }
}
