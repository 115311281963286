// The file for the current environment will overwrite this one during build.
// Different environments can be found in ./environment.{dev|prod}.ts, and
// you can create your own and use it with the --env flag.
// The build system defaults to the dev environment.

export const environment = {
  production: false,

  // URL of development API
  // apiUrl: "https://caterwinoauthdev.azurewebsites.net",
  apiUrl_Web: "https://cardbeycaterwinapidev.azurewebsites.net",
  apiUrl: "https://api.cardbey.com",
  //apiUrl: "http://localhost:11000",
  // apiUrl_Web: "https://cardbeycaterwinapidev.azurewebsites.net",
  pageSize: 5,
  idAdmin: 1
};
