import { Injectable } from '@angular/core';

import { Observable } from "rxjs";
import { Review } from "../../models";
import { ReviewProductBrowserV2Action } from "./review-product-browse-v2.action";
import { ReviewServiceBrowserV2Action } from "./review-service-browse-v2.action";
import { ReviewBrowserV2Action } from "./review-browse-v2.action";
import { ReviewRemoveV2Action } from "./review-remove-v2.action";
import { ReviewReadV2Action } from "./review-read-v2.action";
import { ReviewEditV2Action } from "./review-edit-v2.action";

@Injectable()
export class ReviewAdapter {

  constructor(
    private browse: ReviewBrowserV2Action,
    private browseProduct: ReviewProductBrowserV2Action,
    private browseService: ReviewServiceBrowserV2Action,
    private remove: ReviewRemoveV2Action,
    private read: ReviewReadV2Action,
    private edit: ReviewEditV2Action,
  ) {}

  // Simulate GET /products/:id/revires
  getAllReviews(pageNo: any, filter: any): Observable<any> {
    return this.browse.exec({pageNo, filter});
  }
  // Simulate GET /products/:id/revires
  getAllProductReviews(id: any, pageNo: any, filter: any): Observable<any> {
    return this.browseProduct.exec({id, pageNo, filter});
  }
  // Simulate GET /services/:id/revires
  getAllServiceReviews(id: any, pageNo: any, filter: any): Observable<any> {
    return this.browseService.exec({id, pageNo, filter});
  }

  deleteById(id: number): Observable<any> {
    return this.remove.exec({ id });
  }

  getById(id: number): Observable<any> {
    return this.read.exec({ id });
  }

  //  POST /reviews
  update(review: Review): Observable<any> {
    return this.edit.exec(review);
  }
}
