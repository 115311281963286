import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class UserEditV1Action extends ActionEntityV1Abstract {

  //  POST /users
  exec(options: any): Observable<any> {
    return this.http.post(
      // TODO: update api to fix this work around
      `${this.apiOauthUrl}/api/manage/User/UpdateProfile`,
      options.toFormUrlEncoded().replace("Phone", "PhoneNumber")
    );
  }
}
