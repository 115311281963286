import { Injectable } from '@angular/core';

@Injectable()
export class RestoreService<T> {
  private originalItem: T;
  private currentItem: T;

  public get(): T {
    return this.currentItem;
  }

  public set(item: T) {
    this.originalItem = this.clone(item);
    this.currentItem = item;
  }

  public restoreItem(): T {
    this.currentItem = this.clone(this.originalItem);
    return this.get();
  }

  public clone(item: T): T {
    // return JSON.parse(JSON.stringify(item));
    return item;
  }
}
