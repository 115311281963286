import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { BannerDigitalAdapter, UserAdapter, CategoryAdapter, BaseService } from "../../services";
import { BannerDigital } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { MatDialog } from "@angular/material";

import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { AppContext } from "../../services/appcontext";
import { FileUpload } from "../../../../node_modules/primeng/primeng";
import { SnackbarService } from "app/core/snackbar/snackbar.service";
import { ParseService } from "../../services/parse.service";

@Component({
  selector: "app-ms-banner-item",
  templateUrl: "./banner-item.component.html",
  styleUrls: ["./banner-item.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class BannerDigitalItemComponent implements OnInit {
  public form: FormGroup;
  private id?: number = null;
  private title?: any;
  private description?: any;
  private image_vertical?: any;
  private image_horizontal?: any;
  public isItemEdited: boolean;
  public errorMessage: string;
  public item: BannerDigital;
  private image: any;
  //
  public applicationTypes = [];
  public externalUsers = [];
  basePath = "banners";
  defaultImage = '/assets/img/default.png';

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private bannerAdapter: BannerDigitalAdapter,
    private restoreService: RestoreService<BannerDigital>,
    private dialog: MatDialog,
    private appContext: AppContext,
    private snackbarService: SnackbarService,
    private userAdapter: UserAdapter,
    private parseService: ParseService
  ) { }

  ngOnInit() {
    let pageTitle = "Create New Banner";
    this.setItemId();
    if (this.id === null || this.id === undefined || isNaN(this.id)) {
      this.isItemEdited = false;
      this.populateDataForNewItem();
    } else {
      this.isItemEdited = true;
      pageTitle = "Edit Banner";
      this.getItem();
    }

    this.getExternalUser();
    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
  }

  public getExternalUser() {
    this.userAdapter.getExternalUsers(1, 1000, 1).subscribe(
      data => {
        const user = this.parseService.multi(data);
        this.externalUsers = user;
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  public getItem() {
    this.bannerAdapter.getById(this.id).subscribe(
      item => {
        this.restoreService.set(item);
        this.item = this.parseService.single(this.restoreService.get());
        const details = <any>this.item;
        this.buildForm(details);
      },
      error => {
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm(data = { title: '', description: '', user: { id: null } }) {
    this.form = this.fb.group({
      title: [data.title || null, Validators.compose([Validators.required, Validators.minLength(3)])],
      description: [data.description || null, Validators.compose([])],
      user_id: [data.user.id || null, Validators.compose([Validators.required])],
      image_vertical: this.isItemEdited ? [] : [null, Validators.compose([Validators.required])],
      image_horizontal: this.isItemEdited ? [] : [null, Validators.compose([Validators.required])],
    });
  }

  public populateDataForNewItem() {
    this.item = <BannerDigital>{};
    this.item["id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.bannerAdapter.deleteById(this.id).subscribe(res => {
          this.snackbarService.showSuccess('Deleted successfully');
          this.router.navigate(["banners"]);
        }, error => {
          this.snackbarService.showError('Error');
        });
      }
    });
  }

  onSubmit() {
    const bannerdigital = BannerDigital.fromData(this.form.value);
    bannerdigital.id = this.id;
    bannerdigital.image_vertical = this.image_vertical;
    bannerdigital.image_horizontal = this.image_horizontal;
    if (this.isItemEdited) {
      this.bannerAdapter.updateById(bannerdigital).subscribe(res => {
        this.snackbarService.showSuccess('Updated successfully');
        this.router.navigate(["banners_digital"]);
      }, (errors) => {
        errors.errors[0].map(item => {
          this.snackbarService.showError(`Error: ${item.detail}`);
        })
      });
    } else {
      this.bannerAdapter.create(bannerdigital).subscribe(res => {
        this.snackbarService.showSuccess('Created successfully');
        this.router.navigate(["banners_digital"]);
      }, error => {
        this.snackbarService.showError('Error');
      });
    }
  }

  onCancel() {
    this.location.back();
  }
}
