import { BaseObject } from "./base-object";
import { group } from "@angular/animations";

export class Product extends BaseObject {
  public static readonly defaultIconImage = "/media/category_images/default-category.png";
  static fromData(data: any) {
    const {
      id,
      name,
      price,
      currency_unit,
      description,
      meta_desc,
      meta_keywords,
      primary_image,
      sub_image_1,
      sub_image_2,
      sub_image_3,
      sub_image_4,
      sub_image_5,
      promotion_price,
      category_id,
      type,
      user_id} = data;
    return new this(
      id,
      name,
      price,
      currency_unit,
      description,
      meta_desc,
      meta_keywords,
      primary_image,
      sub_image_1,
      sub_image_2,
      sub_image_3,
      sub_image_4,
      sub_image_5,
      promotion_price,
      category_id,
      type,
      user_id
    );
  }

  constructor(
    public id: number = null,
    public name: string,
    public price: number,
    public currency_unit?: string,
    public description?: string,
    public meta_desc?: string,
    public meta_keywords?: string,
    public primary_image?: any,
    public sub_image_1?: any,
    public sub_image_2?: any,
    public sub_image_3?: any,
    public sub_image_4?: any,
    public sub_image_5?: any,
    public promotion_price?: number,
    public category_id?: any,
    public type?: any,
    public user_id?: any
  ) { super(); }
}
