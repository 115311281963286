import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { Action } from "../../models";
import {MatDialog } from '@angular/material';
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { ActionAdapter, ControllerAdapter, RestoreService, BaseService } from '../../services/index';

const password = new FormControl("", Validators.required);
const confirmPassword = new FormControl("", CustomValidators.equalTo(password));

@Component({
  selector: "app-ms-action-item",
  templateUrl: "./action-item.component.html",
  styleUrls: ["./action-item.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class ActionItemComponent implements OnInit {
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public isLoading: boolean;
  public errorMessage: string;
  public item: Action;
  public controllers = [];
  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private actionAdapter: ActionAdapter,
    private restoreService: RestoreService<Action>,
    private dialog: MatDialog,
    private controllerAdapter: ControllerAdapter
  ) {}

  ngOnInit() {
    let pageTitle = "Create new Action";

    this.setItemId();

    if (this.id === null || this.id === undefined) {
      this.isItemEdited = false;
      this.populateDataForNewItem();
    } else {
      this.isItemEdited = true;
      pageTitle = "Edit Action";
      this.getItem();
    }

    this.getControllers();
    this.pageTitleService.setTitle(pageTitle);
  }

  public getItem() {
    // this.busyIndicatorService.show();
    this.isLoading = true;
    this.actionAdapter.getById(this.id).subscribe(
      item => {
        // this.busyIndicatorService.hide();
        this.isLoading = false;
        this.restoreService.set(item.data);
        this.item = this.restoreService.get();
        this.buildForm();
      },
      error => {
        // this.busyIndicatorService.hide();
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      ControllerInfoId: [
        null,
        Validators.compose([Validators.required])
      ],
      ActionName: [
        null,
        Validators.compose([Validators.required, Validators.minLength(5)])
      ],
      ActionDisplayName: [
        null,
      ],
      ShowAsMenu: [
        null,
      ],
    });

    if (this.item) {
      this.form.patchValue(this.item);
    }
  }

  public populateDataForNewItem() {
    this.item = <Action>{};
    this.item["Id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
  }

  onCancel() {
    this.router.navigate(["actions"]);
  }

  onReset() {
    this.form.patchValue(this.restoreService.restoreItem());
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
          this.actionAdapter
          .deleteById(this.id)
          .subscribe(res => {
            this.router.navigate(["actions"]);
        });
      }
    });
  }

  onSubmit() {
    const action = Action.fromData(this.form.value);
    action.Id = this.id
    this.actionAdapter
      .addOrUpdate(action)
      .subscribe(res => {
        this.router.navigate(["actions"]);
      });
  }

  private getControllers() {
    this.controllerAdapter
      .getAll()
      .subscribe(data => (this.controllers = data.data));
  }

}
