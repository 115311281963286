
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Client } from "../../models/client";
import { ClientAddOrUpdateV1Action } from "./client-add-or-update-v1.action";
import { ClientReadV1Action } from "./client-read-v1.action";
import { ClientRemoveV1Action } from "./client-remove-v1.action";
import { ClientBrowseV1Action } from "./client-browse-v1.action";

@Injectable()
export class ClientAdapter {

  constructor(
        private add_or_update: ClientAddOrUpdateV1Action,
        private browse: ClientBrowseV1Action,
        private read: ClientReadV1Action,
        private remove: ClientRemoveV1Action,
    ) {
  }

  // Simulate POST /clients
  addOrUpdate(client: Client): Observable<any> {
    return this.add_or_update.exec({client});
  }

  // Simulate DELETE /clients/:id
  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }

  // Simulate GET /clients/:id
  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }

  // Simulate GET /clients
  getAll(): Observable<any> {
    return this.browse.exec(null);
  }
}
