import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { fadeInAnimation } from '../../core/route-animation/route.animation';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BannerAdapter, BaseService, DeliveryAdapter } from '../../services';
import { Delivery } from '../../models';
import { RestoreService } from '../../services/restore.service';
import { MatDialog } from '@angular/material';

import { DeleteConfirmDialogComponent } from 'app/dialog/delete-confirm-dialog';
import { AppContext } from '../../services/appcontext';
import { FileUpload } from '../../../../node_modules/primeng/primeng';
import { SnackbarService } from 'app/core/snackbar/snackbar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-ms-delivery-item',
  templateUrl: './delivery-item.component.html',
  styleUrls: ['./delivery-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[@fadeInAnimation]': 'true',
  },
  animations: [fadeInAnimation],
})
export class DeleveryItemComponent implements OnInit {
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public errorMessage: string;
  public item: Delivery;
  private image_1: any;
  private image_2: any;
  private image_3: any;
  public LIST_STATUS = [
    {
      value: 'NEW',
      name: 'NEW'
    },
    {
      value: 'PENDING',
      name: 'PENDING'
    },
    {
      value: 'DONE',
      name: 'DONE'
    },
    {
      value: 'CANCELLED',
      name: 'CANCELLED'
    },
    {
      value: 'IN-PROGRESS',
      name: 'IN PROGRESS'
    },
    {
      value: 'REQUESTED_CANCEL',
      name: 'REQUESTED CANCEL'
    },
    {
      value: 'APPROVED',
      name: 'APPROVED'
    },
    {
      value: 'DENIED',
      name: 'DENIED'
    },
    {
      value: 'DELIVERED',
      name: 'DELIVERED'
    }
  ]
  type: any = 'now';

  settings = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MM-yyyy HH:mm:ss',
    defaultOpen: false,
    closeOnSelect: true,
  };
  //
  public applicationTypes = [];
  basePath = 'deliveries';
  defaultImage = '/assets/img/default.png';

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private bannerAdapter: BannerAdapter,
    private deliveryAdapter: DeliveryAdapter,
    private restoreService: RestoreService<Delivery>,
    private dialog: MatDialog,
    private appContext: AppContext,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    let pageTitle = 'Create New Delivery';
    const { path } = this.activatedRoute.routeConfig;
    if (this.activatedRoute.snapshot.params['id']) {
      this.type =
        path === 'deliveries/:id/edit' || path === 'deliveries/now/:id/edit'
          ? 'now'
          : 'schedule';
    } else {
      this.type =
        path === 'deliveries/new' || path === 'deliveries/now/new'
          ? 'now'
          : 'schedule';
    }
    this.setItemId();
    if (this.id === null || this.id === undefined || isNaN(this.id)) {
      this.isItemEdited = false;
      this.populateDataForNewItem();
    } else {
      this.isItemEdited = true;
      pageTitle = 'Edit Banner';
      this.getItem();
    }

    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
  }

  public getItem() {
    this.deliveryAdapter.getById(this.id).subscribe(
      (item) => {
        this.restoreService.set(item);
        this.item = this.restoreService.get();
        const {
          data: { attributes },
          data: { attributes: parsedData },
        } = <any>this.item;
        this.buildForm({
          ...parsedData,
          pickup_time: moment(attributes.pickup_time, 'x'),
          expect_delivery_time: moment(attributes.expect_delivery_time, 'x'),
        });
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm(
    data = {
      receiver_name: '',
      receiver_email: '',
      receiver_phone: '',
      receiver_address: '',
      pickup_time: '',
      expect_delivery_time: '',
      status: ''
    }
  ) {
    this.form = this.fb.group({
      receiver_name: [
        data.receiver_name || null,
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      receiver_email: [
        data.receiver_email || null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.email,
        ]),
      ],
      receiver_phone: [
        data.receiver_phone || null,
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      receiver_address: [
        data.receiver_address || null,
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      image_1: this.isItemEdited
        ? []
        : [null, Validators.compose([Validators.required])],
      image_2: this.isItemEdited ? [] : [],
      image_3: this.isItemEdited ? [] : [],
      pickup_time: [data.pickup_time || new Date()],
      expect_delivery_time: [data.expect_delivery_time || new Date()],
      status: [data.status || null],
    });

    if (this.item) {
      this.form.patchValue(this.item);
    }
  }

  public populateDataForNewItem() {
    this.item = <Delivery>{};
    this.item['id'] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params['id'] === 'new'
        ? null
        : +this.activatedRoute.snapshot.params['id'];
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe((responseOK) => {
      if (responseOK) {
        this.bannerAdapter.deleteById(this.id).subscribe(
          (res) => {
            this.snackbarService.showSuccess('Deleted successfully');
            this.router.navigate(['banners']);
          },
          (error) => {
            this.snackbarService.showError('Error');
          }
        );
      }
    });
  }

  onSubmit() {
    const delivery = Delivery.fromData(this.form.value);
    const { path } = this.activatedRoute.routeConfig;
    delivery.id = this.id;
    delivery.image_1 = this.image_1;
    delivery.image_2 = this.image_2;
    delivery.image_3 = this.image_3;
    if (this.type === 'schedule') {
      delivery.pickup_time = delivery.pickup_time
        ? moment(delivery.pickup_time).format('x')
        : undefined;
      delivery.expect_delivery_time = delivery.expect_delivery_time
        ? moment(delivery.expect_delivery_time).format('x')
        : undefined;
    } else {
      delivery.pickup_time = null;
      delivery.expect_delivery_time = null;
    }
    delivery.type = this.type === 'now' ? 'DELIVERY_NOW' : 'DELIVERY_SCHEDULE';
    if (this.isItemEdited) {
      this.deliveryAdapter.updateById(delivery).subscribe(
        (res) => {
          this.snackbarService.showSuccess('Updated successfully');
          this.router.navigate([`deliveries/${this.type}`]);
        },
        (errors) => {
          errors.errors[0].map((item) => {
            this.snackbarService.showError(`Error: ${item.detail}`);
          });
        }
      );
    } else {
      this.deliveryAdapter.create(delivery).subscribe(
        (res) => {
          this.snackbarService.showSuccess('Created successfully');
          this.router.navigate([`deliveries/${this.type}`]);
        },
        (error) => {
          this.snackbarService.showError('Error');
        }
      );
    }
  }

  onCancel() {
    this.location.back();
  }
}
