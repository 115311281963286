import { Injectable } from '@angular/core';

import build from 'redux-object';
import normalize from 'json-api-normalizer';

@Injectable()
export class ParseService {

  public single(json) {
    const schemaData = normalize(json, {
      camelizeTypeValues: false,
      camelizeKeys: false
    });
    return build(schemaData, json.data.type, json.data.id);
  }
  public multi(json) {
    const items = [];
    json.data.forEach((row, index) => {
      const schemaData = normalize(json, {
        camelizeTypeValues: false,
        camelizeKeys: false
      });
      items.push(build(schemaData, row.type, row.id));
    });
    return items;
  }

}
