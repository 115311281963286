import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class OrderRefundV2Action extends ActionEntityV2Abstract {

  // Simulate POST /orders/:id
  exec(options: any): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/orders/${options.id}/refund`,
      JSON.stringify({ items: options.orderItem }),
      { headers: { "Content-Type": 'application/json' } }
    );
  }
}
