import { BusyIndicatorService } from './../../core/busyIndicator/busyIndicator.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BannerAdapter, BaseService } from "../../services";
import { Banner } from "../../models";
import { MatDialog, PageEvent } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { fadeInAnimation } from "../../core";
import { FormControl } from '@angular/forms';
import { ParseService } from "../../services/parse.service";
import { SnackbarService } from 'app/core/snackbar/snackbar.service';

@Component({
  selector: 'app-ms-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [fadeInAnimation]
})
export class BannerListComponent implements OnInit {
  rows = [];
  nameControl = new FormControl();
  treeNodes: Array<object>;
  selectedTabIndex = 0;

  pageIndex: number;
  pageSize: number;
  length: number;
  filter: any;
  loadingIndicator = false;
  type: any = 'web'

  basePath = "banners";

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private bannerAdapter: BannerAdapter,
    private dialog: MatDialog,
    private router: Router,
    private parseService: ParseService,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
    protected busyIndicatorService: BusyIndicatorService
  ) {
    this.pageSize = baseService.PAGE_SIZE;
  }

  ngOnInit() {
    this.pageTitleService.setTitle("Banners Management");
    this.setTabIndex();
    this.getBanners();
    const { path } = this.activatedRoute.routeConfig;
    const type = path === 'banners' || path === 'banners/web' ? 'web' : 'mobile';
    this.type = type;
    this.treeNodes = [
      { id: 'Banner Web' },
      { id: 'Banner Mobile' },
    ];
  }

  // Get all banners
  getBanners(event?: PageEvent) {
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageIndex = event.pageIndex;
    }
    let filter = '';
    const name = this.nameControl.value;
    if (name !== "" && !this.baseService.isNil(name)) {
      filter = `name=*${name}*`;
    }
    this.requestApi(this.pageIndex + 1, filter, this.pageSize);
  }

  switchActive(id: number, status: any) {
    const banner = Banner.fromData({
      id: id,
      status: status == 0 ? '1' : '0'
    });
    this.bannerAdapter.updateById(banner).subscribe(res => {
      this.getBanners();
      this.router.navigate([`banners`]);
    }, (errors) => {
      errors.errors[0].map(item => {
        this.snackbarService.showError(`Error: ${id}`);
      })
    });
  }

  switchType(id: number, type: string) {
    const banner = Banner.fromData({
      id: id,
      type: type == 'web' ? 'mobile' : 'web'
    });
    this.bannerAdapter.updateById(banner).subscribe(res => {
      this.snackbarService.showSuccess('Updated successfully');
      this.getBanners();
    }, (errors) => {
      errors.errors[0].map(item => {
        this.snackbarService.showError(`Error: ${id}`);
      })
    });
  }

  public requestApi(pageNo, filter, pageSize?) {
    this.busyIndicatorService.show();
    this.loadingIndicator = true;
    const { path } = this.activatedRoute.routeConfig;
    const type = path === 'banners' || path === 'banners/web' ? 'web' : 'mobile';
    this.bannerAdapter.getAll(pageNo, pageSize, filter, type).subscribe(
      data => {
        if (!data) {
          return;
        }
        this.rows = this.parseService.multi(data);
        let extVideo = ['ogm', 'wvm', 'mpg', 'webm', 'ogv', 'omv', 'asx', 'mpeg', 'mp4', 'avi', 'mov', 'm4v'];
        this.rows.map(item => {
          let imageUrl = item.image ? item.image.url : '';
          item.isVideo = imageUrl && extVideo.includes(imageUrl.substr(imageUrl.lastIndexOf(".") + 1));
        })
        this.loadingIndicator = false;
        this.length = data.meta.totalItems;
      },
      error => {
        this.snackbarService.showError(`Error : ${error}`);
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      }
    );
  }

  setTabIndex() {
    const { path } = this.activatedRoute.routeConfig;
    this.selectedTabIndex = path === "banners" || path === "banners/web" || path === null || path === undefined ? 0 : 1;
  }

  public onSelectedTabChange(evt) {
    location.assign("#/" + this.basePath + "/" + this.getCategoryGroup());
  }

  getCategoryGroup(): string { return this.selectedTabIndex === 0 ? "web" : "mobile"; }

  delete(id) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, { width: "300px" });
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.bannerAdapter.deleteById(id).subscribe(
          data => {
            this.getBanners();
            this.snackbarService.showSuccess('Deleted successfully');
          },
          error => {
            this.snackbarService.showError('Error');
            this.loadingIndicator = false;
          }
        );
      }
    });
  }
}
