import { BaseObject } from "./base-object";

export class Controller extends BaseObject {
  static fromData(data: any) {
    const { Id, ControllerName } = data;

    return new this(Id, ControllerName);
  }

  constructor(public Id: number = null, public ControllerName: string) {
    super();
  }
}
