import { BaseObject } from './base-object';

export class Delivery extends BaseObject {
  static fromData(data: any) {
    const {
      id,
      receiver_name,
      price,
      receiver_email,
      receiver_phone,
      receiver_address,
      pickup_time,
      expect_delivery_time,
      path,
      image_1,
      image_2,
      image_3,
      type,
      status
    } = data;
    return new this(
      id,
      path,
      receiver_name,
      price,
      receiver_email,
      receiver_phone,
      receiver_address,
      pickup_time,
      expect_delivery_time,
      image_1,
      image_2,
      image_3,
      type,
      status
    );
  }

  constructor(
    public id: number = null,
    public path: string,
    public receiver_name: string,
    public price: number,
    public receiver_email: string,
    public receiver_phone: string,
    public receiver_address: string,
    public pickup_time: string,
    public expect_delivery_time: string,
    public image_1?: File,
    public image_2?: File,
    public image_3?: File,
    public type: string = 'DELIVERY_NOW',
    public status: string = 'NEW'
  ) {
    super();
  }
}
