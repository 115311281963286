import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { PageTitleService } from "../core/page-title/page-title.service";
import { BookingAdapter, BaseService } from "../services";

import {TreeNode} from 'primeng/api';

import {FormControl} from '@angular/forms'

import { fadeInAnimation, ModalDialogService, BusyIndicatorService } from "../core";
import { ParseService } from "../services/parse.service";

@Component({
  selector: 'app-ms-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [ fadeInAnimation ]
})
export class BookingsComponent implements OnInit {
  selectedNode: TreeNode;
  selectedTabIndex = 0;

  isLoading: boolean;
  errorMessage: string;
  deleteId: number;
  loadingIndicator = false;
  basePath = "orders";
  bookings: any;
  pageSize = 0;
  length = 0;

  params = {
    page: 1,
    search: '',
    status: '',
    fromDate: '',
    toDate: ''
  }

  fromDate = new FormControl();
  toDate = new FormControl();
  search = new FormControl();

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private activatedRoute: ActivatedRoute,
    private busyIndicatorService: BusyIndicatorService,
    private router: Router,
    private bookingAdapter: BookingAdapter,
    private parseService: ParseService
  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle("Booking Management");

    this.getBookings(this.params);
  }

  public getBookings(params) {
    this.busyIndicatorService.show();
    this.isLoading = true;
    this.loadingIndicator = true;

    this.bookingAdapter.getAllBookings(params).subscribe(
      response => {
        if (response) {
          if (response.data.length >= 0) {
            const buyingOrders = this.parseService.multi(response);
            this.bookings = buyingOrders;
            console.log(this.bookings);
            this.length = response.meta.totalItems;
            this.pageSize = 20;
          }
        }
        this.loadingIndicator = false;
      },
      error => {
        this.errorMessage = <any>error;
        this.loadingIndicator = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  public filterBookings() {
    const datePipe = new DatePipe("en-US");
    const fromDate = datePipe.transform(this.fromDate.value, 'yyyy-MM-dd') || '';
    const toDate = datePipe.transform(this.toDate.value, 'yyyy-MM-dd') || '';

    this.params.page = 1;
    this.params.search = this.search.value;
    this.params.fromDate = fromDate;
    this.params.toDate = toDate;

    this.getBookings(this.params)
  }
}
