import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Controller } from "../../models/controller";
import { ControllerAddOrUpdateV1Action } from "./controller-add-or-update-v1.action";
import { ControllerBrowseV1Action } from "./controller-browse-v1.action";
import { ControllerReadV1Action } from "./controller-read-v1.action";
import { ControllerRemoveV1Action } from "./controller-remove-v1.action";

@Injectable()
export class ControllerAdapter {

  constructor(
    private add_or_update: ControllerAddOrUpdateV1Action,
    private browse: ControllerBrowseV1Action,
    private read: ControllerReadV1Action,
    private remove: ControllerRemoveV1Action,
  ) {}

  // Simulate POST /controllers
  addOrUpdate(controller: Controller): Observable<any> {
    return this.add_or_update.exec({controller});
  }

  // Simulate DELETE /controllers/:id
  deleteById(controllerId: number): Observable<any> {
    return this.remove.exec({controllerId});
  }

  // Simulate GET /controllers
  getAll(): Observable<any> {
    return this.browse.exec(null);
  }

  // Simulate GET /controllers/:id
  getById(controllerId: number): Observable<any> {
    return this.read.exec({controllerId});
  }
}
