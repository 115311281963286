import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { AppContext } from "./../../services/appcontext";

@Component({
  selector: "app-ms-login-session",
  templateUrl: "./login-component.html",
  styleUrls: ["./login-component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;

  loading = false;
  returnUrl: string;
  errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private appContext: AppContext
  ) {}

  ngOnInit() {
    console.log(this.email, this.password);
    // reset login status
    this.authService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  login() {
    this.loading = true;
    this.authService.signinUser(this.email, this.password).subscribe(
      data => {
        this.appContext.initialize();
        this.router.navigate([this.returnUrl]);
      },
      error => {
        // TODO: can check error code to show in case of network is
        // not available
        this.errorMessage = "Incorrect username or password.";
        this.loading = false;
      }
    );
  }
}
