import { Injectable } from '@angular/core';

import { Observable } from "rxjs";
import { Category } from "../../models/category";
import { CategoryAddV1Action } from "./category-add-v1.action";
import { CategoryBrowseProductV1Action } from "./category-browse-product-v1.action";
import { CategoryBrowseServiceV1Action } from "./category-browse-service-v1.action";
import { CategoryEditV1Action } from "./category-edit-v1.action";
import { CategoryReadV1Action } from "./category-read-v1.action";
import { CategoryRemoveV1Action } from "./category-remove-v1.action";

// V2
import { CategoryBrowseProductV2Action } from "./v2/category-browse-product-v2.action";
import { CategoryBrowseServiceV2Action } from "./v2/category-browse-service-v2.action";
import { CategoryBrowseMenuV2Action } from "./v2/category-browse-menu-v2.action";
import { CategoryReadV2Action } from "./v2/category-read-v2.action";
import { CategoryEditV2Action } from "./v2/category-edit-v2.action";
import { CategoryAddV2Action } from "./v2/category-add-v2.action";
import { CategoryRemoveV2Action } from "./v2/category-remove-v2.action";


@Injectable()
export class CategoryAdapter {
  constructor(
    private add: CategoryAddV2Action,
    private browseProduct: CategoryBrowseProductV2Action,
    private browseService: CategoryBrowseServiceV2Action,
    private browseMenu: CategoryBrowseMenuV2Action,
    private edit: CategoryEditV2Action,
    private read: CategoryReadV2Action,
    private remove: CategoryRemoveV2Action,
  ) {}

  // Simulate GET /clients/:id
  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }

  // Simulate GET /clients
  getAllProductCategories(): Observable<any> {
    return this.browseProduct.exec(null);
  }
  GetAllServiceCategories(): Observable<any> {
    return this.browseService.exec(null);
  }

  GetAllMenuCategories(): Observable<any> {
    return this.browseMenu.exec(null);
  }

  // Simulate POST /clients
  insertCategory(category: Category): Observable<any> {
    return this.add.exec(category);
  }

  updateCategory(category: Category): Observable<any> {
    return this.edit.exec(category);
  }

  // Simulate DELETE /clients/:id
  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }

}
