import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { BannerAdapter, UserAdapter, CategoryAdapter, BaseService } from "../../services";
import { Banner } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { MatDialog } from "@angular/material";

import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { AppContext } from "../../services/appcontext";
import { FileUpload } from "../../../../node_modules/primeng/primeng";
import { SnackbarService } from "app/core/snackbar/snackbar.service";
import { ParseService } from "../../services/parse.service";

@Component({
  selector: "app-ms-banner-item",
  templateUrl: "./banner-item.component.html",
  styleUrls: ["./banner-item.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class BannerItemComponent implements OnInit {
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public errorMessage: string;
  public item: any;
  private image: any;
  public externalUsers = [];
  //
  public applicationTypes = [];
  basePath = "banners";
  defaultImage = '/assets/img/default.png';

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private bannerAdapter: BannerAdapter,
    private restoreService: RestoreService<Banner>,
    private dialog: MatDialog,
    private appContext: AppContext,
    private userAdapter: UserAdapter,
    private snackbarService: SnackbarService,
    private parseService: ParseService
  ) { }

  ngOnInit() {
    let pageTitle = "Create New Banner";
    this.setItemId();
    if (this.id === null || this.id === undefined || isNaN(this.id)) {
      this.isItemEdited = false;
      this.populateDataForNewItem();
    } else {
      this.isItemEdited = true;
      pageTitle = "Edit Banner";
      this.getItem();
    }

    this.getExternalUser();
    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
  }

  public getExternalUser() {
    this.userAdapter.getExternalUsers(1, 1000, 1).subscribe(
      data => {
        const user = this.parseService.multi(data);
        this.externalUsers = user;
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  public getItem() {
    this.bannerAdapter.getById(this.id).subscribe(
      item => {
        this.restoreService.set(item);
        this.item = this.restoreService.get();
        let extVideo = ['ogm', 'wvm', 'mpg', 'webm', 'ogv', 'omv', 'asx', 'mpeg', 'mp4', 'avi', 'mov', 'm4v'];
        let imageUrl = this.item && this.item.data && this.item.data.attributes.image ? this.item.data.attributes.image.url : '';
        const { data: { attributes: parsedData } } = <any>this.item;
        this.buildForm(parsedData);
        this.item.data.attributes.isVideo = imageUrl && extVideo.includes(imageUrl.substr(imageUrl.lastIndexOf(".") + 1));
      },
      error => {
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm(data = { name: '', user_id: '' }) {
    this.form = this.fb.group({
      name: [data.name || null, Validators.compose([Validators.required, Validators.minLength(3)])],
      user_id: [data.user_id || null, Validators.compose([Validators.required])],
      image: this.isItemEdited ? [] : [null, Validators.compose([Validators.required])],
    });

    if (this.item) {
      this.form.patchValue(this.item);
    }
  }

  public populateDataForNewItem() {
    this.item = <Banner>{};
    this.item["id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.bannerAdapter.deleteById(this.id).subscribe(res => {
          this.snackbarService.showSuccess('Deleted successfully');
          this.router.navigate(["banners"]);
        }, error => {
          this.snackbarService.showError('Error');
        });
      }
    });
  }

  onSubmit() {
    const banner = Banner.fromData(this.form.value);
    const { path } = this.activatedRoute.routeConfig;
    let type = ''
    if (this.activatedRoute.snapshot.params["id"]) {
      type = path === 'banners/:id/edit' || path === 'banners/web/:id/edit' ? 'web' : 'mobile';
    } else {
      type = path === 'banners/new' || path === 'banners/web/new' ? 'web' : 'mobile';
    }
    banner.id = this.id;
    banner.image = this.image;
    banner.type = type;
    if (this.isItemEdited) {
      this.bannerAdapter.updateById(banner).subscribe(res => {
        this.snackbarService.showSuccess('Updated successfully');
        this.router.navigate([`banners/${type}`]);
      }, (errors) => {
        errors.errors[0].map(item => {
          this.snackbarService.showError(`Error: ${item.detail}`);
        })
      });
    } else {
      this.bannerAdapter.create(banner).subscribe(res => {
        this.snackbarService.showSuccess('Created successfully');
        this.router.navigate([`banners/${type}`]);
      }, error => {
        this.snackbarService.showError('Error');
      });
    }
  }

  onCancel() {
    this.location.back();
  }
}
