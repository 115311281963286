import { Injectable, NgModule } from '@angular/core';
import { Observable } from "rxjs";
import { Product } from "../../models/product";
import { ProductAddV1Action } from "./product-add-v1.action";
import { ProductRemoveV1Action } from "./product-remove-v1.action";
import { ProductReadV1Action } from "./product-read-v1.action";
import { ProductBrowseV1Action } from "./product-browse-v1.action";
import { ProductEditV1Action } from "./product-edit-v1.action";

/* V2 */
import { ProductBrowseV2Action } from "./v2/product-browse-v2.action";
import { ProductRemoveV2Action } from "./v2/product-remove-v2.action";
import { ProductReadV2Action } from "./v2/product-read-v2.action";
import { ProductEditV2Action } from "./v2/product-edit-v2.action";
import { ProductAddV2Action } from "./v2/product-add-v2.action";
import { ProductImportV2Action } from "./v2/product-import-v2.action";


@Injectable()
export class ProductAdapter {
  constructor(
    private add: ProductAddV2Action,
    private remove: ProductRemoveV2Action,
    private read: ProductReadV2Action,
    private browse: ProductBrowseV2Action,
    private edit: ProductEditV2Action,
    private importProduct: ProductImportV2Action,
  ) {}

  // Simulate GET /products
  getAll(pageNo: any, filter: any, type?: any) {
    type = type ? type : 'Menu';
    return this.browse.exec({ pageNo, filter , type});
  }

  // Simulate GET /products/:id
  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }

  // Simulate PATCH /products
  updateById(product: Product, id: any): Observable<any> {
    return this.edit.exec({product, id});
  }

  // Simulate POST /products
  create(product: Product): Observable<any> {
    return this.add.exec(product);
  }

  // Simulate DELETE /products/:id
  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }

  // Simulate POST /products/import
  importDataProduct(data: any, type: any): Observable<any> {
    return this.importProduct.exec(data, type);
  }

}
