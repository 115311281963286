import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Banner } from "../../../models/banner";
import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';

@Injectable()
export class BannerEditV2Action extends ActionEntityV2Abstract {
  exec(banner: Banner): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}/banner/${banner.id}`,
      banner.toFormData()
    );
  }
}
