import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { BannerDigitalAdapter, PromotionAdapter, BaseService } from "../../services";
import { Banner, Promotion, Review } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { AppContext } from "../../services/appcontext";
import { Location } from '@angular/common';

@Component({
  selector: "app-ms-banner-info",
  templateUrl: "./digital-info.component.html",
  styleUrls: ["./digital-info.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class DigitalShareInfoComponent implements OnInit {
  private id?: number = null;
  public item: Banner;
  public promotions: Promotion[];
  public reviews: Review[];
  public applicationTypes = [];
  basePath = "digitals_share";
  public _IconImage: any;
  loading: boolean;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private bannerAdapter: BannerDigitalAdapter,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private restoreService: RestoreService<Banner>,
    private appContext: AppContext,
    private location: Location
  ) { }

  ngOnInit() {
    const pageTitle = "Banner Info";
    this.id = this.activatedRoute.snapshot.params["id"]
    this.getItem();
    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
    this.promotions = [];
  }

  public getItem() {
    this.loading = true;
    this.bannerAdapter.getById(this.id).subscribe(
      item => {
        const { data: { attributes: parsedData } } = item;
        this.item = parsedData;
        this.loading = false;
      }
    );
  }

  back() {
    this.location.back();
  }
}
