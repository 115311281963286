import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { User } from "../../models/user";
import { UserImage } from "../../models/user-image";
import { UserAddExternalV1Action } from "./user-add-external-v1.action";
import { UserAddInternalV1Action } from "./user-add-internal-v1.action";
import { UserBrowseExternalV1Action } from "./user-browse-external-v1.action";
import { UserBrowseInternalV1Action } from "./user-browse-internal-v1.action";
import { UserEditImageV1Action } from "./user-edit-image-v1.action";
import { UserEditV2Action } from "./v2/user-edit-v2.action";
import { UserReadV1Action } from "./user-read-v1.action";
import { UserRemoveV1Action } from "./user-remove-v1.action";
import { UserSearchV1Action } from "./user-search-v1.action";

/* V2 */
import { UserBrowseExternalV2Action } from "./v2/user-browse-external-v2.action";
import { UserBrowseInternalV2Action } from "./v2/user-browse-internal-v2.action";
import { UserReadV2Action } from "./v2/user-read-v2.action";
import { UserSearchV2Action } from "./v2/user-search-v2.action";
import { UserBrowseV2Action } from "./v2/user-browse-v2.action";

@Injectable()
export class UserAdapter {
  constructor(
    private addExternal: UserAddExternalV1Action,
    private addInternal: UserAddInternalV1Action,
    private browseExternal: UserBrowseExternalV2Action,
    private browseInternal: UserBrowseInternalV2Action,
    private editImage: UserEditImageV1Action,
    private edit: UserEditV2Action,
    private read: UserReadV2Action,
    private browse: UserBrowseV2Action,
    private remove: UserRemoveV1Action,
    private search: UserSearchV2Action,
  ) {}

  update(user: User): Observable<any> {
    return this.edit.exec(user);
  }

  update_password(user: any): Observable<any> {
    return this.edit.exec(user);
  }

  registerInternalUser(
    email: string,
    name: string,
    password: string,
    confirmPassword: string
  ): Observable<any> {
    return this.addInternal.exec({email, name, password, confirmPassword});
  }

  registerExternallUser(
    email: string,
    name: string,
    password: string,
    confirmPassword: string
  ): Observable<any> {
    return this.addExternal.exec({email, name, password, confirmPassword});
  }

  getAll(pageNo: any, pageSize: number, filter: any): Observable<any> {
    return this.browse.exec({ pageNo, pageSize, filter });
  }

  updateImage(userImage: UserImage): Observable<any> {
    return this.editImage.exec(userImage);
  }

  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }

  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }

  getInternalUsers(): Observable<any> {
    return this.browseInternal.exec(null);
  }

  getExternalUsers(pageNo: any, pageSize: number, filter: any): Observable<any> {
    return this.browseExternal.exec({ pageNo, pageSize, filter });
  }

  GetAllUsersByFilter(key): Observable<any> {
    return this.search.exec({key});
  }
}
