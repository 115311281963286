import { User } from './../../../models/user';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class UserEditV2Action extends ActionEntityV2Abstract {

  //  GET /users/:id
  exec(options: User): Observable<any> {
    return this.http.patch(`${this.apiUrl}/users/${options.id}`, options.toFormData());
  }
}
