// Action
export * from "./action/action.adapter";
export * from "./action/action-read-v1.action";
export * from "./action/action-add-or-update-v1.action";
export * from "./action/action-read-controller-v1.action";
export * from "./action/action-remove-controller-v1.action";
export * from "./action/action-remove-v1.action";

// Client
export * from "./client/client.adapter";
export * from "./client/client-read-v1.action";
export * from "./client/client-browse-v1.action";
export * from "./client/client-add-or-update-v1.action";
export * from "./client/client-remove-v1.action";
// Constant
export * from "./constant/constant.adapter";
export * from "./constant/constant-read-v1.action";
// Controller
export * from "./controller/controller.apdater";
export * from "./controller/controller-add-or-update-v1.action";
export * from "./controller/controller-browse-v1.action";
export * from "./controller/controller-read-v1.action";
export * from "./controller/controller-remove-v1.action";
export * from "./restore.service";
// Role
export * from "./role/role.adapter";
export * from "./role/role-read-v1.action";
export * from "./role/role-remove-v1.action";
export * from "./role/role-add-or-update-v1.action";
export * from "./role/role-browse-v1.action";
export * from "./role/role-browse-other-v1.action";
// Role Permission
export * from "./role-permission/role-permission.adapter";
export * from "./role-permission/role-permission-action-v1.action";
export * from "./role-permission/role-permission-assign.action";
export * from "./role-permission/role-permission-controller-v1.action";
// User Role
export * from "./user-role/user-role.adapter";
export * from "./user-role/user-role-read-v1.action";
export * from "./user-role/user-role-assign-v1.action";
export * from "./alert.service";
export * from "./appcontext";
// product
export * from "./product/product.adapter";
export * from "./product/product-add-v1.action";
export * from "./product/product-remove-v1.action";
export * from "./product/product-edit-v1.action";
export * from "./product/product-browse-v1.action";
export * from "./product/product-read-v1.action";
// service
export * from "./service/service.adapter";
export * from "./service/service-read-v1.action";
export * from "./service/service-browse-v1.action";
export * from "./service/service-add-v1.action";
export * from "./service/service-edit-v1.action";
export * from "./service/service-remove-v1.action";
// promotion
export * from "./promotion/promotion.adapter";
export * from "./promotion/promotion-browse-v1.action";
// User
export * from "./user/user.adapter";
export * from "./user/user-add-external-v1.action";
export * from "./user/user-add-internal-v1.action";
export * from "./user/user-browse-external-v1.action";
export * from "./user/user-browse-internal-v1.action";
export * from "./user/user-edit-image-v1.action";
export * from "./user/user-edit-v1.action";
export * from "./user/user-read-v1.action";
export * from "./user/user-remove-v1.action";
export * from "./user/user-search-v1.action";
// order
export * from "./order/order.adapter";
export * from "./order/order-read-v1.action";
export * from "./order/order-refund-v1.action";
export * from "./order/order-browse-v1.action";

// booking
export * from "./booking/booking.adapter";
export * from "./booking/v2/booking-browse-v2.action";

// category
export * from "./category/category.adapter";
export * from "./category/category-add-v1.action";
export * from "./category/category-browse-product-v1.action";
export * from "./category/category-browse-service-v1.action";
export * from "./category/category-read-v1.action";
export * from "./category/category-remove-v1.action";
export * from "./category/category-edit-v1.action";
export * from "./base.service";
// reviews
export * from "./reviews/review.adapter";

//notis
export * from "./notis/notis.adapter";
export * from "./notis/notis-browse-v1.action";

/* V2 */
// Banner
export * from "./banner/banner.adapter";
export * from "./banner/v2/banner-add-v2.action";
export * from "./banner/v2/banner-remove-v2.action";
export * from "./banner/v2/banner-edit-v2.action";
export * from "./banner/v2/banner-browse-v2.action";
export * from "./banner/v2/banner-read-v2.action";
export * from "./banner/v2/banner-active-v2.action";

// Banner Digital
export * from "./banner-digital/banner.adapter";
export * from "./banner-digital/v2/banner-add-v2.action";
export * from "./banner-digital/v2/banner-remove-v2.action";
export * from "./banner-digital/v2/banner-edit-v2.action";
export * from "./banner-digital/v2/banner-browse-v2.action";
export * from "./banner-digital/v2/banner-read-v2.action";
//Digital screen activity
export * from "./digital-screen-activity/screen-activity.adapter";
export * from "./digital-screen-activity/v1/screen-activity-browse-v1.action";
//Digital screen
export * from "./digital-screen/screen.adapter";
export * from "./digital-screen/v1/screen-add-v1.action";
export * from "./digital-screen/v1/screen-browse-v1.action";
export * from "./digital-screen/v1/screen-edit-v1.action";
export * from "./digital-screen/v1/screen-remove-v1.action";
export * from "./digital-screen/v1/screen-read-v1.action";

export * from "./digital-share/digital.adapter";
export * from "./digital-share/v2/digital-remove-v2.action";
export * from "./digital-share/v2/digital-edit-v2.action";
export * from "./digital-share/v2/digital-browse-v2.action";
export * from "./digital-share/v2/digital-read-v2.action";

// Permission
export * from "./permission/permission.adapter";
export * from "./permission/v2/permission-add-v2.action";
export * from "./permission/v2/permission-remove-v2.action";
export * from "./permission/v2/permission-edit-v2.action";
export * from "./permission/v2/permission-browse-v2.action";
export * from "./permission/v2/permission-read-v2.action";

// User
export * from "./user/v2/user-browse-external-v2.action";
export * from "./user/v2/user-browse-internal-v2.action";
export * from "./user/v2/user-read-v2.action";
export * from "./user/v2/user-edit-v2.action";
export * from "./user/v2/user-browse-v2.action";
export * from "./user/v2/user-search-v2.action";
// Product
export * from "./product/v2/product-browse-v2.action";
export * from "./product/v2/product-read-v2.action";
export * from "./product/v2/product-remove-v2.action";
export * from "./product/v2/product-edit-v2.action";
export * from "./product/v2/product-add-v2.action";
export * from "./product/v2/product-import-v2.action";
// Service
export * from "./service/v2/service-browse-v2.action";
export * from "./service/v2/service-read-v2.action";
export * from "./service/v2/service-edit-v2.action";
export * from "./service/v2/service-remove-v2.action";
export * from "./service/v2/service-add-v2.action";
// Promotion
export * from "./promotion/v2/promotion-browse-v2.action";
// Category
export * from "./category/v2/category-browse-product-v2.action";
export * from "./category/v2/category-browse-menu-v2.action";
export * from "./category/v2/category-browse-service-v2.action";
export * from "./category/v2/category-read-v2.action";
export * from "./category/v2/category-edit-v2.action";
export * from "./category/v2/category-add-v2.action";
export * from "./category/v2/category-remove-v2.action";
// Order
export * from "./order/v2/order-browse-v2.action";
export * from "./order/v2/order-read-v2.action";
export * from "./order/v2/order-refund-v2.action";
// Review
export * from "./reviews/review-browse-v2.action";
export * from "./reviews/review-remove-v2.action";
export * from "./reviews/review-read-v2.action";
export * from "./reviews/review-edit-v2.action";
export * from "./reviews/review-product-browse-v2.action";
export * from "./reviews/review-service-browse-v2.action";

// Role
export * from "./role/role.adapter";
export * from "./role/v2/role-add-v2.action";
export * from "./role/v2/role-remove-v2.action";
export * from "./role/v2/role-edit-v2.action";
export * from "./role/v2/role-browse-v2.action";
export * from "./role/v2/role-read-v2.action";

// Delivery
export * from "./delivery/delivery.adapter";
export * from "./delivery/v2/delivery-add-v1.action";
export * from "./delivery/v2/delivery-browse-v2.action";
export * from "./delivery/v2/delivery-edit-v1.action";
export * from "./delivery/v2/delivery-remove-v1.action";
export * from "./delivery/v2/delivery-read-v1.action";

// Subcribers
export * from "./subcribers/subcribers.adapter";
export * from "./subcribers/v2/subcribers-browse-v2.action";
