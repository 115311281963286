import { HTTP_INTERCEPTORS, HttpHandler } from '@angular/common/http';

import { PostInterceptor } from './PostInterceptor';
import { JwtInterceptor } from './JwtInterceptor';
import { AuthResponseHandler } from './AuthResponseHandler';
import { ErrorHandler } from './ErrorHandler';
import { AppLoaderInterceptor } from 'app/interceptors/app-loader.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PostInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthResponseHandler, multi: true },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AppLoaderInterceptor,
        multi: true,
    }
];
