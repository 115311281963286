import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class UserSearchV2Action extends ActionEntityV2Abstract {

  //  GET GetAllUsersByFilter
  exec(options: any): Observable<any> {
    // return this.http.get(`${this.apiUrl}/User/GetAllUsersByFilter?Search=${options.key}`);
    return this.http.get(`${this.apiUrl}/users?name=*${options.key}*`);
  }
}
