import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Banner } from "../../models/banner";
import { BannerBrowseV2Action } from "./v2/banner-browse-v2.action";
import { BannerEditV2Action } from "./v2/banner-edit-v2.action";
import { BannerAddV2Action } from './v2/banner-add-v2.action';
import { BannerRemoveV2Action } from './v2/banner-remove-v2.action';
import { BannerReadV2Action } from './v2/banner-read-v2.action';
import { BannerActiveV2Action } from './v2/banner-active-v2.action';

@Injectable()
export class BannerAdapter {
  constructor(
    private add: BannerAddV2Action,
    private remove: BannerRemoveV2Action,
    private read: BannerReadV2Action,
    private browse: BannerBrowseV2Action,
    private edit: BannerEditV2Action,
    private active: BannerActiveV2Action,
  ) { }

  getAll(pageNo: any, pageSize: number, filter: any, type: any) {
    return this.browse.exec({ pageNo, pageSize, filter, type });
  }

  getById(id: number): Observable<any> {
    return this.read.exec({ id });
  }

  updateById(banner: Banner): Observable<any> {
    return this.edit.exec(banner);
  }

  create(banner: Banner): Observable<any> {
    return this.add.exec(banner);
  }

  deleteById(id: number): Observable<any> {
    return this.remove.exec({ id });
  }

  activeById(id: number): Observable<any> {
    return this.active.exec(id);
  }
}
