import { Injectable} from '@angular/core';
import { Observable } from "rxjs";
import { BannerDigital } from "../../models/banner-digital";
import { BannerDigitalBrowseV2Action } from "./v2/banner-browse-v2.action";
import { BannerDigitalEditV2Action } from "./v2/banner-edit-v2.action";
import { BannerDigitalAddV2Action } from './v2/banner-add-v2.action';
import { BannerDigitalRemoveV2Action } from './v2/banner-remove-v2.action';
import { BannerDigitalReadV2Action } from './v2/banner-read-v2.action';

@Injectable()
export class BannerDigitalAdapter {
  constructor(
    private add: BannerDigitalAddV2Action,
    private remove: BannerDigitalRemoveV2Action,
    private read: BannerDigitalReadV2Action,
    private browse: BannerDigitalBrowseV2Action,
    private edit: BannerDigitalEditV2Action,
  ) {}

   getAll(pageNo: any, pageSize: number, filter: any) {
    return this.browse.exec({pageNo, pageSize, filter});
  }

  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }

  updateById(banner: BannerDigital): Observable<any> {
    return this.edit.exec(banner);
  }

  create(banner: BannerDigital): Observable<any> {
    return this.add.exec(banner);
  }

  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }
}
