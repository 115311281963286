import { SnackbarService } from './../../core/snackbar/snackbar.service';
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormGroup,
} from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { UserAdapter, ProductAdapter, PromotionAdapter, BaseService, ServiceAdapter } from "../../services";
import { User, Promotion } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { ParseService } from "../../services/parse.service";
import { AppContext } from "../../services/appcontext";
import { MatDialog, PageEvent, MatTabChangeEvent } from "@angular/material";

@Component({
  selector: "app-ms-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class UserInfoComponent implements OnInit {
  private id?: number = null;
  public item: User;
  public products: any;
  public services: any;
  public promotions: Promotion[];
  public applicationTypes = [];
  basePath = "users";
  public _IconImage: any;
  // pagination
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private userAdapter: UserAdapter,
    private productAdapter: ProductAdapter,
    private serviceAdapter: ServiceAdapter,
    private promotionAdapter: PromotionAdapter,
    private activatedRoute: ActivatedRoute,
    private restoreService: RestoreService<any>,
    private parseService: ParseService,
    private appContext: AppContext,
    private snackbarService: SnackbarService,

  ) {}

  ngOnInit() {
    const pageTitle = "User Info";
    this.id = this.activatedRoute.snapshot.params["id"];
    this.getItem();
    this.getAllPromotions('Services');
    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
    this.promotions = [];
  }

  public getItem() {
    this.userAdapter.getById(this.id).subscribe(
      item => {
        this.restoreService.set(this.parseService.single(item));
        this.item = this.restoreService.get();
        console.log(this.item);
      },
      err => {
        if (err.error.errors.length) {
          this.snackbarService.showError(err.error.errors[0].detail);
        }
      }
    );

  }

  // Get all products
  getProducts(event?: PageEvent) {
    let pageNo = 1;
    if (!this.baseService.isNil(event)) {
      pageNo = event.pageIndex + 1;
    }
    this.productAdapter.getAll(pageNo, 'user_id=' + this.id).subscribe(
      item => {
        if (!this.baseService.isNil(item)) {
        this.restoreService.set(this.parseService.multi(item));
        this.products = this.restoreService.get();
        this.pageIndex = 1;
        this.pageSize = 10;
        this.length = item.meta.totalItems;
        }
      }
    );
  }

  public getServices(event ?: PageEvent) {
    let pageNo = 1;
    if (!this.baseService.isNil(event)) {
      pageNo = event.pageIndex + 1;
    }
    this.serviceAdapter.getAll(pageNo, 'user_id=' + this.id).subscribe(
      item => {
        console.log(this.parseService.multi(item));
        this.restoreService.set(this.parseService.multi(item));
        this.services = this.restoreService.get();
        this.pageIndex = 1;
        this.pageSize = 10;
        this.length = item.meta.totalItems;
      })

  }
   // edit product
  edit(node) {
    this.router.navigate([this.basePath, node.id, "edit"]);
  }

  public getAllPromotions($type= "") {
    this.promotionAdapter.getAllPromotionByUserId(this.id, $type).subscribe(
      item => {
        if (!this.baseService.isNil(item)) {
          this.promotions = this.parseService.multi(item);
        }
      }
    );
  }
  public onTabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.tab.textLabel === "Products") {
      this.getProducts();
    }
    if (tabChangeEvent.tab.textLabel === "Promotions") {
      this.getAllPromotions('services');
    }
    if (tabChangeEvent.tab.textLabel === "Services") {
      this.getServices();
    }
  }

  public onSwitchPromotions(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.tab.textLabel === "Service Promotions") {
      this.getAllPromotions('Services');
    }
    if (tabChangeEvent.tab.textLabel === "Product Promotions") {
      this.getAllPromotions('Products');
    }
  }
}
