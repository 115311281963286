import { Permission } from './../../models/permission';
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { PermissionAdapter, BaseService } from "../../services";
import { RestoreService } from "../../services/restore.service";
import { AppContext } from "../../services/appcontext";
import { Location } from '@angular/common';

@Component({
  selector: 'app-permission-info',
  templateUrl: './permission-info.component.html',
  styleUrls: ['./permission-info.component.scss']
})
export class PermissionInfoComponent implements OnInit {

  private id?: number = null;
  public item: Permission;
  public applicationTypes = [];
  basePath = "permissions";
  public _IconImage: any;
  loading: boolean;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private permissionsAdapter: PermissionAdapter,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private restoreService: RestoreService<Permission>,
    private appContext: AppContext,
    private location: Location
  ) { }

  ngOnInit() {
    const pageTitle = "Permission Info";
    this.id = this.activatedRoute.snapshot.params["id"]
    this.getItem();
    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
  }

  public getItem() {
    this.loading = true;
    this.permissionsAdapter.getById(this.id).subscribe(
      item => {
        const { data: { attributes: parsedData } } = item;
        this.item = parsedData;
        this.loading = false;
      }
    );
  }

  back() {
    this.location.back();
  }
}
