import { Injectable} from '@angular/core';
import { Observable } from "rxjs";
import { DigitalShare } from "../../models/digital-share";
import { DigitalShareBrowseV2Action } from "./v2/digital-browse-v2.action";
import { DigitalShareEditV2Action } from "./v2/digital-edit-v2.action";
import { DigitalShareRemoveV2Action } from './v2/digital-remove-v2.action';
import { DigitalShareReadV2Action } from './v2/digital-read-v2.action';

@Injectable()
export class DigitalShareAdapter {
  constructor(
    private remove: DigitalShareRemoveV2Action,
    private read: DigitalShareReadV2Action,
    private browse: DigitalShareBrowseV2Action,
    private edit: DigitalShareEditV2Action,
  ) {}

   getAll(pageNo: any, pageSize: number, filter: any) {
    return this.browse.exec({pageNo, pageSize, filter});
  }

  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }

  updateById(digital: DigitalShare): Observable<any> {
    return this.edit.exec(digital);
  }

  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }
}
