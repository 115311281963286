import { BaseObject } from "./base-object";

export class Service extends BaseObject {
  public static readonly defaultIconImage = "/media/category_images/default-category.png";
  static fromData(data: any) {
  const {
    id,
    name,
    price,
    currency_unit,
    description,
    primary_image,
    sub_image_1,
    sub_image_2,
    sub_image_3,
    sub_image_4,
    sub_image_5,
    promotion_price,
    category_id,
    user_id,
    duration,
    trading_hours,
  } = data;
    return new this(
      id,
      name,
      price,
      currency_unit,
      description,
      primary_image,
      sub_image_1,
      sub_image_2,
      sub_image_3,
      sub_image_4,
      sub_image_5,
      promotion_price,
      category_id,
      user_id,
      duration,
      trading_hours,
    );
  }

  constructor(
    public id: number = null,
    public name: string,
    public price: number,
    public currency_unit?: string,
    public description?: string,
    public primary_image?: any,
    public sub_image_1?: any,
    public sub_image_2?: any,
    public sub_image_3?: any,
    public sub_image_4?: any,
    public sub_image_5?: any,
    public promotion_price?: number,
    public category_id?: any,
    public user_id?: any,
    public duration?: any,
    public trading_hours?: any,
  ) { super(); }
}
