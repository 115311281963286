import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class CategoryEditV1Action extends ActionEntityV1Abstract {

  exec(options: any): Observable<any> {
    return this.http.post(
      `${this.apiBaseUrl}/Category/UpdateCategory`,
      options.toFormData(),
    );
  }

}
