import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class ActionAddOrUpdateV1Action extends ActionEntityV1Abstract {

  // Simulate POST /clients
  exec(options: any): Observable<any> {
    return this.http.post(`${this.apiOauthUrl}/api/manage/ActionInfo/AddOrUpdate`,
    options.action.toFormUrlEncoded());
  }
}
