import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {

  numberOfRequest: number = 0;
  showLoading: Subject<boolean> = new Subject();

  constructor() {
  }

  showAppLoading() {
    this.showLoading.next(true);
  }

  hideAppLoading() {
    this.showLoading.next(false);
  }
}
