import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConstantReadV1Action } from "./constant-read-v1.action";

@Injectable()
export class ConstantAdapter {

  constructor(private read: ConstantReadV1Action) {}

  // Simulate GET /constants
  // type can be: 'ApplicationTypes' | 'UserTypes'
  getContants(type: string = "ApplicationTypes"): Observable<any> {
    return this.read.exec({type});
  }
}
