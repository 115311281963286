import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ProductAdapter, UserAdapter, CategoryAdapter, BaseService} from "../../services";
import { Product } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { MatDialog } from "@angular/material";

import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { AppContext } from "../../services/appcontext";
import { ParseService } from "../../services/parse.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: "app-ms-product-item",
  templateUrl: "./product-item.component.html",
  styleUrls: ["./product-item.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class ProductItemComponent implements OnInit {
  public Editor = ClassicEditor;
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public isLoading: boolean;
  public errorMessage: string;
  public productDetail = {
    category: {
      id: ''
    },
    user: {
      id: ''
    }
  };
  public item: Product;
  public productCategories = [];
  public externalUsers = [];
  public priceUnits = ['AUD', 'VND'];

  //
  public applicationTypes = [];
  basePath = "products";
  public _PrimaryImage: any;
  public _subImage1: any;
  public _subImage2: any;
  public _subImage3: any;
  public _subImage4: any;
  public _subImage5: any;
  isBrowsewrIE = false;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productAdapter: ProductAdapter,
    private categoryAdapter: CategoryAdapter,
    private userAdapter: UserAdapter,

    private restoreService: RestoreService<Product>,
    private dialog: MatDialog,
    private appContext: AppContext,
    private parseService: ParseService
  ) {}

  ngOnInit() {
    let pageTitle = "Create new Product";

    this.setItemId();
    if (this.id === null || this.id === undefined || isNaN(this.id)) {
      this.isItemEdited = false;
      this.populateDataForNewItem();
    } else {
      this.isItemEdited = true;
      pageTitle = "Edit Product";
      this.getItem();
    }

    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
    this.getProductCategories();
    this.getExternalUser();
    this.isBrowsewrIE = /msie\s|trident/i.test(window.navigator.userAgent)
  }

  public getItem() {
    this.isLoading = true;

    this.productAdapter.getById(this.id).subscribe(
      item => {
        this.isLoading = false;
        this.productDetail = this.parseService.single(item);
        this.restoreService.set(this.parseService.single(item));
        this.item = this.parseService.single(item)
        this.buildForm();
      },
      error => {
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(65)])],
      price: [null, Validators.compose([Validators.required])],
      currency_unit: [null, Validators.compose([Validators.required])],
      description: [null],
      meta_desc: [null],
      meta_keywords: [null],
      primary_image: [null],
      sub_image_1: [null],
      sub_image_2: [null],
      sub_image_3: [null],
      sub_image_4: [null],
      sub_image_5: [null],
      promotion_price: [null],
      category_id: [null, Validators.compose([Validators.required])],
      user_id: [null]
    });
    if (this.item) {
      this.baseService.loadIconImage(this.item.primary_image);

      this.item.category_id = this.productDetail.category.id;
      this.item.user_id = this.productDetail.user.id;
      this.form.patchValue(this.item);
    }
  }

  readIconImage(ImageId, inputId) {
    const fUpload: any = document.getElementById(inputId);
    if (!fUpload || !fUpload.files || fUpload.files.length === 0) { return; }

    const f: any = fUpload.files[0];
    const img: any = document.getElementById(ImageId);
    const reader = new FileReader();
    reader.onloadend = (e: any) => {
      const data = e.target.result;
      img.src = data;
      if (ImageId === 'primaryImage') {
        this._PrimaryImage = f;
      }
      if (ImageId === 'subImage1') {
        this._subImage1 = f;
      }
      if (ImageId === 'subImage2') {
        this._subImage2 = f;
      }
      if (ImageId === 'subImage3') {
        this._subImage3 = f;
      }
      if (ImageId === 'subImage4') {
        this._subImage4 = f;
      }
      if (ImageId === 'subImage5') {
        this._subImage5 = f;
      }

    }
    reader.readAsDataURL(f);
  }

  public populateDataForNewItem() {
    this.item = <Product>{};
    this.item["id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.productAdapter.deleteById(this.id).subscribe(res => {
          this.router.navigate(["products"]);
        });
      }
    });
  }

  onSubmit() {
    const product = Product.fromData(this.form.value);

    product.id = this.id;
    product.type = 'Product';

    if (product.primary_image && product.primary_image.url) {
      delete product.primary_image
    }
    if (product.sub_image_1 && product.sub_image_1.url) {
      delete product.sub_image_1
    }
    if (product.sub_image_2 && product.sub_image_2.url) {
      delete product.sub_image_2
    }
    if (product.sub_image_3 && product.sub_image_3.url) {
      delete product.sub_image_3
    }
    if (product.sub_image_4 && product.sub_image_4.url) {
      delete product.sub_image_4
    }
    if (product.sub_image_5 && product.sub_image_5.url) {
      delete product.sub_image_5
    }
    if (this._PrimaryImage !== undefined && this._PrimaryImage !== null) {
      product.primary_image = this._PrimaryImage;
    }
    if (this._subImage1 !== undefined && this._subImage1 !== null) {
      product.sub_image_1 = this._subImage1;
    }
    if (this._subImage2 !== undefined && this._subImage2 !== null) {
      product.sub_image_2 = this._subImage2;
    }
    if (this._subImage3 !== undefined && this._subImage3 !== null) {
      product.sub_image_3 = this._subImage3;
    }
    if (this._subImage4 !== undefined && this._subImage4 !== null) {
      product.sub_image_4 = this._subImage4;
    }
    if (this._subImage5 !== undefined && this._subImage5 !== null) {
      product.sub_image_5 = this._subImage5;
    }

    if (this.isItemEdited) {
      this.productAdapter.updateById(product, this.id).subscribe(res => {
        this.router.navigate(["products"]);
      });
    } else {
      this.productAdapter.create(product).subscribe(res => {
        this.router.navigate(["products"]);
      });
    }
  }

  public getExternalUser() {
    this.userAdapter.getExternalUsers(1, 1000 , 1).subscribe(
      data => {
        const user = this.parseService.multi(data);
        this.externalUsers = user;
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  public getProductCategories() {
    this.categoryAdapter.getAllProductCategories().subscribe(
      data => {
        const category = this.parseService.multi(data);
        if (category !== null && category.length > 0) {
          if (this.id !== null && this.id !== undefined) {
            data = (<any[]>category).filter(p => p.id !== this.id);
          }
          this.getCategoryComboSourceDeep(category);
        }
      },
      error => {
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );
  }

  public getCategoryComboSourceDeep(rows) {
    rows.forEach((row, index) => {
      this.productCategories.push({id: row.id, name: row.name});
      // if (row.Children !== null && row.Children.length > 0) {
      //   this.getCategoryComboSourceDeep(row.Children);
      // }
    });
  }
}
