import { BusyIndicatorService } from './../../core/busyIndicator/busyIndicator.service';
import { ParseService } from './../../services/parse.service';
import { PermissionAdapter } from './../../services/permission/permission.adapter';
import { Permission } from './../../models/permission';
import { SnackbarService } from 'app/core/snackbar/snackbar.service';
import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";

import { Action, Role } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { UserAdapter, RoleAdapter, UserRoleAdapter, BaseService } from "../../services";
import { Subscription } from 'rxjs';

const password = new FormControl("", Validators.required);
const confirmPassword = new FormControl("", CustomValidators.equalTo(password));

@Component({
  selector: "app-ms-role-item",
  templateUrl: "./role-item.component.html",
  styleUrls: ["./role-item.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class RoleItemComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public isLoading: boolean;
  public loadingIndicator = true;
  public users: any[] = [];
  public selectedUserId: number;
  public errorMessage: string;
  public item: Role;
  public pageTitle = "Create New Role";
  isShowAssignPermissionForm = false;
  pageSize: any;

  public permissions = [];
  availablePermissions = [];

  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private roleAdapter: RoleAdapter,
    private restoreService: RestoreService<Role>,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private location: Location,
    private permissionAdapter: PermissionAdapter,
    private parseService: ParseService,
    protected busyIndicatorService: BusyIndicatorService
  ) {
    this.pageSize = this.baseService.PAGE_SIZE;
  }

  ngOnInit() {
    let pageTitle = "Create New Role";

    this.setItemId();
    if (this.activatedRoute.snapshot.routeConfig.path.indexOf('edit') === -1) {
      this.isItemEdited = false;
      this.populateDataForNewItem();
    } else {
      this.isItemEdited = true;
      pageTitle = "Edit Role";
      this.getItem();
    }
    this.pageTitleService.setTitle(pageTitle);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  getItem() {
    const subscription = this.roleAdapter.getById(this.id).subscribe(
      item => {
        this.restoreService.set(item);
        this.item = this.restoreService.get();
        const { data: { attributes: parsedData } } = <any>this.item;
        this.permissions = parsedData.permissions;
        this.buildForm(parsedData);
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
    this.buildForm();
    this.subscriptions.push(subscription);
  }

  private buildForm(data = { name: '' }) {
    this.form = this.fb.group({
      name: [data.name || null, Validators.compose([Validators.required, Validators.minLength(3)])]
    });

    if (this.item) {
      this.form.patchValue(this.item);
    }
  }

  populateDataForNewItem() {
    this.item = <Role>{};
    this.item["id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        const subscription = this.roleAdapter.deleteById(this.id).subscribe(res => {
          this.snackbarService.showSuccess('Deleted successfully');
          this.router.navigate(["roles"]);
        }, error => {
          this.snackbarService.showError('Error');
        });
        this.subscriptions.push(subscription);
      }
    });
  }

  onSubmit() {
    const role = Role.fromData(this.form.value);
    role.id = this.id;
    role.guard_name = 'api';
    role.permissions = this.permissions.map(perm => +perm.id);
    let subscription;
    if (this.isItemEdited) {
      subscription = this.roleAdapter.updateById(role).subscribe(res => {
        this.snackbarService.showSuccess('Updated successfully');
        this.router.navigate(["roles"]);
      },err => {
        if (err.error.errors.length) {
          this.snackbarService.showError(err.error.errors[0].detail);
        }
      });
    } else {
      subscription = this.roleAdapter.create(role).subscribe(res => {
        this.snackbarService.showSuccess('Created successfully');
        this.router.navigate(["roles"]);
      }, err => {
        if (err.error.errors.length) {
          this.snackbarService.showError(err.error.errors[0].detail);
        }
      });
    }
    this.subscriptions.push(subscription);
  }

  onCancel() {
    this.location.back();
  }

  deletePermission({ id }) {
    this.permissions = this.permissions.filter(({ id: itemId }) => id !== itemId);
  }

  showAssignPermissionForm() {
    this.busyIndicatorService.show();
    const subscription = this.permissionAdapter.getAll(null, -1, '').subscribe(
      data => {
        this.isShowAssignPermissionForm = true;
        if (!data) {
          return;
        }
        this.availablePermissions = this.parseService.multi(data)
          .filter(item => !this.permissions.some(perm => +perm.id === +item.id));
      },
      error => {
        this.snackbarService.showError(`Error : ${error}`);
        this.busyIndicatorService.hide();
      },
      () => {
        this.busyIndicatorService.hide();
      }
    );
    this.subscriptions.push(subscription);
  }

  cancelAssignPermission() {
    this.isShowAssignPermissionForm = false;
  }

  updateAssignPermission() {
    this.permissions = [...this.permissions,
    ...this.availablePermissions.filter(perm => perm.isChecked)];
    this.isShowAssignPermissionForm = false;
  }

  filterPermissions(permissions, permissionName: string) {
    const val = permissionName.toLowerCase().trim();
    if (!val) {
      return permissions;
    }
    return permissions.filter(d => d.name.toLowerCase().indexOf(val) !== -1);
  }
}
