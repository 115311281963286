import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Product } from "../../../models/product";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class ProductAddV2Action extends ActionEntityV2Abstract {

  // Simulate PATCH /products/:id
  exec(product: Product): Observable<any> {
    return this.http.post(`${this.apiUrl}/products/`, product.toFormData());
  }

}
