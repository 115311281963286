import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Notis } from "../../models/notis";
import { NotisBrowseV1Action } from "./notis-browse-v1.action";

@Injectable()
export class NotisAdapter {
  constructor(
    private browse: NotisBrowseV1Action,
  ) { }

  getAll(pageNo: any, pageSize: number, filter: any, type: any) {
    return this.browse.exec({ pageNo, pageSize, filter, type });
  }

}
