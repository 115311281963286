import { BaseObject } from "./base-object";

export class DigitalShare extends BaseObject {
    static fromData(data: any) {
        const { id, host_id, renter_id, screen_id, status, admin_active_flg } = data;
        return new this(id, host_id, renter_id, screen_id, status, admin_active_flg);
    }

    constructor(
        public id: number = null,
        public host_id: any,
        public renter_id: any,
        public screen_id: any,
        public status: boolean,
        public admin_active_flg: boolean,
    ) { super(); }
}