import { Permission } from './../../models/permission';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { BannerAdapter, UserAdapter, CategoryAdapter, BaseService, PermissionAdapter } from "../../services";
import { RestoreService } from "../../services/restore.service";
import { MatDialog } from "@angular/material";

import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { AppContext } from "../../services/appcontext";
import { SnackbarService } from "app/core/snackbar/snackbar.service";

@Component({
  selector: 'app-permission-item',
  templateUrl: './permission-item.component.html',
  styleUrls: ['./permission-item.component.scss']
})
export class PermissionItemComponent implements OnInit {

  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public errorMessage: string;
  public item: Permission;
  private name: any;
  //
  public applicationTypes = [];
  basePath = "permissions";
  defaultImage = '/assets/img/default.png';

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private permissionAdapter: PermissionAdapter,
    private restoreService: RestoreService<Permission>,
    private dialog: MatDialog,
    private appContext: AppContext,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {
    let pageTitle = "Create New Permission";

    this.setItemId();
    if (this.id === null || this.id === undefined || isNaN(this.id)) {
      this.isItemEdited = false;
      this.populateDataForNewItem();
    } else {
      this.isItemEdited = true;
      pageTitle = "Edit Permission";
      this.getItem();
    }

    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
  }

  public getItem() {
    this.permissionAdapter.getById(this.id).subscribe(
      item => {
        this.restoreService.set(item);
        this.item = this.restoreService.get();

        const { data: { attributes: parsedData } } = <any>this.item;
        this.buildForm(parsedData);
      },
      error => {
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm(data = { name: '' }) {
    this.form = this.fb.group({
      name: [data.name || null, Validators.compose([Validators.required, Validators.minLength(3)])],

    });

    if (this.item) {
      this.form.patchValue(this.item);
    }
  }

  public populateDataForNewItem() {
    this.item = <Permission>{};
    this.item["id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.permissionAdapter.deleteById(this.id).subscribe(res => {
          this.snackbarService.showSuccess('Deleted successfully');
          this.router.navigate(["permissions"]);
        }, ({ error }) => {
          if (error.error) {
            return this.snackbarService.showError(error.error);
          }
          this.snackbarService.showError('Error');
        });
      }
    });
  }

  onSubmit() {
    const permission = Permission.fromData(this.form.value);
    permission.id = this.id;
    permission.guard_name = 'api';
    if (this.isItemEdited) {
      this.permissionAdapter.updateById(permission).subscribe(res => {
        this.snackbarService.showSuccess('Updated successfully');
        this.router.navigate(["permissions"]);
      }, err => {
        if (err.error.errors.length) {
          this.snackbarService.showError(err.error.errors[0].detail);
        }
      });
    } else {
      this.permissionAdapter.create(permission).subscribe(res => {
        this.snackbarService.showSuccess('Created successfully');
        this.router.navigate(["permissions"]);
      }, err => {
        if (err.error.errors.length) {
          this.snackbarService.showError(err.error.errors[0].detail);
        }
      });
    }
  }

  onCancel() {
    this.location.back();
  }

}
