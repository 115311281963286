import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Observable} from 'rxjs';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductAdapter, CategoryAdapter, UserAdapter, BaseService } from "../../services";
import { MatDialog, PageEvent } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { fadeInAnimation } from "../../core";
import {TreeNode} from 'primeng/api';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import { ParseService } from "../../services/parse.service";
import { SnackbarService } from 'app/core/snackbar/snackbar.service';

@Component({
  selector: 'app-ms-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [ fadeInAnimation ]
})
export class ProductListComponent implements OnInit {
  treeNodes_Product: TreeNode[];
  selectedNode: TreeNode;
  selectedTabIndex = 0;
  rows = [];
  isLoading: boolean;
  errorMessage: string;
  errorMessageImport: string;
  deleteId: number;

  // pagination
  public pageEvent: PageEvent;
  public pageIndex: number;
  public pageSize: number;
  public length: number;
  public pageNo: number;
  public filter: any;
  public selectedCategory: any;
  public selectedUser: any;
  public productCategories = [];
  public externalUsers: any;
  loadingIndicator = false;
  filteredCategories: Observable<string[]>;
  filteredUsers: Observable<string[]>;

  catControl = new FormControl();
  userControl = new FormControl();
  basePath = "products";
  fileToUpload: File = null;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private productAdapter: ProductAdapter,
    private userAdapter: UserAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private categoryAdapter: CategoryAdapter,
    private dialog: MatDialog,
    private parseService: ParseService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle("Products Management");
    this.setTabIndex();
    
    this.getProducts();
    this.getProductCategories();
    this.getAllUser('');
  }

  setTabIndex() {
    const group = this.activatedRoute.snapshot.params["group"];
    this.selectedTabIndex = group === "Product" || group === null || group === undefined ? 0 : 1;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFileToActivity() {
    this.productAdapter.importDataProduct(this.fileToUpload, 'Product').subscribe(data => {
        var json = data.errors;
        if(json != ""){
          this.errorMessageImport = <any>json;
        } else {
          location.reload();
        }
      // do something, if upload success
      }, error => {
        console.log(error);
        this.errorMessageImport = <any>error;
      });
  }

  public getAllUser(key: string) {
    this.userAdapter.GetAllUsersByFilter(key).subscribe(
      data => {
        this.externalUsers = this.parseService.multi(data);
        this.filteredUsers = this.userControl.valueChanges.pipe(
          startWith(''),
          map(user => user ? this._filter(user, 'user') : this.externalUsers.slice())
        );
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  // Get all products
  getProducts(event?: PageEvent) {
    this.pageNo = 1;
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageNo = event.pageIndex + 1;
      this.pageIndex = event.pageIndex;
    }
    this.filter = '';
    if (this.catControl.value !== "" && !this.baseService.isNil(this.selectedCategory)) {
      this.filter += 'category_id=' + this.selectedCategory.id;
    }
    if (this.userControl.value !== "" && !this.baseService.isNil(this.selectedUser)) {
      this.filter += '&user_id=' + this.selectedUser.id;
    }
    this.requestApi(this.pageNo, this.filter, this.pageIndex);
    return event;
  }

  public requestApi(pageNo, filter, pageIndex?) {
    this.productAdapter.getAll(pageNo, filter, 'Product').subscribe(
      data => {
        this.rows = this.parseService.multi(data);
        this.loadingIndicator = false;
        this.pageIndex = pageIndex;
        this.pageSize = 20;
        this.length = data.meta.totalItems;
      },
      error => {
        this.errorMessage = <any>error;
        this.loadingIndicator = false;
      }
    );
  }
  delete(id) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, { width: "300px" });
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.productAdapter.deleteById(id).subscribe(
          data => {
            this.requestApi(this.pageNo, this.filter);
            this.snackbarService.showSuccess('Successfully');
          },
          error => {
            this.errorMessage = <any>error;
            this.loadingIndicator = false;
          },
        );
      }
    });
  }

  public getProductCategories() {
    this.categoryAdapter.getAllProductCategories().subscribe(
      data => {
        const category = this.parseService.multi(data);
        if (category !== null && category.length > 0) {
          this.getCategoryComboSourceDeep(category);
        }

        this.filteredCategories = this.catControl.valueChanges.pipe(
          startWith(''),
          map(option => option ? this._filter(option, 'category') : this.productCategories.slice())
        );
      },
      error => {
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );
  }
  public getCategoryComboSourceDeep(rows) {
    rows.forEach((row, index) => {
      this.productCategories.push({id: row.id, name: row.name});
      // if (row.Children !== null && row.Children.length > 0) {
      //   this.getCategoryComboSourceDeep(row.Children);
      // }
    });
  }

  private _filter(value: any, type): any[] {
    let filterValue = ''
    if (type === 'category') {
      filterValue  = value.name ? value.name.toLowerCase() : value.toLowerCase();
      this.selectedCategory = value.name ? value : null;
      return this.productCategories.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    if (type === 'user') {
      filterValue  = value.fullname ? value.fullname.toLowerCase() : value.toLowerCase();
      this.getAllUser(filterValue);
      this.selectedUser = value.fullname ? value : null;
      return this.externalUsers.filter(option => option.fullname.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  public displayFn(object): Object {
    return object && ( object.name ? object.name : (object.fullname ? object.fullname : object));
  }
}
