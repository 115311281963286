import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { UserRoleReadV1Action } from "./user-role-read-v1.action";
import { UserRoleAssignV1Action } from "./user-role-assign-v1.action";

@Injectable()
export class UserRoleAdapter {

  constructor(
      private read: UserRoleReadV1Action,
      private assign: UserRoleAssignV1Action
    ) {}

  //  GET /userInRoles
  getUsersInRole(roleId: number): Observable<any> {
    return this.read.exec({roleId});
  }

  // POST /assignUserToRole
  assignUsersToRole(roleId: number, userIds: Array<number>): Observable<any> {
    return this.assign.exec({roleId, userIds});
  }
}
