import { BaseObject } from "./base-object";

export class Permission extends BaseObject {
  static fromData(data: any) {
    const { id, name, guard_name} = data;
    return new this(id, name, guard_name);
  }

  constructor(
    public id: number = null,
    public name: string,
    public guard_name: string,

  ) { super(); }
}
