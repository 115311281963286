import { BusyIndicatorService } from './../../core/busyIndicator/busyIndicator.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService, DeliveryAdapter } from '../../services';
import { MatDialog, PageEvent } from '@angular/material';
import { DeleteConfirmDialogComponent } from 'app/dialog/delete-confirm-dialog';
import { fadeInAnimation } from '../../core';
import { FormControl } from '@angular/forms';
import { ParseService } from '../../services/parse.service';
import { SnackbarService } from 'app/core/snackbar/snackbar.service';

@Component({
  selector: 'app-ms-delivery-list',
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { '[@fadeInAnimation]': 'true' },
  animations: [fadeInAnimation],
})
export class DeliveryListComponent implements OnInit {
  rows = [];
  nameControl = new FormControl();
  treeNodes: Array<object>;
  selectedTabIndex = 0;

  pageIndex: number;
  pageSize: number;
  length: number;
  filter: any;
  loadingIndicator = false;
  type: any = 'now';

  public LIST_STATUS = {
    NEW: 'NEW',
    DONE: 'DONE',
    DENIED: 'DENIED',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    CANCELLED: 'CANCELLED',
    DELIVERED: 'DELIVERED',
    'IN-PROGRESS': 'IN PROGRESS',
    REQUESTED_CANCEL: 'REQUESTED CANCEL',
  };

  basePath = 'deliveries';

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private deliveryAdapter: DeliveryAdapter,
    private dialog: MatDialog,
    private router: Router,
    private parseService: ParseService,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
    protected busyIndicatorService: BusyIndicatorService
  ) {
    this.pageSize = baseService.PAGE_SIZE;
  }

  ngOnInit() {
    this.pageTitleService.setTitle('Delivery Management');
    this.setTabIndex();
    this.getDeliveries();
    const { path } = this.activatedRoute.routeConfig;
    const type =
      path === 'deliveries' || path === 'deliveries/now' ? 'now' : 'schedule';
    this.type = type;
    this.treeNodes = [{ id: 'Delivery Now' }, { id: 'Delivery Schedule' }];
  }

  // Get all banners
  getDeliveries(event?: PageEvent) {
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageIndex = event.pageIndex;
    }
    let filter = '';
    const name = this.nameControl.value;
    if (name !== '' && !this.baseService.isNil(name)) {
      filter = `receiver_name=*${name}*`;
    }
    this.requestApi(this.pageIndex + 1, filter, this.pageSize);
  }

  public requestApi(pageNo, filter, pageSize?) {
    this.busyIndicatorService.show();
    this.loadingIndicator = true;
    const { path } = this.activatedRoute.routeConfig;
    const type =
      path === 'deliveries' || path === 'deliveries/now'
        ? 'DELIVERY_NOW'
        : 'DELIVERY_SCHEDULE';
    this.deliveryAdapter.getAll(pageNo, pageSize, filter, type).subscribe(
      (data) => {
        if (!data) {
          return;
        }
        this.rows = this.parseService.multi(data);
        this.loadingIndicator = false;
        this.length = data.meta.totalItems;
      },
      (error) => {
        this.snackbarService.showError(`Error : ${error}`);
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      }
    );
  }

  setTabIndex() {
    const { path } = this.activatedRoute.routeConfig;
    this.selectedTabIndex =
      path === 'deliveries' ||
      path === 'deliveries/now' ||
      path === null ||
      path === undefined
        ? 0
        : 1;
  }

  public onSelectedTabChange(evt) {
    location.assign('#/' + this.basePath + '/' + this.getCategoryGroup());
  }

  getCategoryGroup(): string {
    return this.selectedTabIndex === 0 ? 'now' : 'schedule';
  }

  delete(id) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((responseOK) => {
      if (responseOK) {
        this.deliveryAdapter.deleteById(id).subscribe(
          (data) => {
            this.getDeliveries();
            this.snackbarService.showSuccess('Deleted successfully');
          },
          (error) => {
            this.snackbarService.showError('Error');
            this.loadingIndicator = false;
          }
        );
      }
    });
  }
}
