import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class BookingBrowseV2Action extends ActionEntityV2Abstract {

  // Simulate GET /clients
  exec(options: any): Observable<any> {
    const number = options && options.page ? options.page : 1;
    const size = options && options.number ? options.number : 20;
    const status = options && options.status ? `status[]=${options.status}` : '';

    return this.http.get(
      `${this.apiUrl}/bookings?page[number]=${number}&page[size]=${size}&${status}`,
    );
  }
}
