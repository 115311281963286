import { Injectable } from '@angular/core';

import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { PromotionBrowseV1Action } from "./promotion-browse-v1.action";

/* V2 */
import { PromotionBrowseV2Action } from "./v2/promotion-browse-v2.action";


@Injectable()
export class PromotionAdapter {

  constructor(private browse: PromotionBrowseV2Action) {}

    getAllPromotionByUserId(id: number, type: any): Observable<any> {
      return this.browse.exec({id, type});
    }

}
