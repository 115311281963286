import { ActionEntityAbstract } from "./action-entity.abstract";
import { Injectable } from '@angular/core';
import { environment } from "./../../environments/environment";

@Injectable()
export abstract class ActionEntityV1Abstract extends ActionEntityAbstract {
    public readonly apiBaseUrl = environment.apiUrl_Web;
    public readonly apiOauthUrl = environment.apiUrl;

}
