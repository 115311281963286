import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class UserEditImageV1Action extends ActionEntityV1Abstract {

  //  User/UpdateBackgroundImageAndAvatar
  exec(options: any): Observable<any> {
    return this.http.put(`${this.apiBaseUrl}/User/UpdateBackgroundImageAndAvatar`,
      options.toFormData());
  }
}
