import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';


import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams
} from "@angular/common/http";

import { environment } from "environments/environment";
const API_URL = environment.apiUrl;

@Injectable()
export class AuthService {
  private token: string;
  private refreshToken: string;

  constructor(public router: Router, public http: HttpClient) {}

  // TODO: implement later
  signupUser(email: string, password: string) {
  }

  /** get access token by usernam & password
   *
   *  NOTE: will implement refresh flow later on
   */
  signinUser(email: string, password: string) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );

    const body = `email=${email}&password=${password}`;
    return this.http
      .post(`${API_URL}/oauth/login`, body, { headers })
      .pipe(
        map(res => this.extractAuthen(res))
      );
  }

  logout() {
    // TODO: will call signOut api
    localStorage.removeItem("currentUser");
    // this.router.navigate(["session/login"]);
  }

  getToken(): string {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    return (currentUser) ? currentUser.token : "";
  }

  isAuthenticated() {
    return localStorage.getItem("currentUser") !== null;
  }

  private extractAuthen(auth: any) {
    const user = {
      token: auth.data.attributes.access_token,
      refreshToken: auth.data.attributes.refresh_token,
      id: auth.data.attributes.user_id
    };

    localStorage.setItem("currentUser", JSON.stringify(user));
    return user;
  }
}
