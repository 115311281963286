import { BaseObject } from "./base-object";

export class DigitalScreen extends BaseObject {
    static fromData(data: any) {
        const {id, name, type, title, user_id, activate,last_online_at, is_online, admin_active_flg, disable_reason, duration, slot, share_slot, percent_share, price} = data;
        return new this(id, name, type, title, user_id, activate,last_online_at, is_online, admin_active_flg, disable_reason, duration, slot, share_slot, percent_share, price);
    }

    constructor(
        public id: number = null,
        public name: String,
        public type: String,
        public title: String,
        public user_id: number,
        public activate: boolean,
        public last_online_at: any,
        public is_online: boolean,
        public admin_active_flg: boolean,
        public disable_reason: String,
        public duration: number,
        public slot: number,
        public share_slot: number,
        public percent_share: number,
        public price: number,
    ) { super(); }
}
