import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CategoryAdapter, BaseService } from "../../services";
import { Category } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { MatDialog } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { AppContext } from "../../services/appcontext";
import { ParseService } from "../../services/parse.service";
import { SnackbarService } from "app/core/snackbar/snackbar.service";

import { Observable } from "rxjs";

@Component({
  selector: 'app-ms-category-new',
  templateUrl: './category-new.component.html',
  styleUrls: ['./category-new.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoryNewComponent implements OnInit {
  public form: FormGroup;
  public isItemEdited: boolean;
  public isLoading: boolean;
  public errorMessage: string;

  public item: Category;
  private id?: number = null;
  private pid?: number = null;
  private group: string = null;
  private type: string = null;
  public categoriesComboSource = [];
  // public selectedParent?: any;
  public selectedType?: any;
  public selectedGroup?: any;

  public _primaryImage: any;
  basePath = "categories";

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private categoryAdapter: CategoryAdapter,
    private restoreService: RestoreService<Category>,
    private dialog: MatDialog,
    private appContext: AppContext,
    private parseService: ParseService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {
    this.getRouteParams();
    let pageTitle = "Create new Category";
    if (this.id === null || this.id === undefined) {
      this.isItemEdited = false;
    } else {
      this.isItemEdited = true;
      pageTitle = "Edit Category";
    }
    this.pageTitleService.setTitle(pageTitle);
    this.loadData();

  }

  public getRouteParams() {
    this.id = this.activatedRoute.snapshot.params["id"];
    this.pid = this.activatedRoute.snapshot.params["pid"];
    this.group = this.activatedRoute.snapshot.params["group"];
  }

  public loadData() {
    this.isLoading = true;
    let query: Observable<any> = null;
    if (this.group === "Product" || this.group === "Menu") {
      query  = this.categoryAdapter.getAllProductCategories();
    } else {
      query  = this.categoryAdapter.GetAllServiceCategories();
    }
    query.subscribe(
      data => {
        let category = this.parseService.multi(data);

        if (category !== null && category.length > 0) {
          if (this.id !== null && this.id !== undefined) {
            category = (<any[]>category).filter(p => p.id !== this.id);
          }
          this.getCategoryComboSourceDeep(category);
        }
      },
      error => {
        this.errorMessage = <any>error;
      },
      () => {
        // Load other UI here...
        if (this.isItemEdited) {
          this.populateExistingItem();
        } else {
          this.populateNewItem();
        }
        this.isLoading = false;
      }
    );

    this.buildForm();
  }
  public getCategoryComboSourceDeep(rows) {
    rows.forEach((row, index) => {
      this.categoriesComboSource.push({id: row.id, name: row.name});
      // if (row.Children !== null && row.Children.length > 0) {
      //   this.getCategoryComboSourceDeep(row.Children);
      // }
    });
  }
  public populateExistingItem() {
    this.isLoading = true;
    this.categoryAdapter.getById(this.id).subscribe(
      item => {
        this.isLoading = false;
        this.item = this.parseService.single(item);
        this.restoreService.set(this.parseService.single(item));
        // this.selectedParent = !this.baseService.isNil(this.item.parent_id) ? this.item.parent_id.toString() : -1;
        if (this.item.type == 'Food') {
          this.item.type = 1;
        }
        if (this.item.type == 'Other') {
          this.item.type = 2;
        }
        this.selectedType = !this.baseService.isNil(this.item.type) ? this.item.type : -1;
        if (this.item.group == 'Product') {
          this.item.group = '1';
        }
        if (this.item.group == 'Service') {
          this.item.group = '2';
        }
        this.selectedGroup = !this.baseService.isNil(this.item.group) ? this.item.group : -1;
        this.buildForm();
      },
      error => {
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );
  }
  public populateNewItem() {
    this.item = <Category>{};
    this.item.id = null;
    this.item.group = this.group === undefined ? null : this.group;
    this.item.parent_id = this.pid === undefined || this.pid === -1 ? null : this.pid;
    this.item.type = null;
    // this.selectedParent = parseInt((this.baseService.isNil(this.pid) ? -1 : this.pid).toString(), null);
    this.selectedType = this.type;
    this.selectedGroup = this.group;
    this.buildForm();
    this.restoreService.set(this.item);
  }
  private buildForm() {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.minLength(3)])],
      group: [null, Validators.compose([Validators.required])],
      parent_id: [null],
      description: [null],
      type: [null],
      IconImage: [null]
    });

    if (this.item) {
      this.baseService.loadIconImage(this.item.primary_image);
      this.form.patchValue(this.item);
    }
  }

  readIconImage(ImageId, inputId) {
    const fUpload: any = document.getElementById(inputId);
    if (!fUpload || !fUpload.files || fUpload.files.length === 0) { return; }

    const f: any = fUpload.files[0];
    const img: any = document.getElementById(ImageId);
    const reader = new FileReader();
    reader.onloadend = (e: any) => {
      const data = e.target.result;
      img.src = data;
      if (ImageId === 'primaryImage') {
        this._primaryImage = f;
      }
    }
    reader.readAsDataURL(f);
  }

  onCancel() {
    this.router.navigate([this.basePath, this.group]);
  }
  onReset() {
    this.form.patchValue(this.restoreService.restoreItem());
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, { width: "300px" });
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.categoryAdapter.deleteById(this.id).subscribe(res => {
          this.router.navigate([this.basePath, this.group]);
        });
      }
    });
  }
  onSubmit() {
    const category = Category.fromData(this.form.value);
    category.id = this.id;
    if (category.parent_id === -1) {
      category.parent_id = null;
    }
    if (this._primaryImage !== undefined && this._primaryImage !== null) {
      category.primary_image = this._primaryImage;
    }

    if (category.type == 1) {
      category.type = 'Food';
    }
    if (category.type == 2) {
      category.type = 'Other';
    }
    if (category.group == '1') {
      category.group = 'Product';
    }
    if (category.group == '2') {
      category.group = 'Service';
    }
    if (category.id) {
      this.categoryAdapter.updateCategory(category).subscribe(
        res => {
          this.router.navigate([this.basePath]);
        },
        error => {
          this.snackbarService.showError('Error');
        }
      );
    } else {
      this.categoryAdapter.insertCategory(category).subscribe(
        res => {
          this.router.navigate([this.basePath]);
        },
        error => {
          this.snackbarService.showError('Error');
        }
      );
    }
  }
}
