import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { Router } from "@angular/router";
import { ServiceAdapter, CategoryAdapter, UserAdapter, BaseService } from "../../services";
import { MatDialog, PageEvent } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { fadeInAnimation } from "../../core";
import { TreeNode } from 'primeng/api';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ParseService } from "../../services/parse.service";
import { SnackbarService } from 'app/core/snackbar/snackbar.service';

@Component({
  selector: 'app-ms-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [fadeInAnimation]
})
export class ServiceListComponent implements OnInit {
  rows = [];
  isLoading: boolean;
  errorMessage: string;
  deleteId: number;
  loadingIndicator = false;

  // pagination
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;
  public pageNo: number;
  public filter: any;
  public selectedCategory: any;
  public selectedUser: any;
  public serviceCategories = [];
  public externalUsers: any;
  filteredCategories: Observable<string[]>;
  filteredUsers: Observable<string[]>;
  catControl = new FormControl();
  userControl = new FormControl();
  basePath = "services";
  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private serviceAdapter: ServiceAdapter,
    private categoryAdapter: CategoryAdapter,
    private userAdapter: UserAdapter,
    private router: Router,
    private dialog: MatDialog,
    private parseService: ParseService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle("Services Management");
    this.getServices();
    this.getServiceCategories();
    this.getAllUser('');
  }

  // Get all services
  getServices(event?: PageEvent) {
    this.pageNo = 1;
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageNo = event.pageIndex + 1;
      this.pageIndex = event.pageIndex;
    }
    this.filter = '';
    if (this.catControl.value !== "" && !this.baseService.isNil(this.selectedCategory)) {
      this.filter += 'category_id=' + this.selectedCategory.id;
    }
    if (this.userControl.value !== "" && !this.baseService.isNil(this.selectedUser)) {
      this.filter += '&user_id=' + this.selectedUser.id;
    }
    this.requestApi(this.pageNo, this.filter, this.pageIndex);
    return event;
  }

  public requestApi(pageNo, filter, pageIndex?) {
    this.serviceAdapter.getAll(pageNo, filter).subscribe(
      data => {
        this.rows = this.parseService.multi(data);
        this.loadingIndicator = false;
        this.pageIndex = pageIndex;
        this.pageSize = 20;
        this.length = data.meta.totalItems;
      },
      error => {
        this.errorMessage = <any>error;
        this.loadingIndicator = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  delete(id) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, { width: "300px" });
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.serviceAdapter.deleteById(id).subscribe(
          res => {
            this.requestApi(this.pageNo, this.filter);
            this.snackbarService.showSuccess('Successfully');
          },
          error => {
            this.errorMessage = <any>error;
            this.loadingIndicator = false;
          },
        );
      }
    });
  }

  private _filter(value: any, type): any[] {
    let filterValue = ''
    if (type === 'category') {
      filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
      this.selectedCategory = value.name ? value : null;
      return this.serviceCategories.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }
    if (type === 'user') {
      filterValue = value.fullname ? value.fullname.toLowerCase() : value.toLowerCase();
      this.getAllUser(filterValue);
      this.selectedUser = value.fullname ? value : null;
      return this.externalUsers.filter(option => option.fullname.toLowerCase().indexOf(filterValue) === 0);
    }
  }

  public displayFn(object): Object {
    return object && (object.name ? object.name : (object.fullname ? object.fullname : object));
  }

  public getAllUser(key: string) {
    this.userAdapter.GetAllUsersByFilter(key).subscribe(
      data => {
        this.externalUsers = this.parseService.multi(data);
        this.filteredUsers = this.userControl.valueChanges.pipe(
          startWith(''),
          map(user => user ? this._filter(user, 'user') : this.externalUsers.slice())
        );
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  public getServiceCategories() {
    this.categoryAdapter.GetAllServiceCategories().subscribe(
      data => {
        const category = this.parseService.multi(data);
        if (category !== null && category.length > 0) {
          this.getCategoryComboSourceDeep(category);
        }

        this.filteredCategories = this.catControl.valueChanges.pipe(
          startWith(''),
          map(option => option ? this._filter(option, 'category') : this.serviceCategories.slice())
        );
      },
      error => {
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );
  }
  public getCategoryComboSourceDeep(rows) {
    rows.forEach((row, index) => {
      this.serviceCategories.push({ id: row.id, name: row.name });
      // if (row.Children !== null && row.Children.length > 0) {
      //   this.getCategoryComboSourceDeep(row.Children);
      // }
    });
  }
}
