import { BusyIndicatorService } from '../../core/busyIndicator/busyIndicator.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DigitalShareAdapter, BaseService } from "../../services";
import { MatDialog, PageEvent } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { fadeInAnimation } from "../../core";
import { FormControl } from '@angular/forms';
import { ParseService } from "../../services/parse.service";
import { SnackbarService } from 'app/core/snackbar/snackbar.service';
import { DigitalShare } from "../../models";

@Component({
  selector: 'app-ms-banner-list',
  templateUrl: './digital-list.component.html',
  styleUrls: ['./digital-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [fadeInAnimation]
})
export class DigitalShareListComponent implements OnInit {
  rows = [];
  nameControl = new FormControl();

  pageIndex: number;
  pageSize: number;
  length: number;
  filter: any;
  loadingIndicator = false;

  basePath = "digitals_share";
  public form: FormGroup;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private digitalAdapter: DigitalShareAdapter,
    private dialog: MatDialog,
    private router: Router,
    private parseService: ParseService,
    private snackbarService: SnackbarService,
    protected busyIndicatorService: BusyIndicatorService,
  ) {
    this.pageSize = baseService.PAGE_SIZE;
  }

  ngOnInit() {
    this.pageTitleService.setTitle("Digitals Share Management");
    this.getBanners();
  }

  // Get all banners
  getBanners(event?: PageEvent) {
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageIndex = event.pageIndex;
    }
    let filter = '';
    const name = this.nameControl.value;
    if (name !== "" && !this.baseService.isNil(name)) {
      filter = `name=*${name}*`;
    }
    this.requestApi(this.pageIndex + 1, filter, this.pageSize);
  }

  toggleActiveFlg(item: DigitalShare) {
    const digital = DigitalShare.fromData(item);
    digital.admin_active_flg = !item.admin_active_flg;
    this.digitalAdapter.updateById(digital).subscribe(res => {
      this.getBanners();
      this.snackbarService.showSuccess('Updated successfully');
    }, (errors) => {
      errors.errors[0].map(item => {
        this.snackbarService.showError(`Error: ${item.detail}`);
        this.loadingIndicator = false;
      })
    });
  }

  public requestApi(pageNo, filter, pageSize?) {
    this.busyIndicatorService.show();
    this.loadingIndicator = true;
    this.digitalAdapter.getAll(pageNo, pageSize, filter).subscribe(
      data => {
        if (!data) {
          return;
        }
        this.rows = this.parseService.multi(data);
        let extVideo = ['ogm', 'wvm', 'mpg', 'webm', 'ogv', 'omv', 'asx', 'mpeg', 'mp4', 'avi', 'mov', 'm4v'];
        this.rows.map(item => {
          let imgHorUrl = item.image_horizontal ? item.image_horizontal.url : '';
          let imgVerUrl = item.image_vertical ? item.image_vertical.url : '';
          item.isHorVideo = false;
          item.isVerVideo = false;
          if (imgHorUrl && extVideo.includes(imgHorUrl.substr(imgHorUrl.lastIndexOf(".") + 1))) {
            item.isHorVideo = true;
          } else if (imgVerUrl && extVideo.includes(imgVerUrl.substr(imgVerUrl.lastIndexOf(".") + 1))) {
            item.isVerVideo = true;
          }
        })
        this.loadingIndicator = false;
        this.length = data.meta.totalItems;
      },
      error => {
        this.snackbarService.showError(`Error : ${error}`);
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      }
    );
  }

  delete(id) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, { width: "300px" });
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.digitalAdapter.deleteById(id).subscribe(
          data => {
            this.getBanners();
            this.snackbarService.showSuccess('Deleted successfully');
          },
          error => {
            this.snackbarService.showError('Error');
            this.loadingIndicator = false;
          }
        );
      }
    });
  }
}
