import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class ClientAddOrUpdateV1Action extends ActionEntityV1Abstract {

  //  POST /roles
  exec(options: any): Observable<any> {
    return this.http.post(
        `${this.apiOauthUrl}/api/manage/Client/AddOrUpdate`,
        options.client.toFormUrlEncoded()
      );
  }
}
