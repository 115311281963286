import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class PermissionBrowseV2Action extends ActionEntityV2Abstract {
    exec({pageNo, pageSize, filter}): Observable<any> {
        return this.http.get(
          `${this.apiUrl}/permissions?page[number]=${pageNo}&page[size]=${pageSize}&${filter}`
        );
    }

}
