import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class UserBrowseInternalV1Action extends ActionEntityV1Abstract {

  //  GET /users/internal
  exec(options: any): Observable<any> {
    return this.http.get(`${this.apiOauthUrl}/api/manage/User/Internal`);
  }
}
