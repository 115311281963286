import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class CategoryBrowseProductV2Action extends ActionEntityV2Abstract {

  // Simulate GET /clients
  exec(options: any): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/categories?group=Product&page[size]=100`,
    );
  }
}
