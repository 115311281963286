import { BaseObject } from "./base-object";

export class Role extends BaseObject {
  static fromData(data: any) {
    const { id, name, guard_name, permissions } = data;

    return new this(id, name, guard_name, permissions);
  }

  constructor(
    public id: number = null,
    public name: string,
    public guard_name: string,
    public permissions: any

  ) {
    super();
  }
}
