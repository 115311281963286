import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class SubcribersBrowseV2Action extends ActionEntityV2Abstract {
  // Simulate GET /clients
  exec(options: any): Observable<any> {
    console.log("options", options);
    const number = options && options.page ? options.page : 1;
    const size = options && options.number ? options.number : 20;
    const email = options && options.email ? `email[]=${options.email}` : "";

    return this.http.get(
      `${this.apiUrl}/subscribers?page[number]=${number}&page[size]=${size}&${email}`
    );
  }
}
