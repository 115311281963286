import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormGroup,
} from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceAdapter, PromotionAdapter, ReviewAdapter, BaseService } from "../../services";
import { Service, Promotion, Review } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { AppContext } from "../../services/appcontext";
import { ParseService } from "../../services/parse.service";

@Component({
  selector: "app-ms-service-info",
  templateUrl: "./service-info.component.html",
  styleUrls: ["./service-info.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class ServiceInfoComponent implements OnInit {
  private id?: number = null;
  public item: Service;

  public promotions: Promotion[];
  public reviews: Review[];
  public applicationTypes = [];
  basePath = "services";
  public _IconImage: any;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private serviceAdapter: ServiceAdapter,
    private reviewAdapter: ReviewAdapter,
    private activatedRoute: ActivatedRoute,
    private restoreService: RestoreService<Service>,
    private appContext: AppContext,
    private parseService: ParseService
  ) {}

  ngOnInit() {
    const pageTitle = "Service Info";
    this.id = this.activatedRoute.snapshot.params["id"]
    this.getItem();
    this.getReviews();
    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
    this.promotions = [];
  }

  public getItem() {
    this.serviceAdapter.getById(this.id).subscribe(
      item => {
        const service = this.parseService.single(item);
        this.restoreService.set(this.parseService.single(item));
        this.item = this.parseService.single(item)
        if (!this.baseService.isNil(service.promotion)) {
          this.promotions = [service.promotion];
        }
      }
    );

  }

  public getReviews() {
    this.reviewAdapter.getAllServiceReviews(this.id, 1, {}).subscribe(
      response => {
        const reviews = this.parseService.multi(response);
        this.reviews = reviews;
      }
    );
  }
}
