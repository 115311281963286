import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";

import { Review } from "../../models";
import { RestoreService, BaseService } from "../../services";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { ReviewAdapter } from "./../../services/";
import { ParseService } from "../../services/parse.service";

@Component({
  selector: "app-ms-review-edit",
  templateUrl: "./review-edit.component.html",
  styleUrls: ["./review-edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class ReviewEditComponent implements OnInit {
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public isLoading: boolean;
  public errorMessage: string;
  public item: Review;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private reviewAdapter: ReviewAdapter,
    private restoreService: RestoreService<Review>,
    private dialog: MatDialog,
    private parseService: ParseService,
  ) {}

  ngOnInit() {
    const pageTitle = "Edit Review";

    this.setItemId();
    this.isItemEdited = true;

    this.getItem();

    this.pageTitleService.setTitle(pageTitle);
  }

  public getItem() {
    // this.busyIndicatorService.show();
    this.isLoading = true;
    this.reviewAdapter.getById(this.id).subscribe(
      item => {
        this.isLoading = false;
        const data = this.parseService.single(item);
        this.restoreService.set(data);
        this.item = this.restoreService.get();
        this.buildForm();
      },
      error => {
        // this.busyIndicatorService.hide();
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      comment: [null],
    });

    if (this.item) {
      this.form.patchValue(this.item);
    }
  }

  public populateDataForNewItem() {
    this.item = <Review>{};
    this.item["Id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
  }

  onReset() {
    this.form.patchValue(this.restoreService.restoreItem());
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.reviewAdapter.deleteById(this.id).subscribe(res => {
          this.router.navigate(["users"]);
        });
      }
    });
  }

  onSubmit() {
    const review = Review.fromData(this.form.value);
    review.id = this.id;
    this.reviewAdapter.update(review).subscribe(
      res => {
          this.router.navigate(["reviews"]);
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

}
