import { BaseObject } from "./base-object";

export class BannerDigital extends BaseObject {
    static fromData(data: any) {
        const { id, description, image_vertical, image_horizontal, title, user_id } = data;
        return new this(id, description, image_vertical, image_horizontal, title, user_id);
    }

    constructor(
        public id: number = null,
        public description: String,
        public image_vertical: File,
        public image_horizontal: File,
        public title: String,
        public user_id: String
    ) { super(); }
}
