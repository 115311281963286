import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class OrderReadV2Action extends ActionEntityV2Abstract {

  // Simulate GET /orders/:id
  exec(options: any): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/orders/${options.id}`,
    );
  }
}
