import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class ServiceBrowserV1Action extends ActionEntityV1Abstract {

  // Simulate GET /Services
  exec(options: any): Observable<any> {
    return this.http.get(
      `${this.apiBaseUrl}/Service/GetAllServicesByCategoryIdAndUserIdAndFilter?PageNo=${options.pageNo}&${options.filter}`
    );
  }
}
