import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../action-entity-v2.abstract";
import { environment } from '../../../environments/environment';
@Injectable()
export class NotisBrowseV1Action extends ActionEntityV2Abstract {
  exec({ pageNo, pageSize, filter, type }): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/notis/${environment.idAdmin}?is_read=0&page[number]=${pageNo}&page[size]=${pageSize}&type=${type}&${filter}`
    );
  }
}
