import { SnackbarService } from 'app/core/snackbar/snackbar.service';
import { ActionAdapter, UserAdapter } from '../../services/index';
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl
} from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";

import { User } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";



function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('password');
  const cpwd = c.parent.get('confirmPassword')

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

@Component({
  selector: "app-ms-user-new",
  templateUrl: "./user-new.component.html",
  styleUrls: ["./user-new.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class UserNewComponent implements OnInit {
  public form: FormGroup;
  private id?: number = null;

  public isLoading: boolean;
  public errorMessage: string;
  public item: User;
  public userType: string;
  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userAdapter: UserAdapter,
    private restoreService: RestoreService<User>,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit() {
    const pageTitle = "Create New User";
    this.populateDataForNewItem();
    this.pageTitleService.setTitle(pageTitle);
  }


  private buildForm() {
    this.form = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, Validators.email])
      ],
      name: [
        null,
        Validators.compose([Validators.required])
      ],
      password: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6)])
      ],
      confirmPassword: [
        null,
        Validators.compose([Validators.required, passwordConfirming])
      ]
    });

    if (this.item) {
      this.form.patchValue(this.item);
    }
  }

  public populateDataForNewItem() {
    this.item = <User>{};
    this.item["Id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
    this.setUserType();
  }

  private setUserType() {
    this.userType = this.activatedRoute.snapshot.params["type"];
  }

  onSubmit() {
    if (this.userType === "external") {
      this.userAdapter
        .registerExternallUser(this.form.value.email, this.form.value.name, this.form.value.password, this.form.value.confirmPassword)
        .subscribe(_ => {
          this.router.navigate(["users"]);
          this.snackbarService.showSuccess("Created Successfully");
        }, err => {
          if (err.error.errors.length) {
            this.snackbarService.showError(err.error.errors[0].detail);
          }
        });
    } else {
      this.userAdapter
        .registerInternalUser(this.form.value.email, this.form.value.name, this.form.value.password, this.form.value.confirmPassword)
        .subscribe(_ => {
          this.snackbarService.showSuccess("Updated Successfully");
          this.router.navigate(["users"]);
        }, err => {
          if (err.error.errors) {
            this.snackbarService.showError(err.error.errors[0].detail);
          }
        });
    }
  }
}
