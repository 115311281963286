import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class CategoryRemoveV1Action extends ActionEntityV1Abstract {

  // Simulate DELETE /clients/:id
  exec(options: any): Observable<any> {
    return this.http.delete(
      `${this.apiBaseUrl}/Category/DeleteCategory?id=${options.id}`,
    );
  }

}
