import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable, of, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthResponseHandler implements HttpInterceptor {

  constructor(private router: Router) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError((err, c) => {
          if (err.status === 401) {
            this.router.navigate(['/session/login']);
          }
          if (err.status === 403 && err.error.errors.length && err.error.errors.some(error => error.detail === 'User is not logged in.')) {
            this.router.navigate(['/session/login']);
          }
          return throwError(err);
        })
      );
  }
}
