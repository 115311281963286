import { Permission } from '../../models/permission';
import { Injectable} from '@angular/core';
import { Observable } from "rxjs";
import { PermissionAddV2Action } from './v2/permission-add-v2.action';
import { PermissionRemoveV2Action } from './v2/permission-remove-v2.action';
import { PermissionReadV2Action } from './v2/permission-read-v2.action';
import { PermissionBrowseV2Action } from './v2/permission-browse-v2.action';
import { PermissionEditV2Action } from './v2/permission-edit-v2.action';


@Injectable()
export class PermissionAdapter {
  constructor(
    private add: PermissionAddV2Action,
    private remove: PermissionRemoveV2Action,
    private read: PermissionReadV2Action,
    private edit: PermissionEditV2Action,
    private browse: PermissionBrowseV2Action,
  ) {}

   getAll(pageNo: any,  pageSize: number, filter: any) {
    return this.browse.exec({pageNo, pageSize, filter});
  }

  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }

  updateById(permission: Permission): Observable<any> {
    return this.edit.exec(permission);
  }

  create(permission: Permission): Observable<any> {
    return this.add.exec(permission);
  }

  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }
}
