import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "delete-confirm-dialog.html"
})
export class DeleteConfirmDialogComponent {
  model = null;

  private defaults = {
    title: 'Confirmation',
    message: 'Do you want to delete item?',
    yesText: 'Yes',
    noText: 'No'
  };

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.model = { ...this.defaults, ...data }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
