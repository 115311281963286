export class BaseObject {

  static nilToEmp(value) {
    return value === undefined || value === null ? "" : value;
  }

  toFormUrlEncoded() {
    const formBody = [];

    Object.keys(this).forEach(key => {
      const value = this[key];
      if (value) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value);
        formBody.push(encodedKey + "=" + encodedValue);
      }
    });
    return formBody.join("&");
  }

  toFormData() {
    const formBody: FormData = new FormData();
    Object.keys(this).forEach(key => {
      const value = this[key];
      if (value) {
        formBody.append(key, value);
      }
    });
    return formBody;
  }
}
