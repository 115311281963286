import { Component, Optional, ViewEncapsulation } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MatSnackBar
} from "@angular/material";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AppContext } from "./services/appcontext";

@Component({
  selector: "app-cardbey-admin",
  templateUrl: "./app.component.html",
  encapsulation: ViewEncapsulation.None
})
export class GeneAppComponent {
  constructor(translate: TranslateService, appContext: AppContext) {
    translate.addLangs(["en", "fr"]);
    translate.setDefaultLang("en");

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");

    // appContext.initialize();
  }
}
