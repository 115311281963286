import { Injectable } from '@angular/core';
import { environment } from "environments/environment";
import { MatSnackBar } from "@angular/material";

@Injectable()
export class BaseService {
    public apiDomain = environment.apiUrl_Web;
    defaultIconImage = "/media/category_images/default-category.png";
    public PAGE_SIZE = 20;

    constructor(public snackBar: MatSnackBar) { }

    // public PAGE_SIZE = environment.pageSize;
    public readIconImage(fileId, imageId) {
        const fUpload: any = document.getElementById(fileId);
        if (!fUpload || !fUpload.files || fUpload.files.length === 0) { return; }

        const f: any = fUpload.files[0];
        const img: any = document.getElementById(imageId);
        const reader = new FileReader();
        reader.onloadend = (e: any) => {
            const data = e.target.result;
            img.src = data;
        }
        reader.readAsDataURL(f);
        return f;
    }

    public isNil(value): boolean {
        return value === null || value === undefined;
    }

    public loadIconImage(image) {
        return (this.isNil(image) ? this.defaultIconImage : image);
    }
}
