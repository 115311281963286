import { RoleBrowseOtherV1Action } from './role-browse-other-v1.action';
import { Role } from './../../models/role';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { RoleBrowseV2Action } from "./v2/role-browse-v2.action";
import { RoleEditV2Action } from "./v2/role-edit-v2.action";
import { RoleAddV2Action } from './v2/role-add-v2.action';
import { RoleRemoveV2Action } from './v2/role-remove-v2.action';
import { RoleReadV2Action } from './v2/role-read-v2.action';
import { RoleBrowseV1Action } from '..';

@Injectable()
export class RoleAdapter {
  constructor(
    private add: RoleAddV2Action,
    private remove: RoleRemoveV2Action,
    private read: RoleReadV2Action,
    private browse: RoleBrowseV2Action,
    private edit: RoleEditV2Action,
  ) { }

  getAll(pageNo: any, pageSize: number, filter: any) {
    return this.browse.exec({ pageNo, pageSize, filter });
  }

  getById(id: number): Observable<any> {
    return this.read.exec({ id });
  }

  updateById(role: Role): Observable<any> {
    return this.edit.exec(role);
  }

  create(role: Role): Observable<any> {
    return this.add.exec(role);
  }

  deleteById(id: number): Observable<any> {
    return this.remove.exec({ id });
  }

  getAvailableRoles(): Observable<any> {
    return this.browse.exec(null);
  }
}
