import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class OrderBrowseV1Action extends ActionEntityV1Abstract {

  exec(options: any): Observable<any> {
    const filter = `&Status=${options.selectedStatus}&From=${options.fromDate}&To=${options.toDate}&Search=${options.search}`;
    return this.http.get(`${this.apiBaseUrl}/api/admin/orders?PageNo=${options.page}${filter}`
    );
  }

}
