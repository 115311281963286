import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  HostListener,
  ViewEncapsulation
} from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { DeviceDetectorService } from "ngx-device-detector";
import PerfectScrollbar from "perfect-scrollbar";

import { BreadcrumbService } from "ng5-breadcrumb";
import {TranslateService} from '@ngx-translate/core';
import { MenuItems } from "../core/menu/menu-items/menu-items";
import { PageTitleService } from "../core/page-title/page-title.service";
import { filter } from 'rxjs/operators';
import { AuthService } from "../auth/auth.service";
import { NotisAdapter, BaseService } from "../services";
import { ParseService } from "../services/parse.service";
declare var $: any;

const screenfull = require("screenfull");

@Component({
  selector: "app-gene-layout",
  templateUrl: "./main-material.html",
  styleUrls: ["./main-material.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit, OnDestroy {
  private _router: Subscription;
  header: string;
  currentLang = "en";
  url: string;
  showSettings = false;
  dark: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  customizerIn = false;
  root = "ltr";
  chatpanelOpen = false;
  deviceInfo = null;
  rows = [];
  showNotiScreenShare = 0;
  totalNoti = 0;
  private _mediaSubscription: Subscription;
  sidenavOpen = true;
  sidenavMode = "side";
  isMobile = false;
  private _routerEventsSubscription: Subscription;

  private isFullscreen = false;

  @ViewChild("sidenav") sidenav;

  constructor(
    public menuItems: MenuItems,
    private breadcrumbService: BreadcrumbService,
    private pageTitleService: PageTitleService,
    public translate: TranslateService,
    private router: Router,
    private media: MediaObserver,
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private baseService: BaseService,
    private notisAdapter: NotisAdapter,
    private parseService: ParseService
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");

    breadcrumbService.addFriendlyNameForRoute("/dashboard", "Dashboard");
    breadcrumbService.addFriendlyNameForRoute("/session", "Session");
    breadcrumbService.addFriendlyNameForRoute("/session/login", "Login");
    breadcrumbService.addFriendlyNameForRoute("/session/register", "Register");
    breadcrumbService.addFriendlyNameForRoute(
      "/session/forgot-password",
      "Forgot"
    );
    breadcrumbService.addFriendlyNameForRoute(
      "/session/lockscreen",
      "Lock Screen"
    );
  }

  ngOnInit() {

    this.pageTitleService.title.subscribe((val: string) => {
      this.header = val;
    });

    this._router = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.url = event.url;
      });

    if (
      this.url !== "/session/login" &&
      this.url !== "/session/register" &&
      this.url !== "/session/forgot-password" &&
      this.url !== "/session/lockscreen"
    ) {
      const elemSidebar = <HTMLElement>document.querySelector(
        ".sidebar-container "
      );
      if (window.matchMedia(`(min-width: 960px)`).matches) {
        const ps = new PerfectScrollbar(elemSidebar);
      }
    }

    this.deviceInfo = this.deviceService.getDeviceInfo();
    // console.log(this.deviceInfo.device);
    if (
      this.deviceInfo.device === "ipad" ||
      this.deviceInfo.device === "iphone" ||
      this.deviceInfo.device === "android"
    ) {
      this.sidenavMode = "over";
      this.sidenavOpen = false;
    } else {
      this._mediaSubscription = this.media
        .media$
        .subscribe((change: MediaChange) => {
          const isMobile = change.mqAlias === "xs" || change.mqAlias === "sm";

          this.isMobile = isMobile;
          this.sidenavMode = isMobile ? "over" : "side";
          this.sidenavOpen = !isMobile;
        });

      this._routerEventsSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd && this.isMobile) {
          this.sidenav.close();
        }
      });
    }
    if(this.router.url !='/digitals_share'){
      this.getNotis() ;
    }

  }

  ngOnDestroy() {
    this._router.unsubscribe();
    this._mediaSubscription.unsubscribe();
  }

  // Get all notis
  getNotis() {
    const page = 1;
    const pageSize = 30;
    this.notisAdapter.getAll(page, pageSize, '', null).subscribe(
      data => {
        if (!data) {
          return;
        }
        this.rows = data.notis;
        this.showNotiScreenShare = this.rows.length;
        this.totalNoti +=this.rows.length;
      },
      error => {
        //code
      },
      () => {
         //code
      }
    );
  }
  menuMouseOver(): void {
    if (
      window.matchMedia(`(min-width: 960px)`).matches &&
      this.collapseSidebar
    ) {
      this.sidenav.mode = "over";
    }
  }
  gotoPage(link): void {
    this.router.navigate([link]);
  }
  menuMouseOut(): void {
    if (
      window.matchMedia(`(min-width: 960px)`).matches &&
      this.collapseSidebar
    ) {
      this.sidenav.mode = "side";
    }
  }

  toggleFullscreen() {
    if (screenfull.enabled) {
      screenfull.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }

  customizerFunction() {
    this.customizerIn = !this.customizerIn;
  }

  addClassOnBody(event) {
    if (event.checked) {
      $("body").addClass("dark-theme-active");
    } else {
      $("body").removeClass("dark-theme-active");
    }
  }

  addMenuItem(): void {
    this.menuItems.add({
      state: "pages",
      name: "GENE MENU",
      type: "sub",
      icon: "trending_flat",
      children: [
        { state: "blank", name: "SUB MENU1" },
        { state: "blank", name: "SUB MENU2" }
      ]
    });
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  onLogout() {
    this.router.navigate(['session/login'])
  }

  goUserInfo() {
    this.router.navigate(['account_settings'])
  }

  goChangePassword() {
    this.router.navigate(['update_password'])
  }
}
