import { Injectable } from "@angular/core";

import { ConstantAdapter } from "../services/constant/constant.adapter";

@Injectable()
export class AppContext {
  public applicationTypes: Array<AppConstant>;
  public userTypes: Array<AppConstant>;

  constructor(private constantAdapter: ConstantAdapter) {
    this.applicationTypes = [];
    this.userTypes = [];
  }

  initialize() {
    // this.constantAdapter.getContants("ApplicationTypes").subscribe(data => this.applicationTypes = data.data);
    // this.constantAdapter.getContants("UserTypes").subscribe(data => this.userTypes = data.data);
  }
}

export class AppConstant {
  public Name: string;
  public Code: number;
}
