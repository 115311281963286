import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';

@Injectable()
export class DeliveryReadV2Action extends ActionEntityV2Abstract {
  exec(options: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/deliveries/${options.id}`);
  }
}
