import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ClientAdapter } from "../../services";
import { Client } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { AppContext } from "../../services/appcontext";

const password = new FormControl("", Validators.required);
const confirmPassword = new FormControl("", CustomValidators.equalTo(password));

@Component({
  selector: "app-ms-client-item",
  templateUrl: "./client-item.component.html",
  styleUrls: ["./client-item.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class ClientItemComponent implements OnInit {
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public isLoading: boolean;
  public errorMessage: string;
  public item: Client;

  //
  public applicationTypes = [];

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clientAdapter: ClientAdapter,
    private restoreService: RestoreService<Client>,
    private dialog: MatDialog,
    private appContext: AppContext
  ) {}

  ngOnInit() {
    let pageTitle = "Create new Client";

    this.setItemId();

    if (this.id === null || this.id === undefined) {
      this.isItemEdited = false;
      this.populateDataForNewItem();
    } else {
      this.isItemEdited = true;
      pageTitle = "Edit Client";
      this.getItem();
    }

    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
  }

  public getItem() {
    // this.busyIndicatorService.show();
    this.isLoading = true;

    this.clientAdapter.getById(this.id).subscribe(
      item => {
        // this.busyIndicatorService.hide();
        this.isLoading = false;
        this.restoreService.set(item.data);
        this.item = this.restoreService.get();
        this.buildForm();
      },
      error => {
        // this.busyIndicatorService.hide();
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      ClientKey: [
        null
        //   Validators.compose([Validators.required])
      ],
      Name: [
        null,
        Validators.compose([Validators.required, Validators.minLength(5)])
      ],
      ApplicationType: [null, Validators.compose([Validators.required])],
      AccessTokenLifeTime: [null, Validators.compose([Validators.required])],
      RefreshTokenLifeTime: [null, Validators.compose([Validators.required])],
      Active: [null],
      AllowedOrigin: [null]
    });

    if (this.item) {
      this.form.patchValue(this.item);
    }
  }

  public populateDataForNewItem() {
    this.item = <Client>{};
    this.item["Id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
  }

  onCancel() {
    this.router.navigate(["clients"]);
  }

  onReset() {
    this.form.patchValue(this.restoreService.restoreItem());
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.clientAdapter.deleteById(this.id).subscribe(res => {
          this.router.navigate(["clients"]);
        });
      }
    });
  }

  onSubmit() {
    const client = Client.fromData(this.form.value);
    client.Id = this.id;
    this.clientAdapter.addOrUpdate(client).subscribe(res => {
      this.router.navigate(["clients"]);
    });
  }
}
