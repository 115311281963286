import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class UserAddExternalV1Action extends ActionEntityV1Abstract {

  //  POST /users/external
  exec({ name: fullname, password, email, confirmPassword }): Observable<any> {
    const body = {
      fullname,
      email,
      password
    };

    return this.http.post(`${this.apiOauthUrl}/users`, body);
  }
}
