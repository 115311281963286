import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class ServiceBrowserV2Action extends ActionEntityV2Abstract {

  // Simulate GET /Services
  exec(options: any): Observable<any> {
    const number = options && options.pageNo ? options.pageNo : 1;
    const size = options && options.number ? options.number : 20;

    return this.http.get(
      `${this.apiUrl}/services?page[number]=${number}&page[size]=${size}&${options.filter}`
    );
  }
}
