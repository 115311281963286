import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Service } from "../../models/";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class ServiceEditV1Action extends ActionEntityV1Abstract {

  // Simulate POST /Services
  exec(service: Service): Observable<any> {
    return this.http.put(
      `${this.apiBaseUrl}/Service/UpdateService`,
      service.toFormData()
    );
  }
}
