import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Product } from "../../models/product";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class ProductEditV1Action extends ActionEntityV1Abstract {

  // Simulate POST /products
  exec(product: Product): Observable<any> {
    return this.http.post(
      `${this.apiBaseUrl}/Product/UpdateProduct`,
      product.toFormData()
    );
  }

}
