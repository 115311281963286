import { BaseObject } from "./base-object";

export class Promotion extends BaseObject {
  public static readonly defaultIconImage = "/media/category_images/default-category.png";
  static fromData(data: any) {
    const {
      Id,
      StartDate,
      EndDate,
      PercentDiscount,
      Description,
      IsBoost,
      IsLive
    } = data;

    return new this(
      Id,
      StartDate,
      EndDate,
      PercentDiscount,
      Description,
      IsBoost,
      IsLive
    );
  }

  constructor(
    public Id: number = null,
    public StartDate: string,
    public EndDate: string,
    public PercentDiscount: number,
    public Description: string,
    public IsBoost: boolean,
    public IsLive: string
  ) {
    super();
  }
}
