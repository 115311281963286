import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "environments/environment";

@Pipe({ name: "imageAPI" })
export class ImageAPIPipe implements PipeTransform {
    transform(image) {
        const defaultIconImage = "/assets/img/default.png";
        if (image == undefined) {
            image = null;
        }
        if (image !== null && image.hasOwnProperty('url')) {
            image = image.url;
        }
        return ((image === null || image === undefined || image === 'null') ? defaultIconImage : image);

    }
}
