import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class RoleBrowseV2Action extends ActionEntityV2Abstract {
  exec({ pageNo, pageSize, filter }): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/roles?page[number]=${pageNo}&page[size]=${pageSize}&${filter}`
    );
  }
}
