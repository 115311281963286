import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RolePermissionActionV1Action } from "./role-permission-action-v1.action";
import { RolePermissionControllerV1Action } from "./role-permission-controller-v1.action";
import { RolePermissionAssignV1Action } from "./role-permission-assign.action";


@Injectable()
export class RolePermissionAdapter {

  constructor(
    private action: RolePermissionActionV1Action,
    private controller: RolePermissionControllerV1Action,
    private assign: RolePermissionAssignV1Action,
  ) {}

  //  GET /actionsInRoles
  getActionsInRole(roleId: number): Observable<any> {
    return this.action.exec({roleId});
  }

  //  GET /controllerInRoles
  getControllersInRole(roleId: number): Observable<any> {
    return this.controller.exec({roleId});
  }

  // POST /assignPermissionsToRole
  assignPermissionsToRole(
    roleId: number,
    controllerIds: Array<number>,
    actionIds: Array<number>
  ): Observable<any> {
    return this.assign.exec({roleId, controllerIds, actionIds});
  }

  getPermissions(): Observable<any>{
    return this.controller.get();
  }
}
