import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class UserRoleAssignV1Action extends ActionEntityV1Abstract {

  // POST /assignUserToRole
  exec(options: any): Observable<any> {
    let body = `roleId=${options.roleId}`;
    options.userIds.forEach((val, idx) => {
      body += `&userIds[${idx}]=${val}`
    });

    return this.http.post(`${this.apiOauthUrl}/api/manage/UsersInRole/Assign`, body);
  }
}
