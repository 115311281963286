import { Role } from './../../../models/role';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';

@Injectable()
export class RoleEditV2Action extends ActionEntityV2Abstract {
  exec(role: Role): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}/role/${role.id}`,
      role,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }
}
