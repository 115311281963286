import { PermissionAdapter } from './../../services/permission/permission.adapter';
import { SnackbarService } from 'app/core/snackbar/snackbar.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTabChangeEvent,
  PageEvent
} from "@angular/material";

import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { ModalDialogService } from "../../core/modalDialog/modalDialog.service";
import { BusyIndicatorService } from "../../core/busyIndicator/busyIndicator.service";
import { BaseService } from "../../services";
import { ParseService } from "../../services/parse.service";
import { Subscription, throwError } from "rxjs";
import { FormControl } from "@angular/forms";
import { map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PermissionListComponent implements OnInit, OnDestroy {

  permissions: any;
  selectedTabIndex: any;
  public deleteId: number;
  subscriptions: Subscription[] = [];
  loadingIndicator = false;
  externalUsers: any
  basePath = "permissions";
  nameControl = new FormControl();


  // pagination
  public pageEvent: PageEvent;
  public pageIndex: number;
  public pageSize: number;
  public length: number;
  public pageNo: number;
  public filter: any;
  public totalPage: any;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private permissionAdapter: PermissionAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalDialogService: ModalDialogService,
    protected busyIndicatorService: BusyIndicatorService,
    private dialog: MatDialog,
    private parseService: ParseService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle("Permissions Management");
    this.pageSize = this.baseService.PAGE_SIZE;
    this.getPermissions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(item => item.unsubscribe());
  }

  getPermissions(event?: PageEvent) {
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageIndex = event.pageIndex;
    }
    let filter = '';
    const name = this.nameControl.value;
    if (name !== "" && !this.baseService.isNil(name)) {
      filter = `name=*${name}*`;
    }
    this.requestApi(this.pageIndex + 1, filter, this.pageSize);
  }

  public requestApi(pageNo, filter, pageSize?) {
    this.busyIndicatorService.show();
    this.loadingIndicator = true;
    this.permissionAdapter.getAll(pageNo, pageSize, filter).subscribe(
      data => {
        if (!data) {
          return;
        }
        this.permissions = this.parseService.multi(data);
        this.loadingIndicator = false;
        this.length = data.meta.totalItems;
      },
      ({ error }) => {
        this.snackbarService.showError(error.error);
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      }
    );
  }

  delete(id) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, { width: "300px" });
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.permissionAdapter.deleteById(id).subscribe(
          data => {
            this.getPermissions();
            this.snackbarService.showSuccess('Deleted successfully');
          },
          ({ error }) => {
            this.snackbarService.showError(error.error);
            this.loadingIndicator = false;
          },
        );
      }
    });
  }

  public displayFn(object): Object {
    return object ? object.Name : object;
  }


  public onNew() {
    const type = this.selectedTabIndex === 0 ? "internal" : "external";
    this.router.navigate([type, "new"], {
      relativeTo: this.activatedRoute
    });
  }

}
