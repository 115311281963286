import { Injectable } from '@angular/core';

import { Observable } from "rxjs";
import { Service } from "../../models/";
import { ServiceAddV1Action } from "./service-add-v1.action";
import { ServiceReadV1Action } from "./service-read-v1.action";
import { ServiceBrowserV1Action } from "./service-browse-v1.action";
import { ServiceEditV1Action } from "./service-edit-v1.action";
import { ServiceRemoveV1Action } from "./service-remove-v1.action";

/* V2 */
import { ServiceBrowserV2Action } from "./v2/service-browse-v2.action";
import { ServiceReadV2Action } from "./v2/service-read-v2.action";
import { ServiceEditV2Action } from "./v2/service-edit-v2.action";
import { ServiceRemoveV2Action } from "./v2/service-remove-v2.action";
import { ServiceAddV2Action } from "./v2/service-add-v2.action";

@Injectable()
export class ServiceAdapter {

  constructor(
    private add: ServiceAddV2Action,
    private read: ServiceReadV2Action,
    private browser: ServiceBrowserV2Action,
    private edit: ServiceEditV2Action,
    private remove: ServiceRemoveV2Action,
  ) {}

  // Simulate GET /Services
  getAll(pageNo: any, filter: any): Observable<any> {
    return this.browser.exec({pageNo, filter});
  }

  // Simulate GET /Services/:id
  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }
  // Simulate POST /Services
  updateById(service: Service, id: any): Observable<any> {
    return this.edit.exec({service, id});
  }

  // Simulate POST /Services
  create(service: Service): Observable<any> {
    return this.add.exec(service);
  }

  // Simulate DELETE /Services/:id
  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }
}
