import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "dialog-info.html",
  styleUrls: ['dialog-info.scss'],
})
export class DialogInfoComponent {
  model = null;
  active:boolean = false;
  private defaults = {
    title: 'Timeline',
    message: 'Do you want to ',
    yesText: 'Close',
    noText: 'No'
  };
  logs:[];

  constructor(
    public dialogRef: MatDialogRef<DialogInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.model = { ...this.defaults, ...data }
  }

  ngOnInit() {

    this.active = this.data.screen.is_online;
    this.logs = this.data.logs;
  }

  onNoClick(): void {
    this.dialogRef.close({'agree': false});
  }

  onYesClick(): void {
    this.dialogRef.close({'agree': true});
  }
  getHour(startTime:any, endTime:any):number{
    const startDate: Date = new Date(startTime);
    const endDate: Date = new Date(endTime);

    const timeDifference: number = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to hours
    const totalHours: number = timeDifference / (1000 * 60 * 60);
    return +totalHours.toFixed(2);
  }
}
