import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class UserBrowseExternalV2Action extends ActionEntityV2Abstract {

    //  GET /users/internal
    exec(options: any): Observable<any> {
        const number = options && options.pageNo ? options.pageNo : 1;
        const size = options && options.pageSize ? options.pageSize : 20;
        return this.http.get(
            `${this.apiUrl}/users?page[number]=${number}&page[size]=${size}&${options.filter}`
        );
    }
}
