import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Delivery } from "../../../models/delivery";
import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';

@Injectable()
export class DeliveryAddV2Action extends ActionEntityV2Abstract {
  exec(delivery: Delivery): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/deliveries`,
      delivery.toFormData()
    );
  }
}
