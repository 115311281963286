import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class RolePermissionControllerV1Action extends ActionEntityV1Abstract {

  //  POST /users
  exec(options: any): Observable<any> {
    return this.http.get(`${this.apiOauthUrl}/api/manage/Permission/ControllersInRole/${options.roleId}`);
  }

  get(): Observable<any> {
    return this.http.get(`${this.apiOauthUrl}/permissions`);
  }
}
