import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { Controller } from "../../models/controller";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientAdapter, ControllerAdapter, BaseService } from "../../services";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { AppContext } from "../../services/appcontext";

import {
  fadeInAnimation,
  ModalDialogService,
  BusyIndicatorService
} from "../../core";

@Component({
  selector: "app-ms-client-list",
  templateUrl: "./client-list.component.html",
  styleUrls: ["./client-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": 'true'
  },
  animations: [ fadeInAnimation ]
})
export class ClientListComponent implements OnInit {
  rows = [];
  isLoading: boolean;
  errorMessage: string;
  deleteId: number;
  loadingIndicator = false;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private clientAdapter: ClientAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalDialogService: ModalDialogService,
    private busyIndicatorService: BusyIndicatorService,
    private dialog: MatDialog,
    private appContext: AppContext
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle("Clients Management");
    this.getRows();
  }

  public getRows() {
    this.busyIndicatorService.show();
    this.isLoading = true;
    this.loadingIndicator = true;

    this.clientAdapter.getAll().subscribe(
      data => {
        this.rows = data.data;
        this.loadingIndicator = false;
      },
      error => {
        this.errorMessage = <any>error;
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.isLoading = false;
      }
    );
  }

  openDialog(id: number): void {
    this.deleteId = id;
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.router.navigate(["clients"]);
        this.clientAdapter.deleteById(this.deleteId).subscribe(res => {
          this.getRows();
        });
      }
    });
  }

  public edit(item) {
    this.router.navigate([item.Id, "edit"], {
      relativeTo: this.activatedRoute
    });
  }

  private handleServiceError(op: string, err: any) {
    console.error(`${op} error: ${err.message || err}`);
  }

  getApplicationTypeName(typeId: number) {
    const appType = this.appContext.applicationTypes.find(
      at => at.Code === typeId
    );
    return appType ? appType.Name : "Unknown";
  }
}
