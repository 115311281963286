import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { DigitalScreen } from "../../../models/digital-screen";
import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';

@Injectable()
export class DigitalScreenEditV1Action extends ActionEntityV2Abstract {
  exec(banner: DigitalScreen): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}/screens/${banner.id}`,
      banner
    );
  }
}
