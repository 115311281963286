import { BaseObject } from "./base-object";

export class User extends BaseObject {
  public static readonly defaultIconImage = "/media/category_images/default-category.png";
  static fromData(data: any) {
    const {
      id,
      email,
      fullname,
      phone,
      address,
      postcode,
      verified,
      claimed,
      created_at,
      password,
      permissions,
      roles,
      image,
      background_image,
      qrcode
    } = data;

    return new this(
      id,
      email,
      fullname,
      phone,
      address,
      postcode,
      verified,
      claimed,
      created_at,
      password,
      permissions,
      roles,
      image,
      background_image,
      qrcode
    );
  }

  constructor(
    public id: number = null,
    public email: string,
    public fullname?: string,
    public phone?: string,
    public address?: string,
    public postcode?: string,
    public verified?: any,
    public claimed?: any,
    public created_at?: string,
    public password?: string,
    public permissions?: any,
    public roles?: any,
    public image?: File,
    public background_image?: File,
    public qrcode?: string
  ) {
    super();
  }
}
