import { SnackbarService } from './../core/snackbar/snackbar.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { PageTitleService } from "../core/page-title/page-title.service";
import { OrderAdapter, BaseService } from "../services";

import {TreeNode} from 'primeng/api';

import {FormControl} from '@angular/forms'

import { fadeInAnimation, ModalDialogService, BusyIndicatorService } from "../core";
import { ParseService } from "../services/parse.service";

@Component({
  selector: 'app-ms-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [ fadeInAnimation ]
})
export class OrdersComponent implements OnInit {
  buyingOrderList: TreeNode[];
  sellingOrderList: TreeNode[];
  selectedNode: TreeNode;
  selectedTabIndex = 0;

  isLoading: boolean;
  errorMessage: string;
  deleteId: number;
  loadingIndicator = false;
  basePath = "orders";

  buyingPageSize = 0;
  buyingLength = 0;
  sellingPageSize = 0;
  sellingLength = 0;

  params = {
    page: 1,
    search: '',
    status: '',
    fromDate: '',
    toDate: ''
  }

  fromDate = new FormControl();
  toDate = new FormControl();
  search = new FormControl();

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private activatedRoute: ActivatedRoute,
    private busyIndicatorService: BusyIndicatorService,
    private router: Router,
    private orderAdapter: OrderAdapter,
    private parseService: ParseService,
    private snackbarService: SnackbarService

  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle("Order Management");
    this.getAllBuyingOrders(this.params)
  }

  public getAllBuyingOrders(params) {
    this.busyIndicatorService.show();
    this.isLoading = true;
    this.loadingIndicator = true;

    this.orderAdapter.getAllOrders(params).subscribe(
      response => {
        if (response) {
          if (response.data.length >= 0) {
            const buyingOrders = this.parseService.multi(response);
            this.buyingOrderList = this.toTreeNodes(buyingOrders);
            this.buyingLength = response.meta.totalItems;
            this.buyingPageSize = 20;
          }
        }
        this.loadingIndicator = false;
      },err => {
        if (err.error.errors.length) {
          this.snackbarService.showError(err.error.errors[0].detail);
        }
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;

        this.isLoading = false;
      }
    );
  }

  toTreeNodes(rows): any {
    const _treeNodes = [];
    let paymentMethod = '';

    rows.forEach((row, index) => {
      const deliveryAddress = (
        row.delivery_info && row.delivery_info.delivery_address && row.delivery_info.delivery_address !== null
        ? `, ${row.delivery_info.delivery_address}`
        : ''
      );
      const deliveryCountry = (
        row.delivery_info && row.delivery_info.delivery_country && row.delivery_info.delivery_country !== null
        ? `, ${row.delivery_info.delivery_country}`
        : ''
      );
      const deliveryPostCode = (
        row.delivery_info && row.delivery_info.delivery_postcode && row.delivery_info.delivery_postcode !== null
        ? `, ${row.delivery_info.delivery_postcode}`
        : ''
      );

      if (row.payment && row.payment.transaction_type) {
        paymentMethod = row.payment.transaction_type
      } else {
        paymentMethod = row.payment_method !== '' ? row.payment_method : 'Credit card'
      }

      const node = {
        data: {
          Id: row.id,
          Name: row.name,
          ShippingAddress: deliveryAddress + deliveryCountry + deliveryPostCode,
          OrderDate: row.date,
          PaymentMethod: paymentMethod,
          User: row.user,
          Status: row.status,
          Total: row.total
        },
        children: null
      };
      // if (!this.baseService.isNil(row.children) && row.children.length > 0) {
      //   node.children = this.toTreeNodes(row.Children);
      // }

      _treeNodes.push(node);

    });

    return _treeNodes;
  }
  public onSelectedPagingChange(evt) {
    this.params.page = evt.pageIndex + 1;
    this.getAllBuyingOrders(this.params);
   }
  public filterOrders() {
    const datePipe = new DatePipe("en-US");
    const fromDate = datePipe.transform(this.fromDate.value, 'yyyy-MM-dd') || '';
    const toDate = datePipe.transform(this.toDate.value, 'yyyy-MM-dd') || '';

    this.params.page = 1;
    this.params.search = this.search.value;
    this.params.fromDate = fromDate;
    this.params.toDate = toDate;

    this.getAllBuyingOrders(this.params)
  }

  public viewOrderDetail(node) {
    this.router.navigate([this.basePath, node.data.Id]); // path: /order/:id
  }

}
