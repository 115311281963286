import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceAdapter, UserAdapter, CategoryAdapter, BaseService } from "../../services";
import { Service, User } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { AppContext } from "../../services/appcontext";
import { ParseService } from "../../services/parse.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: "app-ms-service-item",
  templateUrl: "./service-item.component.html",
  styleUrls: ["./service-item.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class ServiceItemComponent implements OnInit {
  public Editor = ClassicEditor;
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public isLoading: boolean;
  public errorMessage: string;
  public serviceDetail = {
    duration: 0,
    category: {
      id: ''
    },
    user: {
      id: ''
    }
  };
  public item: Service;
  public serviceCategories = [];
  public externalUsers = [];

  //
  public applicationTypes = [];
  basePath = "services";
  public _PrimaryImage: any;
  public _subImage1: any;
  public _subImage2: any;
  public _subImage3: any;
  public _subImage4: any;
  public _subImage5: any;

  isBrowsewrIE = false;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private serviceAdapter: ServiceAdapter,
    private categoryAdapter: CategoryAdapter,
    private userAdapter: UserAdapter,
    private restoreService: RestoreService<Service>,
    private dialog: MatDialog,
    private appContext: AppContext,
    private parseService: ParseService
  ) { }

  ngOnInit() {
    let pageTitle = "Create new Service";

    this.setItemId();
    if (this.id === null || this.id === undefined || isNaN(this.id)) {
      this.isItemEdited = false;
      this.populateDataForNewItem();
    } else {
      this.isItemEdited = true;
      pageTitle = "Edit Service";
      this.getItem();
    }

    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
    this.getServiceCategories();
    this.getExternalUser();
    this.isBrowsewrIE = /msie\s|trident/i.test(window.navigator.userAgent)
  }

  public getItem() {
    this.isLoading = true;

    this.serviceAdapter.getById(this.id).subscribe(
      item => {
        this.isLoading = false;
        this.serviceDetail = this.parseService.single(item);
        this.restoreService.set(this.parseService.single(item));
        this.item = this.parseService.single(item)
        this.buildForm();
      },
      error => {
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.minLength(3)])],
      price: [null, Validators.compose([Validators.required])],
      currency_unit: [null, Validators.compose([Validators.required])],
      description: [null],
      primary_image: [null],
      sub_image_1: [null],
      sub_image_2: [null],
      sub_image_3: [null],
      sub_image_4: [null],
      sub_image_5: [null],
      promotion_price: [null],
      category_id: [null, Validators.compose([Validators.required])],
      user_id: [null],
      duration: [null, Validators.compose([Validators.required])],
      trading_hours: new FormGroup({
        Mon: new FormGroup({
          from: new FormControl(),
          to: new FormControl(),
          off: new FormControl()
        }),
        Tue: new FormGroup({
          from: new FormControl(),
          to: new FormControl(),
          off: new FormControl()
        }),
        Wed: new FormGroup({
          from: new FormControl(),
          to: new FormControl(),
          off: new FormControl()
        }),
        Thu: new FormGroup({
          from: new FormControl(),
          to: new FormControl(),
          off: new FormControl()
        }),
        Fri: new FormGroup({
          from: new FormControl(),
          to: new FormControl(),
          off: new FormControl()
        }),
        Sat: new FormGroup({
          from: new FormControl(),
          to: new FormControl(),
          off: new FormControl()
        }),
        Sun: new FormGroup({
          from: new FormControl(),
          to: new FormControl(),
          off: new FormControl()
        }),
      }),
    });

    if (this.item) {
      if (this.item.trading_hours) {
        if (this.baseService.isNil(this.item.trading_hours.Mon) || this.item.trading_hours.Mon.off) {
          this.item.trading_hours.Mon = { from: "", to: "", off: true };
        }
        if (this.baseService.isNil(this.item.trading_hours.Tue) || this.item.trading_hours.Tue.off) {
          this.item.trading_hours.Tue = { from: "", to: "", off: true };
        }
        if (this.baseService.isNil(this.item.trading_hours.Wed) || this.item.trading_hours.Wed.off) {
          this.item.trading_hours.Wed = { from: "", to: "", off: true };
        }
        if (this.baseService.isNil(this.item.trading_hours.Thu) || this.item.trading_hours.Thu.off) {
          this.item.trading_hours.Thu = { from: "", to: "", off: true };
        }
        if (this.baseService.isNil(this.item.trading_hours.Fri) || this.item.trading_hours.Fri.off) {
          this.item.trading_hours.Fri = { from: "", to: "", off: true };
        }
        if (this.baseService.isNil(this.item.trading_hours.Sat) || this.item.trading_hours.Sat.off) {
          this.item.trading_hours.Sat = { from: "", to: "", off: true };
        }
        if (this.baseService.isNil(this.item.trading_hours.Sun) || this.item.trading_hours.Sun.off) {
          this.item.trading_hours.Sun = { from: "", to: "", off: true };
        }
      }
      this.baseService.loadIconImage(this.item.primary_image);

      this.item.duration = Number(this.serviceDetail.duration);
      this.item.category_id = this.serviceDetail.category.id;
      this.item.user_id = this.serviceDetail.user.id;

      this.form.patchValue(this.item);
    }
  }

  readIconImage(ImageId, inputId) {
    const fUpload: any = document.getElementById(inputId);
    if (!fUpload || !fUpload.files || fUpload.files.length === 0) { return; }

    const f: any = fUpload.files[0];
    const img: any = document.getElementById(ImageId);
    const reader = new FileReader();
    reader.onloadend = (e: any) => {
      const data = e.target.result;
      img.src = data;
      if (ImageId === 'primaryImage') {
        this._PrimaryImage = f;
      }
      if (ImageId === 'subImage1') {
        this._subImage1 = f;
      }
      if (ImageId === 'subImage2') {
        this._subImage2 = f;
      }
      if (ImageId === 'subImage3') {
        this._subImage3 = f;
      }
      if (ImageId === 'subImage4') {
        this._subImage4 = f;
      }
      if (ImageId === 'subImage5') {
        this._subImage5 = f;
      }
    }
    reader.readAsDataURL(f);
  }

  public populateDataForNewItem() {
    this.item = <Service>{};
    this.item["id"] = null;
    this.buildForm();
    this.restoreService.set(this.item);
  }

  public setItemId() {
    this.id =
      this.activatedRoute.snapshot.params["id"] === "new"
        ? null
        : +this.activatedRoute.snapshot.params["id"];
  }

  onReset() {
    this.form.patchValue(this.restoreService.restoreItem());
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.serviceAdapter.deleteById(this.id).subscribe(res => {
          this.router.navigate(["services"]);
        });
      }
    });
  }

  onSubmit() {
    const service = Service.fromData(this.form.value);
    service.id = this.id;

    if (service.primary_image && service.primary_image.url) {
      delete service.primary_image
    }
    if (service.sub_image_1 && service.sub_image_1.url) {
      delete service.sub_image_1
    }
    if (service.sub_image_2 && service.sub_image_2.url) {
      delete service.sub_image_2
    }
    if (service.sub_image_3 && service.sub_image_3.url) {
      delete service.sub_image_3
    }
    if (service.sub_image_4 && service.sub_image_4.url) {
      delete service.sub_image_4
    }
    if (service.sub_image_5 && service.sub_image_5.url) {
      delete service.sub_image_5
    }
    if (this._PrimaryImage !== undefined && this._PrimaryImage !== null) {
      service.primary_image = this._PrimaryImage;
    }
    if (this._subImage1 !== undefined && this._subImage1 !== null) {
      service.sub_image_1 = this._subImage1;
    }
    if (this._subImage2 !== undefined && this._subImage2 !== null) {
      service.sub_image_2 = this._subImage2;
    }
    if (this._subImage3 !== undefined && this._subImage3 !== null) {
      service.sub_image_3 = this._subImage3;
    }
    if (this._subImage4 !== undefined && this._subImage4 !== null) {
      service.sub_image_4 = this._subImage4;
    }
    if (this._subImage5 !== undefined && this._subImage5 !== null) {
      service.sub_image_5 = this._subImage5;
    }

    Object.keys(service.trading_hours).forEach(item => {
      if (service.trading_hours[item].from
        && service.trading_hours[item].from !== '00:01'
        && service.trading_hours[item].to
        && service.trading_hours[item].to !== '00:02') {
        service.trading_hours[item].off = false
      } else {
        service.trading_hours[item].from = '00:01'
        service.trading_hours[item].to = '00:02'
        service.trading_hours[item].off = true
      }
    })

    service.trading_hours = JSON.stringify(service.trading_hours);

    if (this.isItemEdited) {
      this.serviceAdapter.updateById(service, this.id).subscribe(res => {
        this.router.navigate(["services"]);
      });
    } else {
      this.serviceAdapter.create(service).subscribe(res => {
        this.router.navigate(["services"]);
      });
    }
  }

  public getExternalUser() {
    this.userAdapter.getExternalUsers(1,1000,1).subscribe(
      data => {
        const user = this.parseService.multi(data);
        this.externalUsers = user;
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  public getServiceCategories() {
    this.categoryAdapter.GetAllServiceCategories().subscribe(
      data => {
        const category = this.parseService.multi(data);
        if (category !== null && category.length > 0) {
          if (this.id !== null && this.id !== undefined) {
            data = (<any[]>category).filter(p => p.id !== this.id);
          }
          this.getCategoryComboSourceDeep(category);
        }
      },
      error => {
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );
  }
  public getCategoryComboSourceDeep(rows) {
    rows.forEach((row, index) => {
      this.serviceCategories.push({ id: row.id, name: row.name });
      // if (row.Children !== null && row.Children.length > 0) {
      //   this.getCategoryComboSourceDeep(row.Children);
      // }
    });
  }
}
