import { RoleInfoComponent } from "./roles/role-info/role-info.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// components
import { MainComponent } from "./main/main.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { BlankComponent } from "./blank/blank.component";
import { LoginComponent } from "./session/login/login.component";
import { RegisterComponent } from "./session/register/register.component";
import { ForgotPasswordComponent } from "./session/forgot-password/forgot-password.component";
import { LockScreenComponent } from "./session/lockscreen/lockscreen.component";

import { ClientListComponent } from "./clients/client-list/client-list.component";
import { ClientItemComponent } from "./clients/client-item/client-item.component";
import { ActionListComponent } from "./actions/action-list/action-list.component";
import { ActionItemComponent } from "./actions/action-item/action-item.component";

import { ControllerListComponent } from "./controller/controller-list/controller-list.component";
import { ControllerItemComponent } from "./controller/controller-item/controller-item.component";
import { UserProfileComponent } from "./profile/user-profile/user-profile.component";

// services
import { AuthGuard } from "./auth/auth-guard.service";
import { UserListComponent } from "./users/user-list/user-list.component";
import { UserEditComponent } from "./users/user-edit/user-edit.component";
import { UserInfoComponent } from "./users/user-info/user-info.component";
import { UserNewComponent } from "./users/user-new/user-new.component";
import { RoleListComponent } from "./roles/role-list/role-list.component";
import { RoleItemComponent } from "./roles/role-item/role-item.component";
import { PasswordEditComponent } from "./users/user-password/password-edit.component";

// Banner
import { BannerListComponent } from "./banners/banner-list/banner-list.component";
import { BannerItemComponent } from "./banners/banner-item/banner-item.component";
import { BannerInfoComponent } from "./banners/banner-info/banner-info.component";

// Banner Digital
import { BannerDigitalListComponent } from "./banners-digital/banner-list/banner-list.component";
import { BannerDigitalItemComponent } from "./banners-digital/banner-item/banner-item.component";
import { BannerDigitalInfoComponent } from "./banners-digital/banner-info/banner-info.component";

// Digitals Share
import { DigitalShareListComponent } from "./digitals-share/digital-list/digital-list.component";
import { DigitalShareInfoComponent } from "./digitals-share/digital-info/digital-info.component";
// Digitals screen
import{ DigitalScreenListComponent} from "./digitals-screen/screen-list/screen-list.component"

// Deliveries
import { DeliveryListComponent } from "./deliveries/delivery-list/delivery-list.component";
import { DeleveryItemComponent } from "./deliveries/delivery-item/delivery-item.component";
import { DeliveryInfoComponent } from "./deliveries/delivery-info/delivery-info.component";

// Category
import { CategoryListComponent } from "./categories/category-list/category-list.component";
import { CategoryItemComponent } from "./categories/category-item/category-item.component";
import { CategoryNewComponent } from "./categories/category-new/category-new.component";

import { OrdersComponent } from "./orders/orders.component";
import { OrderDetailComponent } from "./orders/order-detail/order-detail.component";

// Menus
import { MenuListComponent } from "./menus/menu-list/menu-list.component";
import { MenuItemComponent } from "./menus/menu-item/menu-item.component";
import { MenuInfoComponent } from "./menus/menu-info/menu-info.component";

// Products
import { ProductListComponent } from "./products/product-list/product-list.component";
import { ProductItemComponent } from "./products/product-item/product-item.component";
import { ProductInfoComponent } from "./products/product-info/product-info.component";

// Service
import { ServiceListComponent } from "./service/service-list/service-list.component";
import { ServiceItemComponent } from "./service/service-item/service-item.component";
import { ServiceInfoComponent } from "./service/service-info/service-info.component";

// Promotion
import { PromotionListComponent } from "./promotions/promotion-list/promotion-list.component";
import { BookingsComponent } from "./bookings/bookings.component";

// Subcribe
import { SubscribersComponent } from "./subscribers/subscribers.component";

// Reviews
import { ReviewListComponent } from "./reviews/review-list/review-list.component";
import { ReviewEditComponent } from "./reviews/review-edit/review-edit.component";
import { PermissionListComponent } from "./permissions/permission-list/permission-list.component";
import { PermissionItemComponent } from "./permissions/permission-item/permission-item.component";
import { PermissionInfoComponent } from "./permissions/permission-info/permission-info.component";

// routing
const appRoutes: Routes = [
  {
    path: "session/login",
    component: LoginComponent,
  },
  {
    path: "session/register",
    component: RegisterComponent,
  },
  {
    path: "session/forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "session/lockscreen",
    component: LockScreenComponent,
  },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "profile",
        component: UserProfileComponent,
        canActivate: [AuthGuard],
      },
      { path: "", component: DashboardComponent, canActivate: [AuthGuard] },
      {
        path: "clients",
        component: ClientListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "clients/:id/edit",
        component: ClientItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "clients/new",
        component: ClientItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "controllers",
        component: ControllerListComponent,
        canActivate: [AuthGuard],
        // children: [
        //   { path: "", component: ControllerListComponent, pathMatch: "full"},
        //   { path: "new", component: ControllerItemComponent, pathMatch: "full"},
        //   { path: ":id/edit", component: ControllerItemComponent, pathMatch: "full"}
        // ]
      },
      {
        path: "controllers/:id/edit",
        component: ControllerItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "controllers/new",
        component: ControllerItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "actions",
        component: ActionListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "actions/:id/edit",
        component: ActionItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "actions/new",
        component: ActionItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries",
        component: DeliveryListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries/now",
        component: DeliveryListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries/schedule",
        component: DeliveryListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries/new",
        component: DeleveryItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries/:id/edit",
        component: DeleveryItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries/now/new",
        component: DeleveryItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries/now/:id/edit",
        component: DeleveryItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries/schedule/new",
        component: DeleveryItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries/schedule/:id/edit",
        component: DeleveryItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deliveries/:id",
        component: DeliveryInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners",
        component: BannerListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/:id/edit",
        component: BannerItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/new",
        component: BannerItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/mobile",
        component: BannerListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/mobile/:id/edit",
        component: BannerItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/mobile/new",
        component: BannerItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/mobile/:id",
        component: BannerInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/web",
        component: BannerListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/web/:id/edit",
        component: BannerItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/web/new",
        component: BannerItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/web/:id",
        component: BannerInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners/:id",
        component: BannerInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners_digital",
        component: BannerDigitalListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners_digital/:id/edit",
        component: BannerDigitalItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners_digital/new",
        component: BannerDigitalItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "banners_digital/:id",
        component: BannerDigitalInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "digitals_screen",
        component: DigitalScreenListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "digitals_share",
        component: DigitalShareListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "digitals_share/:id",
        component: DigitalShareInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "users",
        component: UserListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "account_settings",
        component: UserEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "users/:id/edit",
        component: UserEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "users/:id",
        component: UserInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "update_password",
        component: PasswordEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "users/:type/new",
        component: UserNewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "roles",
        component: RoleListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "roles/:id/edit",
        component: RoleItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "roles/new",
        component: RoleItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "roles/:id",
        component: RoleInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "permissions",
        component: PermissionListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "permissions/:id/edit",
        component: PermissionItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "permissions/new",
        component: PermissionItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "permissions/:id",
        component: PermissionInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "categories",
        component: CategoryListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "categories/new",
        component: CategoryNewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "categories/:group/new",
        component: CategoryItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "categories/:group",
        component: CategoryListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "categories/:group/:id/edit",
        component: CategoryItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "categories/:group/:pid/new",
        component: CategoryItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "orders",
        component: OrdersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "orders/:id",
        component: OrderDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "menus",
        component: MenuListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "menus/edit/:id",
        component: MenuItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "menus/new",
        component: MenuItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "menus/:id",
        component: MenuInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "products",
        component: ProductListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "products/edit/:id",
        component: ProductItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "products/new",
        component: ProductItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "products/:id",
        component: ProductInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "services",
        component: ServiceListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "services/edit/:id",
        component: ServiceItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "services/new",
        component: ServiceItemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "services/:id",
        component: ServiceInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reviews",
        component: ReviewListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reviews/:id/edit",
        component: ReviewEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "bookings",
        component: BookingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "subcribers",
        component: SubscribersComponent,
        canActivate: [AuthGuard],
      },

      /*
      children: [
          { path: "", component: ControllerListComponent , canActivate: [AuthGuard]},
          { path: "new", component: ControllerItemComponent , canActivate: [AuthGuard]},
          { path: ":id", component: ControllerItemComponent , canActivate: [AuthGuard]}
        ]
      */
    ],
  },
  // {
  //   path: "controllers",
  //   component: ControllerListComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     // { path: "", component: ControllerListComponent},
  //     { path: "new", component: ControllerItemComponent},
  //     { path: ":id/edit", component: ControllerItemComponent}
  //   ]
  // },
  // {
  //   path: "controllers/new",
  //   component: ControllerItemComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "controllers/:id/edit",
  //   component: ControllerItemComponent,
  //   canActivate: [AuthGuard]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule],
  providers: [],
})
export class RoutingModule {}
