import { Injectable} from '@angular/core';
import { Observable } from "rxjs";
import { DigitalScreenActivity } from "../../models/digital-screen-activity";
import { DigitalScreenActivityBrowseV1Action } from "./v1/screen-activity-browse-v1.action"

@Injectable()
export class DigitalScreenActivityAdapter {
  constructor(
    private browse: DigitalScreenActivityBrowseV1Action,
  ) {}

   getAll(pageNo: any, pageSize: number, filter: any) {
    return this.browse.exec({pageNo, pageSize, filter});
  }
}
