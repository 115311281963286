import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';

@Injectable()
export abstract class ActionEntityAbstract {
    constructor(public http: HttpClient) {}

    abstract exec(options: any, type?: any): Observable<any>;
}
