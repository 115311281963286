import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { Controller } from "../../models/controller";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoryAdapter, ControllerAdapter, BaseService } from "../../services";
import { MatDialog } from "@angular/material";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { AppContext } from "../../services/appcontext";

import { fadeInAnimation, ModalDialogService, BusyIndicatorService } from "../../core";
import { TreeNode } from 'primeng/api';
import { ParseService } from "../../services/parse.service";
import { SnackbarService } from 'app/core/snackbar/snackbar.service';

@Component({
  selector: 'app-ms-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [fadeInAnimation]
})
export class CategoryListComponent implements OnInit {
  treeNodes_ProductCate: TreeNode[];
  treeNodes_ServiceCate: TreeNode[];
  treeNodes_Menu: TreeNode[];
  treeNodes: Array<object>;
  selectedNode: TreeNode;
  selectedTabIndex = 0;

  isLoading: boolean;
  errorMessage: string;
  deleteId: number;
  loadingIndicator = false;
  basePath = "categories";

  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi'
  ];

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private categoryAdapter: CategoryAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalDialogService: ModalDialogService,
    private busyIndicatorService: BusyIndicatorService,
    private dialog: MatDialog,
    private appContext: AppContext,
    private parseService: ParseService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle("Categories Management");
    this.setTabIndex();
    this.loadTreeData(0);
    this.loadTreeData(1);
    this.loadTreeData(2);
    this.treeNodes = [
      { id: 'Product Categories', data: this.treeNodes_ProductCate },
      { id: 'Service Categories', data: this.treeNodes_ServiceCate },
      { id: 'Menu', data: this.treeNodes_Menu },
    ];
  }

  drop(event: CdkDragDrop<any[]>) {
    console.log(this.treeNodes_ProductCate);
    moveItemInArray(this.treeNodes_ProductCate, event.previousIndex, event.currentIndex);
  }

  public onSelectedTabChange(evt) {
    location.assign("#/" + this.basePath + "/" + this.getCategoryGroup());
  }

  setTabIndex() {
    const group = this.activatedRoute.snapshot.params["group"];
    this.selectedTabIndex = group === "Product" || group === null || group === undefined ? 0 : 1;
  }

  getCategoryGroup(): string { return this.selectedTabIndex === 0 ? "Product" : "Service"; }

  add() {
    this.router.navigate([this.basePath, this.getCategoryGroup(), -1, "new"]); // path: /:group/:pid/new"
  }
  addSub(node) {
    this.router.navigate([this.basePath, node.data.Group, node.data.Id, "new"]); // path: /:group/:pid/new"
  }
  edit(node) {
    this.router.navigate([this.basePath, node.data.Group, node.data.Id, "edit"]); // path: /:group/:id/edit"
  }
  delete(node) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, { width: "300px" });
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.categoryAdapter.deleteById(node.data.Id).subscribe(res => {
          this.loadTreeData(this.selectedTabIndex);
          this.snackbarService.showSuccess('Successfully');
        });
      }
    });
  }

  public loadTreeData(tabIndex: number) {
    this.busyIndicatorService.show();
    this.isLoading = true;
    this.loadingIndicator = true;
    if (tabIndex === 0) {
      this.categoryAdapter.getAllProductCategories().subscribe(
        data => {
          const category = this.parseService.multi(data);

          if (category !== null && category.length > 0) {
            this.treeNodes_ProductCate = this.toTreeNodes(category);
          }
          this.loadingIndicator = false;
        },
        error => {
          this.errorMessage = <any>error;
          this.loadingIndicator = false;
        },
        () => {
          this.busyIndicatorService.hide();
          this.isLoading = false;
          this.treeNodes[0]['data'] = this.treeNodes_ProductCate;
        }
      );
    } else {
      this.categoryAdapter.GetAllServiceCategories().subscribe(
        data => {
          const category = this.parseService.multi(data);

          if (category !== null && category.length > 0) {
            this.treeNodes_ServiceCate = this.toTreeNodes(category);
          }
          this.loadingIndicator = false;
        },
        error => {
          this.errorMessage = <any>error;
          this.loadingIndicator = false;
        },
        () => {
          this.busyIndicatorService.hide();
          this.isLoading = false;
          this.treeNodes[1]['data'] = this.treeNodes_ServiceCate;
        }
      );
    }
    if (tabIndex === 2) {
      this.categoryAdapter.GetAllMenuCategories().subscribe(
        data => {
          const category = this.parseService.multi(data);

          if (category !== null && category.length > 0) {
            this.treeNodes_Menu = this.toTreeNodes(category);
          }
          this.loadingIndicator = false;
        },
        error => {
          this.errorMessage = <any>error;
          this.loadingIndicator = false;
        },
        () => {
          this.busyIndicatorService.hide();
          this.isLoading = false;
          this.treeNodes[2]['data'] = this.treeNodes_Menu;
        }
      );
    }
  }

  toTreeNodes(rows): any {
    const _treeNodes = [];
    rows.forEach((row, index) => {
      const node = {
        data: {
          Id: row.id, Name: row.name, Description: row.description, Group: row.group, Type: row.type,
          IconImage: row.primary_image && row.primary_image.url
        },
        children: null
      };

      if (row.children && row.children.length > 0) {
        node.children = this.toTreeNodes(row.children);
      }

      _treeNodes.push(node);

    });
    return _treeNodes;
  }
}
