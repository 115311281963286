import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Router } from '@angular/router';

import { Observable, of, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { SnackbarService } from "app/core/snackbar/snackbar.service";

@Injectable()
export class ErrorHandler implements HttpInterceptor {

  constructor(private router: Router, private snackBar: SnackbarService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError((err, c) => {
          if (err.status === 500 || err.status === 404) {
            this.snackBar.showError('Something bad happened; please try again later.');
          }
          return throwError(err);
        })
      );
  }
}
