import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";
import { ProductAdapter, PromotionAdapter, ReviewAdapter, BaseService } from "../../services";
import { Product, Promotion, Review } from "../../models";
import { RestoreService } from "../../services/restore.service";
import { AppContext } from "../../services/appcontext";
import { ParseService } from "../../services/parse.service";

@Component({
  selector: "app-ms-menu-info",
  templateUrl: "./menu-info.component.html",
  styleUrls: ["./menu-info.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class MenuInfoComponent implements OnInit {
  private id?: number = null;
  public item: Product;
  public promotions: Promotion[];
  public reviews: Review[];
  public applicationTypes = [];
  basePath = "products";
  public _IconImage: any;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private productAdapter: ProductAdapter,
    private promotionAdapter: PromotionAdapter,
    private reviewAdapter: ReviewAdapter,
    private activatedRoute: ActivatedRoute,

    private restoreService: RestoreService<Product>,
    private appContext: AppContext,
    private parseService: ParseService
  ) {}

  ngOnInit() {
    const pageTitle = "Menu Info";
    this.id = this.activatedRoute.snapshot.params["id"]
    this.getItem();
    this.getReviews();
    this.applicationTypes = this.appContext.applicationTypes;
    this.pageTitleService.setTitle(pageTitle);
    this.promotions = [];
  }

  public getItem() {
    this.productAdapter.getById(this.id).subscribe(
      item => {
        const product = this.parseService.single(item);
        this.restoreService.set(this.parseService.single(item));
        this.item = this.parseService.single(item)

        if (!this.baseService.isNil(product.promotion)) {
          this.promotions = [product.promotion];
        }
      }
    );
  }

  public getReviews() {
    this.reviewAdapter.getAllProductReviews(this.id, 1, {}).subscribe(
      response => {
        const reviews = this.parseService.multi(response);
        this.reviews = reviews;
      }
    );
  }
}
