import { BaseObject } from "./base-object";

export class Review extends BaseObject {
  static fromData(data: any) {
    const {
      id,
      ProductId,
      UserId,
      UserName,
      ProfileImage,
      comment,
      Rating,
      IsLike
    } = data;

    return new this(
      id,
      ProductId,
      UserId,
      UserName,
      ProfileImage,
      comment,
      Rating,
      IsLike
    );
  }

  constructor(
    public id: number = null,
    public ProductId: number,
    public UserId: number,
    public UserName: string,
    public ProfileImage: string,
    public comment: string,
    public Rating: number,
    public IsLiked: boolean,
  ) {
    super();
  }
}
