import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  defaultOption: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(private snackBar: MatSnackBar) { }

  showMessage(message: string, action: string = 'Close', options?: MatSnackBarConfig) {
    this.snackBar.open(message, action, {
      ...this.defaultOption,
      ...options
    });
  }

  showSuccess(message: string, action?, options?: MatSnackBarConfig) {
    this.showMessage(message, action, {
      panelClass: ['custom-snackbar', 'success'],
      ...options
    });
  }

  showInfo(message: string, action?, options?: MatSnackBarConfig) {
    this.showMessage(message, action, {
      panelClass: ['custom-snackbar', 'info'],
      ...options
    });
  }

  showWarn(message: string, action?, options?: MatSnackBarConfig) {
    this.showMessage(message, action, {
      panelClass: ['custom-snackbar', 'warn'],
      ...options
    });
  }

  showError(message: string, action?, options?: MatSnackBarConfig) {
    this.showMessage(message, action, {
      panelClass: ['custom-snackbar', 'error'],
      ...options
    });
  }
}
