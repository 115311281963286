import { ActionAdapter, UserAdapter } from "../../services/index";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { fadeInAnimation } from "../../core/route-animation/route.animation";
import { Router, ActivatedRoute } from "@angular/router";

import { User, UserImage } from "../../models";
import { RestoreService, BaseService } from "../../services";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { RoleAdapter } from "./../../services/";
import { ParseService } from "../../services/parse.service";

const password = new FormControl("", Validators.required);
const confirmPassword = new FormControl("", CustomValidators.equalTo(password));

@Component({
  selector: "app-ms-password-edit",
  templateUrl: "./password-edit.component.html",
  styleUrls: ["./password-edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "[@fadeInAnimation]": "true"
  },
  animations: [fadeInAnimation]
})
export class PasswordEditComponent implements OnInit {
  public form: FormGroup;
  private id?: number = null;
  public isItemEdited: boolean;
  public isLoading: boolean;
  public errorMessage: string;
  public item: User;


  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userAdapter: UserAdapter,
    private restoreService: RestoreService<User>,
    private parseService: ParseService
  ) {}

  ngOnInit() {
    const pageTitle = "Edit Password";
    this.isItemEdited = true;
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (!this.id) {
      this.id = currentUser.id;
    }
    this.getItem();

    this.pageTitleService.setTitle(pageTitle);
  }

  public getItem() {
    this.isLoading = true;
    this.userAdapter.getById(this.id).subscribe(
      item => {
        this.isLoading = false;
        const data = this.parseService.single(item);
        this.restoreService.set(data);
        this.item = this.restoreService.get();
        this.buildForm();
      },
      error => {
        // this.busyIndicatorService.hide();
        this.isLoading = false;
        this.errorMessage = <any>error;
      }
    );

    this.buildForm();
  }

  private buildForm() {
    this.form = this.fb.group({
      password: [null],
    });

    if (this.item) {
      this.form.patchValue(this.item);
    }
  }

  onReset() {
    this.form.patchValue(this.restoreService.restoreItem());
  }

  onSubmit() {
    const user = User.fromData(this.form.value);
    // console.log(this.form.value); return false;
    user.id = this.id;
    this.userAdapter.update_password(user).subscribe(
      res => {
        this.router.navigate(["account_settings"]);
      },
      error => {
        this.errorMessage = <any>error;
        console.log(this.errorMessage);
      }
    );
  }
}
