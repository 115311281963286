import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class PostInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (request.method === 'POST') {
    //   if (!(request.body instanceof FormData)) {
    //     request = request.clone({
    //       setHeaders: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //       }
    //     });
    //   }
    // }

    return next.handle(request);
  }
}
