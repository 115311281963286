import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserAdapter, BaseService, ReviewAdapter } from "../../services";
import { MatDialog, PageEvent } from "@angular/material";
import { fadeInAnimation } from "../../core";
import { ParseService } from "../../services/parse.service";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";


@Component({
  selector: 'app-ms-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [ fadeInAnimation ]
})
export class ReviewListComponent implements OnInit {
  reviews: any = [];
  // pagination
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  pageNo: number;
  length: number;
  public deleteId: number;
  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private router: Router,
    private reviewAdapter: ReviewAdapter,
    private parseService: ParseService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle("Reviews Management");
    this.getAllReviews();
  }

  getAllReviews(event?: PageEvent) {
    this.pageNo = 1;
    if (!this.baseService.isNil(event)) {
      this.pageNo = event.pageIndex + 1;
    }

    this.reviewAdapter.getAllReviews(this.pageNo, {}).subscribe(
      data => {
        this.reviews = this.parseService.multi(data);
        this.pageIndex = this.pageNo;
        this.pageSize = 20;
        this.length = data.meta.totalItems;
      }
    )

  }

  public createRange(number) {
    const items: number[] = [];
    for (let i = 1; i <= number; i++) {
       items.push(i);
    }
    return items;
  }

  openDialog(id: number): void {
    this.deleteId = id;
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.router.navigate(["reviews"]);
        this.reviewAdapter.deleteById(this.deleteId).subscribe(res => {
          this.getAllReviews();
        });
      }
    });
  }

  public edit(item) {
    this.router.navigate([item.id, "edit"], {
      relativeTo: this.activatedRoute
    });
  }
}
