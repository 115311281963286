import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, finalize } from "rxjs/operators";
import { AppLoaderService } from "../core/app-loader/app-loader.service";
@Injectable()
export class AppLoaderInterceptor implements HttpInterceptor {
    count: number = 0;

    constructor(private appLoader: AppLoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.appLoader.showAppLoading();
        this.increaseNumberOfRequest();
        return next.handle(request).pipe(
            finalize(() => {
                this.decreaseNumberOfRequest();
                if (this.appLoader.numberOfRequest <= 0) {
                    this.appLoader.hideAppLoading();
                }
            }
            ));
    }

    increaseNumberOfRequest = () => {
        this.appLoader.numberOfRequest++;
    }

    decreaseNumberOfRequest = () => {
        this.appLoader.numberOfRequest--;
    }
}