import { Injectable} from '@angular/core';
import { Observable } from "rxjs";
import { DigitalScreen } from "../../models/digital-screen";
import { DigitalScreenBrowseV1Action } from "./v1/screen-browse-v1.action";
import { DigitalScreenEditV1Action } from "./v1/screen-edit-v1.action";
import { DigitalScreenAddV1Action } from './v1/screen-add-v1.action';
import { DigitalScreenRemoveV1Action } from './v1/screen-remove-v1.action';
import { DigitalScreenReadV1Action } from './v1/screen-read-v1.action';

@Injectable()
export class DigitalScreenAdapter {
  constructor(
    private add: DigitalScreenAddV1Action,
    private remove: DigitalScreenRemoveV1Action,
    private read: DigitalScreenReadV1Action,
    private browse: DigitalScreenBrowseV1Action,
    private edit: DigitalScreenEditV1Action,
  ) {}

   getAll(pageNo: any, pageSize: number, filter: any) {
    return this.browse.exec({pageNo, pageSize, filter});
  }

  getById(id: number): Observable<any> {
    return this.read.exec({id});
  }

  updateById(screen: DigitalScreen): Observable<any> {
    return this.edit.exec(screen);
  }

  create(screen: DigitalScreen): Observable<any> {
    return this.add.exec(screen);
  }

  deleteById(id: number): Observable<any> {
    return this.remove.exec({id});
  }
}
