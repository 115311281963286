import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV2Abstract } from "../../action-entity-v2.abstract";

@Injectable()
export class PromotionBrowseV2Action extends ActionEntityV2Abstract {

  exec(options: any): Observable<any> {
    return this.http.get(
        `${this.apiUrl}/promotions?PageNo=${options.pageNo}&${options.filter}`
    );
  }

}
