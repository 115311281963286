import { Injectable } from "@angular/core";

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS = [
  // {
  //   state: '',
  //   name: '',
  //   type: 'link',
  //   icon: 'explore'
  // },
  /*  {
    state: 'clients',
    name: 'Clients',
    type: 'link',
    icon: 'supervisor_account'
  },
  {
    state: 'controllers',
    name: 'Controllers',
    type: 'link',
    icon: 'control_point_duplicate'
  },
  {
    state: 'actions',
    name: 'Actions',
    type: 'link',
    icon: 'assignment'
  }, */
  {
    state: "banners",
    name: "Banners",
    type: "link",
    icon: "image",
  },
  {
    state: "banners_digital",
    name: "Banner Digital",
    type: "link",
    icon: "image",
  },
  {
    state: "digitals_screen",
    name: "Digital screen",
    type: "link",
    icon: "airplay",
  },
  {
    state: "digitals_share",
    name: "Digital Share",
    type: "link",
    icon: "image",
  },
  {
    state: "users",
    name: "Users",
    type: "link",
    icon: "supervisor_account",
  },
  {
    state: "categories",
    name: "Categories",
    type: "link",
    icon: "settings",
  },
  {
    state: "menus",
    name: "Menu",
    type: "link",
    icon: "restaurant_menu",
  },
  {
    state: "products",
    name: "Products",
    type: "link",
    icon: "work",
  },
  {
    state: "services",
    name: "Services",
    type: "link",
    icon: "art_track",
  },
  {
    state: "reviews",
    name: "Reviews",
    type: "link",
    icon: "comment",
  },
  {
    state: "roles",
    name: "Roles",
    type: "link",
    icon: "account_box",
  },
  {
    state: "permissions",
    name: "Permissions",
    type: "link",
    icon: "verified_user",
  },
  {
    state: "orders",
    name: "Orders",
    type: "link",
    icon: "assignment",
  },
  {
    state: "deliveries",
    name: "Deliveries",
    type: "link",
    icon: "fastfood",
  },
  {
    state: "bookings",
    name: "Bookings",
    type: "link",
    icon: "shopping_basket",
  },
  {
    state: "subcribers",
    name: "Subcribers",
    type: "link",
    icon: "shopping_basket",
  },
  {
    state: "account_settings",
    name: "Account Settings",
    type: "link",
    icon: "settings",
  },
  {
    state: "",
    name: "Help & Support",
    type: "link",
    icon: "help",
  },
  // {
  //   state: 'session',
  //   name: 'SESSIONS',
  //   type: 'sub',
  //   icon: 'face',
  //   children: [
  //     {state: 'login', name: 'LOGIN'},
  //     {state: 'register', name: 'REGISTER'},
  //     {state: 'forgot-password', name: 'FORGOT'},
  //     {state: 'lockscreen', name: 'LOCKSCREEN'}
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  add(menu: Menu) {
    MENUITEMS.push(menu);
  }
}
