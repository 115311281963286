import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActionEntityV1Abstract } from "../action-entity-v1.abstract";

@Injectable()
export class ControllerRemoveV1Action extends ActionEntityV1Abstract {

  // Simulate POST /clients
  exec(options: any): Observable<any> {
    return this.http.delete(`${this.apiOauthUrl}/api/manage/ControllerInfo/DeleteById/${options.controllerId}`);
  }

}
