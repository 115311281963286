import { BaseObject } from "./base-object";

export class Action extends BaseObject {
  static fromData(data: any) {
    const {
      Id,
      ControllerInfoId,
      ActionName,
      ActionDisplayName,
      ShowAsMenu
    } = data;

    return new this(
      Id,
      ControllerInfoId,
      ActionName,
      ActionDisplayName,
      ShowAsMenu
    );
  }

  constructor(
    public Id: number = null,
    public ControllerInfoId: number,
    public ActionName: string,
    public ActionDisplayName?: string,
    public ShowAsMenu?: boolean
  ) {
    super();
  }
}
