import { fadeInAnimation } from './../../core/route-animation/route.animation';
import { FormControl } from '@angular/forms';
import { SnackbarService } from 'app/core/snackbar/snackbar.service';
import { ParseService } from './../../services/parse.service';
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDialogService } from "../../core/modalDialog/modalDialog.service";
import { BusyIndicatorService } from "../../core/busyIndicator/busyIndicator.service";
import { MatDialog, PageEvent } from "@angular/material";
import { DeleteConfirmDialogComponent } from "app/dialog/delete-confirm-dialog";
import { RoleAdapter, BaseService } from "../../services";

@Component({
  selector: "app-ms-role-list",
  templateUrl: "./role-list.component.html",
  styleUrls: ["./role-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [fadeInAnimation]
})
export class RoleListComponent implements OnInit {
  rows = [];
  nameControl = new FormControl();

  pageIndex: number;
  pageSize: number;
  length: number;
  filter: any;
  loadingIndicator = false;
  basePath = 'roles';

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private roleAdapter: RoleAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    protected modalDialogService: ModalDialogService,
    private parseService: ParseService,
    private snackbarService: SnackbarService,
    protected busyIndicatorService: BusyIndicatorService,
    private dialog: MatDialog
  ) {
    this.pageSize = baseService.PAGE_SIZE;
  }

  ngOnInit() {
    this.pageTitleService.setTitle("Roles Management");
    this.getRoles();
  }

  public getRoles(event?: PageEvent) {
    this.pageIndex = 0;
    if (!this.baseService.isNil(event)) {
      this.pageIndex = event.pageIndex;
    }
    let filter = '';
    const name = this.nameControl.value;
    if (name !== "" && !this.baseService.isNil(name)) {
      filter = `name=*${name}*`;
    }
    this.requestApi(this.pageIndex + 1, filter, this.pageSize);
  }

  public requestApi(pageNo, filter, pageSize?) {
    this.busyIndicatorService.show();
    this.loadingIndicator = true;
    this.roleAdapter.getAll(pageNo, pageSize, filter).subscribe(
      data => {
        if (!data) {
          return;
        }
        this.rows = this.parseService.multi(data);
        this.loadingIndicator = false;
        this.length = data.meta.totalItems;
      },
      error => {
        this.snackbarService.showError(`Error : ${error}`);
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      },
      () => {
        this.busyIndicatorService.hide();
        this.loadingIndicator = false;
      }
    );
  }

  delete(id) {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, { width: "300px" });
    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.roleAdapter.deleteById(id).subscribe(
          data => {
            this.getRoles();
            this.snackbarService.showMessage('Deleted successfully');
          },
          ({ error }) => {
            this.snackbarService.showError(error.error);
            this.loadingIndicator = false;
          }
        );
      }
    });
  }

}
