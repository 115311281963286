import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDialogService } from "../../core/modalDialog/modalDialog.service";

import { DeleteConfirmDialogComponent } from "../../dialog/delete-confirm-dialog";
import { PageTitleService } from "../../core/page-title/page-title.service";
import { OrderAdapter, BaseService } from "../../services";
import { ParseService } from "../../services/parse.service";

@Component({
  selector: 'app-ms-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderDetailComponent implements OnInit {
  id = null;
  errorMessage: string;
  detail = null;
  refundArray = [];
  total_refund = 0;

  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private activatedRoute: ActivatedRoute,
    protected modalDialogService: ModalDialogService,
    private orderAdapter: OrderAdapter,
    private dialog: MatDialog,
    private parseService: ParseService
  ) { }

  ngOnInit() {
    this.pageTitleService.setTitle("Order Detail");
    this.setItemId()
    this.getOrderDetail()
  }

  setItemId() {
    this.id = this.activatedRoute.snapshot.params["id"]
  }

  public refundItem() {
    this.orderAdapter.refundOrder({ id: this.id, orderItem: this.refundArray }).subscribe(
      response => {
        this.getOrderDetail();
        this.refundArray = [];
        alert('Refund successful')
      }
    )
  }

  public onChangeInput(id, refundPrice) {
    const index = this.refundArray.findIndex(item => parseInt(item.items.order_item_id, 10) === parseInt(id, 10));

    if (index !== -1) {
      if (!refundPrice || refundPrice === 0) {
        this.refundArray.splice(index, 1);
      } else {
        this.refundArray[index].items.refund_amount = refundPrice
      }
    } else {
      if (refundPrice && refundPrice !== 0) {
        this.refundArray.push({
          order_item_id: id,
          refund_amount: refundPrice
        })
      }
    }
  }

  public getOrderDetail() {
    this.total_refund = 0;
    this.orderAdapter.getOrderDetail(this.id).subscribe(
      response => {
        if (response) {
          this.detail = this.parseService.single(response);
          if (this.detail.payment && this.detail.payment.transaction_type) {
            this.detail.paymentMethod = this.detail.payment.transaction_type
          } else {
            this.detail.paymentMethod = this.detail.payment_method !== '' ? this.detail.payment_method : 'Credit card'
          }

          this.detail.items.map(item => {
            item.refund_price = 0;
            this.total_refund += Number(item.refund_amount);
          })
        }
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

  public formatPrice(price) {
    return Number(price).toFixed(2)
  }

  openDialog(item): void {
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
      data: { message: 'Do you want to refund?' },
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(responseOK => {
      if (responseOK) {
        this.refundItem()
      }
    });
  }
}
