import { BaseObject } from "./base-object";

export class Category extends BaseObject {
  public static readonly defaultIconImage = "/media/category_images/default-category.png";
  static fromData(data: any) {
    const { id, name, group, parent_id, description, type, primary_image } = data;
    return new this(id, name, group, parent_id, description, type, primary_image);
  }

  constructor(
    public id: number = null,
    public name: string,
    public group: string,
    public parent_id?: any,
    public description?: string,
    public type?: any,
    public primary_image?: any
  ) { super(); }
}
