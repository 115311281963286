import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { PageTitleService } from "../../core/page-title/page-title.service";
import { Router } from "@angular/router";
import { UserAdapter, BaseService } from "../../services";
import { MatDialog, PageEvent } from "@angular/material";
import { fadeInAnimation } from "../../core";
import { Promotion } from "../../models";

@Component({
  selector: 'app-ms-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [ fadeInAnimation ]
})
export class PromotionListComponent implements OnInit {
  @Input() promotions: Promotion[];
  // pagination
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;
  basePath = "promotions";
  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private userAdapter: UserAdapter,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    console.log(this.promotions)
  }

}
