import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Delivery } from '../../models/delivery';
import { DeliveryBrowseV2Action } from './v2/delivery-browse-v2.action';
import { DeliveryEditV2Action } from './v2/delivery-edit-v1.action';
import { DeliveryAddV2Action } from './v2/delivery-add-v1.action';
import { DeliveryRemoveV2Action } from './v2/delivery-remove-v1.action';
import { DeliveryReadV2Action } from './v2/delivery-read-v1.action';

@Injectable()
export class DeliveryAdapter {
  constructor(
    private add: DeliveryAddV2Action,
    private remove: DeliveryRemoveV2Action,
    private read: DeliveryReadV2Action,
    private browse: DeliveryBrowseV2Action,
    private edit: DeliveryEditV2Action
  ) {}

  getAll(pageNo: any, pageSize: number, filter: any, type: any) {
    return this.browse.exec({ pageNo, pageSize, filter, type });
  }

  getById(id: number): Observable<any> {
    return this.read.exec({ id });
  }

  updateById(delivery: Delivery): Observable<any> {
    return this.edit.exec(delivery);
  }

  create(delivery: Delivery): Observable<any> {
    return this.add.exec(delivery);
  }

  deleteById(id: number): Observable<any> {
    return this.remove.exec({ id });
  }
}
