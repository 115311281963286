import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { BannerDigital } from "../../../models/banner-digital";
import { ActionEntityV2Abstract } from 'app/services/action-entity-v2.abstract';

@Injectable()
export class BannerDigitalAddV2Action extends ActionEntityV2Abstract {
  exec(banner: BannerDigital): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/digitals`,
      banner.toFormData()
    );
  }
}
