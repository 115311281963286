import { BaseObject } from "./base-object";

export class Banner extends BaseObject {
  static fromData(data: any) {
    const { id, name, user_id, path, image, type, status } = data;
    return new this(id, name, user_id, path, image, type, status);
  }

  constructor(
    public id: number = null,
    public name?: string,
    public user_id?: any,
    public path?: string,
    public image?: File,
    public type: string = 'web',
    public status?: any
  ) { super(); }
}
