import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { PageTitleService } from "../core/page-title/page-title.service";
import { MatIconRegistry } from '@angular/material';
import { Router } from "@angular/router";
import { UserAdapter, BaseService } from "../services";
import { MatDialog, PageEvent } from "@angular/material";
import { fadeInAnimation } from "../core";
import { Review } from "../models";

@Component({
  selector: 'app-ms-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { "[@fadeInAnimation]": 'true' },
  animations: [ fadeInAnimation ]
})
export class ReviewsComponent implements OnInit {
  @Input() reviews: Review[];
  // pagination
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  length: number;
  constructor(
    private pageTitleService: PageTitleService,
    private baseService: BaseService,
    private userAdapter: UserAdapter,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {}

  public createRange(number) {
    const items: number[] = [];
    for (let i = 1; i <= number; i++) {
       items.push(i);
    }
    return items;
  }

}
